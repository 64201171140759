<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-expansion-panels accordion focusable v-model="panel" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header>発注検索</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card-text>
                    <v-form ref="formSearchConditionsOrder">
                      <v-row>
                        <!-- 作成日付 -->
                        <v-col cols="12" sm="4" xl="2">
                          <v-menu
                            v-model="scOrderDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            left
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="scOrderDateRange"
                                label="作成日付"
                                append-icon="mdi-calendar"
                                readonly
                                clearable
                                persistent-hint
                                :hint="`完全一致/範囲検索`"
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="scOrderDates = []"
                                @click:append="scOrderDateMenu = !scOrderDateMenu"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="scOrderDates"
                              range
                              @input="scOrderDateMenu = false"
                              :day-format="(date) => new Date(date).getDate()"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <!-- 着日 -->
                        <v-col cols="12" sm="3">
                          <v-menu
                            v-model="scInDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            left
                            min-width="auto"
                            :disabled="inDateUndecidedCheckBox"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="scInDateRange"
                                label="着日"
                                append-icon="mdi-calendar"
                                readonly
                                clearable
                                persistent-hint
                                :hint="`完全一致/範囲検索`"
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="scInDates = []"
                                @click:append="scInDateMenu = !scInDateMenu"
                                :disabled="inDateUndecidedCheckBox"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="scInDates"
                              range
                              @input="scInDateMenu = false"
                              :day-format="(date) => new Date(date).getDate()"
                              :disabled="inDateUndecidedCheckBox"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="1">
                          <!-- 着日未定 -->
                          <v-checkbox
                            v-model="inDateUndecidedCheckBox"
                            :label="`未定`"
                            dense
                            hide-details="false"
                          ></v-checkbox>
                        </v-col>
                        <!-- 注文番号開始 -->
                        <v-col cols="12" sm="2" xl="1">
                          <v-text-field
                            v-model="scOrderNoStart"
                            label="注文番号開始"
                            :rules="[number, limit_length5]"
                            counter="5"
                            clearable
                            persistent-hint
                            :hint="`完全一致/範囲検索`"
                            hide-spin-buttons
                            type="number"
                          >
                          </v-text-field>
                        </v-col>
                        <!-- 注文番号終了 -->
                        <v-col cols="12" sm="2" xl="1">
                          <v-text-field
                            v-model="scOrderNoEnd"
                            label="注文番号終了"
                            :rules="[number, limit_length5]"
                            counter="5"
                            clearable
                            hide-spin-buttons
                            type="number"
                          >
                          </v-text-field>
                        </v-col>
                        <!-- 仕入先名 -->
                        <v-col cols="12" sm="2" xl="1">
                          <v-text-field
                            v-model="scSupplierName"
                            label="仕入先名"
                            :rules="[limit_length50]"
                            counter="10"
                            clearable
                            persistent-hint
                            :hint="`部分一致`"
                          >
                          </v-text-field>
                        </v-col>
                        <!-- 仕入先コード -->
                        <v-col cols="12" sm="2" xl="1">
                          <v-autocomplete
                            v-model="scSupplierCode"
                            label="仕入先コード"
                            :items="scSupplierList"
                            item-text="comboItem"
                            item-value="code"
                            clearable
                            persistent-hint
                            :hint="`完全一致`"
                          >
                          </v-autocomplete>
                        </v-col>
                        <!-- 仕入先伝票番号 -->
                        <v-col cols="12" sm="2" xl="1">
                          <v-text-field
                            v-model="scPurchaseSlipNo"
                            label="仕入先伝票番号"
                            :rules="[limit_length10]"
                            counter="10"
                            clearable
                            persistent-hint
                            :hint="`完全一致`"
                          >
                          </v-text-field>
                        </v-col>
                        <!-- 得意先名 -->
                        <v-col cols="12" sm="2" xl="1">
                          <v-text-field
                            v-model="scCssClientName"
                            label="得意先名"
                            :rules="[limit_length50]"
                            counter="50"
                            clearable
                            persistent-hint
                            :hint="`部分一致`"
                          >
                          </v-text-field>
                        </v-col>
                        <!-- 得意先コード開始 -->
                        <v-col cols="12" sm="2" :hint="`完全一致`">
                          <v-autocomplete
                            v-model="scCssClientCodeStart"
                            label="得意先コード開始"
                            :items="scCssClientNameList"
                            item-text="comboItem"
                            item-value="code"
                            clearable
                            persistent-hint
                            :hint="`完全一致/範囲検索`"
                          >
                          </v-autocomplete>
                        </v-col>
                        <!-- 得意先コード終了 -->
                        <v-col cols="12" sm="2">
                          <v-autocomplete
                            v-model="scCssClientCodeEnd"
                            label="得意先コード終了"
                            :items="scCssClientNameList"
                            item-text="comboItem"
                            item-value="code"
                            clearable
                          >
                          </v-autocomplete>
                        </v-col>
                        <!-- 売上担当者コード開始 -->
                        <v-col cols="12" sm="2">
                          <v-autocomplete
                            v-model="scCssSalesManagerCodeStart"
                            label="売上担当者コード開始"
                            :items="scCssSalesManagerList"
                            :rules="[number]"
                            clearable
                            persistent-hint
                            :hint="`完全一致/範囲検索`"
                          >
                          </v-autocomplete>
                        </v-col>
                        <!-- 売上担当者コード終了 -->
                        <v-col cols="12" sm="2">
                          <v-autocomplete
                            v-model="scCssSalesManagerCodeEnd"
                            label="売上担当者コード終了"
                            :items="scCssSalesManagerList"
                            :rules="[number]"
                            clearable
                          >
                          </v-autocomplete>
                        </v-col>
                        <!-- 仮伝出力ナンバー -->
                        <v-col cols="12" sm="2">
                          <v-text-field
                            v-model="scTempSlipNo"
                            label="仮伝出力ナンバー"
                            :rules="[number, limit_length5]"
                            counter="5"
                            clearable
                            persistent-hint
                            :hint="`完全一致`"
                            hide-spin-buttons
                            type="number"
                          >
                          </v-text-field>
                        </v-col>
                        <!-- 現場名 -->
                        <v-col cols="12" sm="2">
                          <v-text-field
                            v-model="scSpotName"
                            label="現場名"
                            :rules="[limit_length20]"
                            counter="20"
                            clearable
                            persistent-hint
                            :hint="`部分一致`"
                          >
                          </v-text-field>
                        </v-col>
                        <!-- 経由分伝票番号 -->
                        <v-col cols="12" sm="2">
                          <v-text-field
                            v-model="scToshinSplitNo"
                            label="経由分伝票番号"
                            :rules="[limit_length20]"
                            counter="20"
                            clearable
                            persistent-hint
                            :hint="`完全一致`"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <!-- 売上CSV -->
                        <v-col cols="12" sm="2">
                          <v-radio-group
                            v-model="scSalesCsvFlgRadioGroup"
                            label="売上CSV"
                            mandatory
                            dense
                            hide-details="false"
                          >
                            <v-radio label="全部" value="0"></v-radio>
                            <v-radio label="チェック" value="1"></v-radio>
                            <v-radio label="なし" value="2"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-divider class="hidden-sm-and-down mt-5" vertical></v-divider>
                        <!-- 仕入CSV -->
                        <v-col cols="12" sm="2">
                          <v-radio-group
                            v-model="scPurchaseCsvFlgRadioGroup"
                            label="仕入CSV"
                            mandatory
                            dense
                            hide-details="false"
                          >
                            <v-radio label="全部" value="0"></v-radio>
                            <v-radio label="チェック" value="1"></v-radio>
                            <v-radio label="なし" value="2"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-divider class="hidden-sm-and-down mt-5" vertical></v-divider>
                        <!-- トシン経由 -->
                        <v-col cols="12" sm="2">
                          <v-radio-group
                            v-model="scToshinSectionRadioGroup"
                            label="トシン経由"
                            mandatory
                            dense
                            hide-details="false"
                          >
                            <v-radio label="全部" value="0"></v-radio>
                            <v-radio label="チェック" value="1"></v-radio>
                            <v-radio label="なし" value="2"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-divider class="hidden-sm-and-down mt-5" vertical></v-divider>
                        <!-- トシン入力 -->
                        <v-col cols="12" sm="2">
                          <v-radio-group
                            v-model="scToshinInputRadioGroup"
                            label="トシン入力"
                            mandatory
                            dense
                            hide-details="false"
                          >
                            <v-radio label="全部" value="0"></v-radio>
                            <v-radio label="チェック" value="1"></v-radio>
                            <v-radio label="なし" value="2"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-divider class="hidden-sm-and-down mt-5" vertical></v-divider>
                        <!-- キャンセル -->
                        <v-col cols="12" sm="2">
                          <v-radio-group
                            v-model="scCancelRadioGroup"
                            label="キャンセル"
                            mandatory
                            dense
                            hide-details="false"
                          >
                            <v-radio label="全部" value="0"></v-radio>
                            <v-radio label="チェック" value="1"></v-radio>
                            <v-radio label="なし" value="2"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-row>
                        <!-- チェック項目 -->
                        <v-col cols="12" sm="4">
                          <v-checkbox
                            v-model="scEnableCheckBox"
                            :label="`チェック項目\n（AND検索）`"
                            dense
                            hide-details="false"
                          ></v-checkbox>
                        </v-col>
                        <!-- 入荷 -->
                        <v-col cols="12" sm="2">
                          <v-checkbox
                            v-model="scInFlgCheckBox"
                            :label="`入荷`"
                            dense
                            :disabled="!scEnableCheckBox"
                            hide-details="false"
                          ></v-checkbox>
                        </v-col>
                        <!-- 仮伝 -->
                        <v-col cols="12" sm="2">
                          <v-checkbox
                            ref="scTempSlipFlgCheckBox"
                            v-model="scTempSlipFlgCheckBox"
                            :label="`仮伝`"
                            dense
                            :disabled="!scEnableCheckBox"
                            hide-details="false"
                          ></v-checkbox>
                        </v-col>
                        <!-- 本伝 -->
                        <v-col cols="12" sm="2">
                          <v-checkbox
                            ref="slipFlgCheckBox"
                            v-model="slipFlgCheckBox"
                            :label="`本伝`"
                            dense
                            :disabled="!scEnableCheckBox"
                            hide-details="false"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-divider class="mt-5"></v-divider>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-row align="center" justify="center">
                      <v-col
                        cols="auto"
                        sm="auto"
                        md="auto"
                        lg="auto"
                        xl="auto"
                      >
                        <!-- 検索 -->
                        <v-btn
                          color="secondary"
                          v-on:click="onClickSearchOrderData"
                          rounded
                          class="my-0"
                        >
                          <v-icon left> mdi-magnify </v-icon>検索
                        </v-btn>
                      </v-col>
                      <!-- EXCEL出力ボタン -->
                      <!-- ●TODO 未保存であれば警告かエラー -->
                      <v-col
                        cols="auto"
                        sm="auto"
                        md="auto"
                        lg="auto"
                        xl="auto"
                      >
                        <v-btn
                          color="secondary"
                          v-on:click="outputExcel"
                          rounded
                          class="my-0"
                        >
                          <v-icon left> mdi-microsoft-excel </v-icon>EXCEL出力
                        </v-btn>
                      </v-col>
                      <!-- 仕入CSV出力 -->
                      <v-col
                        cols="auto"
                        sm="auto"
                        md="auto"
                        lg="auto"
                        xl="auto"
                      >
                        <v-btn
                          target="_blank"
                          color="secondary"
                          v-on:click="onClickPurchaseCsv"
                          rounded
                          class="my-0"
                        >
                          <v-icon left> mdi-file-delimited </v-icon>仕入CSV出力
                        </v-btn>
                      </v-col>
                      <!-- 納期一覧表出力 -->
                      <v-col
                        cols="auto"
                        sm="auto"
                        md="auto"
                        lg="auto"
                        xl="auto"
                      >
                        <v-btn
                          target="_blank"
                          color="secondary"
                          v-on:click="onClickDeliveryDateList"
                          rounded
                          class="my-0"
                        >
                          <v-icon left> mdi-file-pdf-box </v-icon>納期一覧表出力
                        </v-btn>
                      </v-col>
                      <!-- トシン伝票明細出力 -->
                      <v-col
                        cols="auto"
                        sm="auto"
                        md="auto"
                        lg="auto"
                        xl="auto"
                      >
                        <v-btn
                          color="secondary"
                          v-on:click="outputToshinSalesSlip"
                          rounded
                          class="my-0"
                        >
                          <v-icon left> mdi-file-pdf-box </v-icon>トシン伝票明細出力
                        </v-btn>
                      </v-col>
                      <v-dialog
                        v-model="dialogDeliveryDateList"
                        persistent
                        max-width="400"
                      >
                        <v-card>
                          <v-card-title class="text-h5">
                            得意先担当者名入力
                          </v-card-title>
                          <v-col>
                            <div v-for='(item, index) in tokuisakiForDeliveryDateList' :key='index'>
                              <v-text-field
                                v-model="item.tokuisakiTantoshaName"
                                :label="item.CSS_TOKUISAKI_NAME"
                                placeholder="担当者名"
                                outlined
                                clearable
                              ></v-text-field>
                            </div>
                          </v-col>
                          <v-card-title class="text-h5">
                            摘要欄入力
                          </v-card-title>
                          <v-col>
                            <v-textarea
                              outlined
                              counter
                              label="摘要"
                              :rules="[limit_length50]"
                              v-model="descriptionForDeliveryDateList"
                            ></v-textarea>
                          </v-col>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="secondary"
                              rounded
                              class="my-2"
                              @click="dialogDeliveryDateList = false"
                            >
                              <v-icon left> mdi-close </v-icon>キャンセル
                            </v-btn>
                            <v-btn
                              color="secondary"
                              rounded
                              class="my-2"
                              :disabled="!isDescriptionValid"
                              @click="outputDeliveryDateList"
                            >
                              <v-icon left> mdi-file-pdf-box </v-icon>出力
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="dataNotExistDialog" max-width="500px">
                        <v-card>
                          <v-card-title>
                            <span class="text-h5">確認</span>
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-card-text>
                            <v-container>
                              出力対象のデータが存在しませんでした
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn dark color="secondary" rounded @click="dataNotExistDialog = false"> OK </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-row>
                  </v-card-actions>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>売上検索</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card-text>
                    <v-form ref="formSearchConditionsSales">
                      <v-row>
                        <!-- 売上日付 -->
                        <v-col cols="12" sm="6" align-self="center">
                          <v-menu
                            v-model="salesDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            left
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="scSalesDateRange"
                                label="売上日付"
                                append-icon="mdi-calendar"
                                readonly
                                clearable
                                persistent-hint
                                :hint="`完全一致/範囲検索`"
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="scSalesDates = []"
                                @click:append="salesDateMenu = !salesDateMenu"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="scSalesDates"
                              range
                              @input="salesDateMenu = false"
                              :day-format="(date) => new Date(date).getDate()"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-divider class="hidden-sm-and-down mt-5" vertical></v-divider>
                        <!-- 売上CSVフラグ -->
                        <v-col cols="12" sm="3">
                          <v-radio-group
                            v-model="scSalesSalesCsvFlgRadio"
                            label="売上CSVフラグ"
                            mandatory
                            dense
                            hide-details="false"
                          >
                            <v-radio label="チェックなし" value="1"></v-radio>
                            <v-radio label="チェックあり" value="2"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-divider class="hidden-sm-and-down mt-5" vertical></v-divider>
                        <!-- 現場名混在 -->
                        <v-col cols="12" sm="3">
                          <v-radio-group
                            v-model="scSalesMixedSpotNameRadio"
                            label="現場名混在"
                            mandatory
                            dense
                            hide-details="false"
                          >
                            <v-radio label="しない" value="0"></v-radio>
                            <v-radio label="する" value="1"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-divider class="mt-5"></v-divider>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <!-- 売上検索 -->
                    <v-btn
                      color="secondary"
                      rounded
                      @click="onClickSearchSales"
                    >
                      <v-icon left> mdi-magnify </v-icon>売上検索
                    </v-btn>
                    <!-- 売上CSV出力 -->
                    <v-btn
                      color="secondary"
                      v-on:click="onClickSalesCsv"
                      rounded
                    >
                      <v-icon left> mdi-file-delimited </v-icon>売上CSV出力
                    </v-btn>
                  </v-card-actions>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!-- 発注明細データエリア -->
          <v-form ref="formDetails">
            <v-data-table
              dense
              :headers="headers"
              :items="searchItemList"
              :sort-by="['orderDate']"
              :sort-desc="[true]"
              :footer-props="footerProps"
              :items-per-page="100"
              class="elevation-5 mt-5 scroll-lock-order-search"
              :item-class="rowClasses"
              v-model="selected"
              show-select
              item-key="tableKey"
              multi-sort
              fixed-header
              height="545"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-container>
                    <v-row>
                      <v-toolbar-title class="pt-4">検索結果</v-toolbar-title>
                      <v-divider class="mx-4 pt-4" inset vertical></v-divider>
                      <v-toolbar-title class="pt-4">
                        {{ searchItemList.length }}件
                      </v-toolbar-title>
                      <div class="ml-4">
                        <v-autocomplete
                          v-model="filterValue"
                          label=" 絞り込み"
                          :items="filterList"
                          item-text="comboItem"
                          item-value="code"
                          @change="updateSummaryCombo"
                          hide-details="false"
                        >
                        </v-autocomplete>
                      </div>
                      <!-- メール送信ボタン -->
                      <!-- メール編集ダイアログ -->
                      <v-spacer></v-spacer>
                      <div>
                        <template v-if="selected == ''">
                          <v-btn
                            color="secondary"
                            dark
                            class="mb-2 mx-2"
                            rounded
                            @click="noSelected()"
                          >
                            <v-icon left>mdi-email-edit</v-icon>メール編集
                          </v-btn>
                        </template>
                        <template v-else-if="differentCssCode == true">
                          <v-btn
                            color="secondary"
                            dark
                            class="mb-2 mx-2"
                            rounded
                            @click="selectedItems()"
                          >
                            <v-icon left>mdi-email-edit</v-icon>メール編集
                          </v-btn>
                        </template>
                        <template v-else>
                          <v-dialog
                            v-model="dialog"
                            fullscreen
                            hide-overlay
                            transition="dialog-bottom-transition"
                            scrollable
                            :disabled="isDisabled"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="secondary"
                                dark
                                class="mb-2 mx-2"
                                rounded
                                v-bind="attrs"
                                v-on="on"
                                @click="openMailForm()"
                              >
                                <v-icon left>mdi-email-edit</v-icon>メール編集
                              </v-btn>
                            </template>
                            <v-card>
                              <v-toolbar dark color="primary">
                                <v-btn icon dark @click="closeMailForm()">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-toolbar>
                              <v-row>
                                <v-col>
                                  <!-- メールフォーム -->
                                  <mail-form
                                    :reqMailList="reqMailList"
                                    @result="closeMailForm"
                                  >
                                  </mail-form>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-dialog>
                        </template>
                        <!-- 単価登録ボタン -->
                        <v-btn
                          class="mb-2 mx-2"
                          dark
                          color="secondary"
                          rounded
                          @click="onClickInsertUnitPrice()"
                        >
                          <v-icon left>mdi-database-edit</v-icon>単価登録
                        </v-btn>
                        <!-- 原価変更依頼ボタン -->
                        <v-btn
                          class="mb-2 mx-2"
                          dark
                          color="secondary"
                          rounded
                          @click="onClickRequestChangingCost"
                        >
                          <v-icon left>mdi-send</v-icon>原価変更依頼
                        </v-btn>
                        <!-- 現場名コピーボタン -->
                        <v-btn
                          class="mb-2 mx-2"
                          dark
                          color="secondary"
                          rounded
                          @click="corySpot()"
                        >
                          <v-icon left>mdi-animation</v-icon>現場名コピー
                        </v-btn>
                      </div>
                    </v-row>
                  </v-container>
                </v-toolbar>
              </template>
              <!-- 操作 -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="m-0"
                      fab
                      dark
                      x-small
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      @click="editItem(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>修正</span>
                </v-tooltip>
              </template>
              <!-- 仕入先名 -->
              <template v-slot:[`item.supplierName`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="nameOmit">
                      {{item.supplierName}}</div>
                  </template>
                  <span>{{item.supplierName}}</span>
                </v-tooltip>
              </template>
              <!-- 品名 -->
              <template v-slot:[`item.productName`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.productName"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link class="productNameOmit">{{ item.productName }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">品名</div>
                    <v-text-field
                      v-model="item.productName"
                      id="productName"
                      :rules="[limit_lengthProductName]"
                      single-line
                      counter="40"
                      :counter-value="productCount"
                      autofocus
                      clearable
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 数量 -->
              <template v-slot:[`item.amount`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.amount"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.amount }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">数量</div>
                    <v-text-field
                      v-model.number="item.amount"
                      min="0"
                      id="amount"
                      :rules="[limit_length9, numberRequired, number]"
                      single-line
                      counter="9"
                      :counter-value="numCount"
                      autofocus
                      clearable
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 単位 -->
              <template v-slot:[`item.unit`]="{ item }">
                <v-edit-dialog
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @open="openUnit(item)"
                  @save="saveUnit(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.unit }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">単位</div>
                    <v-combobox
                      v-model="viewUnit"
                      id="unit"
                      :items="unitList"
                      :rules="[limit_length10]"
                      counter="10"
                      clearable
                      autofocus
                      hide-selected
                      ref="unitComboBox"
                    >
                    </v-combobox>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- キャンセル-->
              <template v-slot:[`item.cancelFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.cancelFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 仕入先伝票番号 -->
              <template v-slot:[`item.purchaseSlipNo`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.purchaseSlipNo"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.purchaseSlipNo }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">仕入先伝票番号</div>
                    <v-text-field
                      v-model="item.purchaseSlipNo"
                      id="purchaseSlipNo"
                      :rules="[limit_length10]"
                      single-line
                      counter="10"
                      autofocus
                      clearable
                      @change="purchaseSlipNo(item)"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- NET -->
              <template v-slot:[`item.net`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.net"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="calcNet(item)"
                  @cancel="cancel"
                >
                  <v-chip link>
                    <div v-if="item.net==null || item.net==``">{{item.net}}</div>
                    <div v-else>{{ new Number(item.net).toLocaleString() }}</div></v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">NET</div>
                    <v-text-field
                      v-model.number="item.net"
                      id="net"
                      :rules="[price]"
                      single-line
                      counter="11"
                      :counter-value="numCount"
                      clearable
                      ref="moneyInput"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- リベート区分 -->
              <template v-slot:[`item.rebateFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.rebateFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeRebate(item)"
                ></v-simple-checkbox>
              </template>
              <!-- お客様卸価格 -->
              <template v-slot:[`item.customerWholesaleCost`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.customerWholesaleCost"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip
                    link
                    :style="item.lastCost > item.customerWholesaleCost ? {'color': 'red', 'fontWeight': 'bolder'} : {'color': '', 'fontWeight': undefined}"
                  >
                    <div v-if="item.customerWholesaleCost==null || item.customerWholesaleCost==``">{{item.customerWholesaleCost}}</div>
                    <div v-else>{{ new Number(item.customerWholesaleCost).toLocaleString() }}</div></v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">お客様卸価格</div>
                    <v-text-field
                      v-model.number="item.customerWholesaleCost"
                      id="customerWholesaleCost"
                      :rules="[price]"
                      single-line
                      counter="11"
                      :counter-value="numCount"
                      clearable
                      ref="moneyInput"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 利益率 -->
              <template v-slot:[`item.profitRatio`]="{ item }">
                <div
                  :style="item.profitRatio == 100 ? {'color': 'red', 'fontWeight': 'bolder'} : {'color': '', 'fontWeight': undefined}"
                >
                  {{ item.profitRatio }}%
                </div>
              </template>
              <!-- 仕入CSV -->
              <template v-slot:[`item.purchaseCsvFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.purchaseCsvFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 売上CSV -->
              <template v-slot:[`item.salesCsvFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.salesCsvFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 掛率 -->
              <template v-slot:[`item.multiplationRate`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.multiplationRate"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.multiplationRate }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">掛率</div>
                    <v-text-field
                      v-model.number="item.multiplationRate"
                      id="multiplationRate"
                      :rules="[rate]"
                      single-line
                      autofocus
                      clearable
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 原 -->
              <template v-slot:[`item.calcCost`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="m-0"
                      fab
                      dark
                      x-small
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      @click="calcCost(item)"
                    >
                      原
                    </v-btn>
                  </template>
                  <span>「お客様卸価格」＝最終仕入価格 ／ 掛率</span>
                </v-tooltip>
              </template>
              <!-- 定 -->
              <template v-slot:[`item.calcListPrice`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="m-0"
                      fab
                      dark
                      x-small
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      @click="calcListPrice(item)"
                    >
                      定
                    </v-btn>
                  </template>
                  <span>「お客様卸価格」＝定価 × 掛率</span>
                </v-tooltip>
              </template>
              <!-- 卸 -->
              <template v-slot:[`item.calcWholesalePrice`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="m-0"
                      fab
                      dark
                      x-small
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      @click="calcWholesalePrice(item)"
                    >
                      卸
                    </v-btn>
                  </template>
                  <span>「お客様卸価格」＝卸価格原価 × 掛率</span>
                </v-tooltip>
              </template>
              <!-- 入荷時仕入価格 -->
              <template v-slot:[`item.arrivalPurchasePrice`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.arrivalPurchasePrice"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>
                    <div v-if="item.arrivalPurchasePrice==null || item.arrivalPurchasePrice==``">{{item.arrivalPurchasePrice}}</div>
                    <div v-else>{{ new Number(item.arrivalPurchasePrice).toLocaleString() }}</div></v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">入荷時仕入価格</div>
                    <v-text-field
                      v-model.number="item.arrivalPurchasePrice"
                      id="arrivalPurchasePrice"
                      :rules="[price]"
                      single-line
                      counter="11"
                      :counter-value="numCount"
                      clearable
                      ref="moneyInput"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 最終仕入価格 -->
              <template v-slot:[`item.lastCost`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.lastCost"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>
                    <div v-if="item.lastCost==null || item.lastCost==``">{{item.lastCost}}</div>
                    <div v-else>{{ new Number(item.lastCost).toLocaleString() }}</div></v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">最終仕入価格</div>
                    <v-text-field
                      v-model.number="item.lastCost"
                      id="lastCost"
                      :rules="[price]"
                      single-line
                      counter="11"
                      :counter-value="numCount"
                      clearable
                      ref="moneyInput"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 原価変更依頼 -->
              <template v-slot:[`item.costChangeFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.costChangeFlg"
                  color="secondary"
                  :ripple="false"
                  disabled
                ></v-simple-checkbox>
              </template>
              <!-- 得意先名 -->
              <template v-slot:[`item.cssClientName`]="{ item }">
                <v-edit-dialog
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @open="openCssClientName(item)"
                  @save="changeCssClientNameList(item, cssClientObj)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.cssClientName }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">得意先名</div>
                    <v-combobox
                      v-model="cssClientObj"
                      id="cssClientName"
                      :items="cssClientNameList"
                      :loading="isLoading"
                      :search-input.sync="searchCssClientName"
                      item-text="comboItem"
                      single-line
                      autofocus
                      placeholder="2~7文字の半角ｶﾅで入力"
                    ></v-combobox>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 着日 -->
              <template v-slot:[`item.inDate`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.inDate"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.inDate }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">着日</div>
                    <v-menu
                      v-model="item.inDateSelectMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      left
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.inDate"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          @click:append="item.inDateSelectMenu = !item.inDateSelectMenu"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.inDate"
                        @input="item.inDateSelectMenu = !item.inDateSelectMenu"
                        :day-format="(date) => new Date(date).getDate()"
                      ></v-date-picker>
                    </v-menu>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 着日未定 -->
              <template v-slot:[`item.inDateUndecidedFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.inDateUndecidedFlg"
                  color="secondary"
                  :ripple="false"
                ></v-simple-checkbox>
              </template>
              <!-- 当 -->
              <template v-slot:[`item.inDateToday`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="m-0"
                      fab
                      dark
                      x-small
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      @click="setDateToday(item, 0)"
                    >
                      当
                    </v-btn>
                  </template>
                  <span>着日に当日日付をセットします</span>
                </v-tooltip>
              </template>
              <!-- 翌 -->
              <template v-slot:[`item.inDateNextday`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="m-0"
                      fab
                      dark
                      x-small
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      @click="setDateNextday(item, 0)"
                    >
                      翌
                    </v-btn>
                  </template>
                  <span>着日に翌日日付をセットします</span>
                </v-tooltip>
              </template>
              <!-- 同注番Copy -->
              <template v-slot:[`item.copySameOrderInDate`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="m-0"
                      fab
                      dark
                      x-small
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      @click="copyInDateToSameOrder(item)"
                    >
                    <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>同じ注番のデータに着日にコピーします</span>
                </v-tooltip>
              </template>
              <!-- AM・PM -->
              <template v-slot:[`item.arrivalDescription`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.arrivalDescription"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.arrivalDescription }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">AM・PM</div>
                    <v-text-field
                      v-model="item.arrivalDescription"
                      id="arrivalDescription"
                      :rules="[limit_length20]"
                      single-line
                      counter="20"
                      autofocus
                      clearable
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 仮伝日付 -->
              <template v-slot:[`item.tempSlipPrintDate`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.tempSlipPrintDate"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.tempSlipPrintDate }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">仮伝日付</div>
                    <v-menu
                      v-model="item.tempSlipDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      left
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.tempSlipPrintDate"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          @click:append="item.tempSlipDateMenu = !item.tempSlipDateMenu"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.tempSlipPrintDate"
                        @input="item.tempSlipDateMenu = !item.tempSlipDateMenu"
                        :day-format="(date) => new Date(date).getDate()"
                      ></v-date-picker>
                    </v-menu>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 当 -->
              <template v-slot:[`item.tempSlipPrintDateToday`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="m-0"
                      fab
                      dark
                      x-small
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      @click="setDateToday(item, 1)"
                    >
                      当
                    </v-btn>
                  </template>
                  <span>仮伝日付に当日日付をセットします</span>
                </v-tooltip>
              </template>
              <!-- 翌 -->
              <template v-slot:[`item.tempSlipPrintDateNextday`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="m-0"
                      fab
                      dark
                      x-small
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      @click="setDateNextday(item, 1)"
                    >
                      翌
                    </v-btn>
                  </template>
                  <span>仮伝日付に翌日日付をセットします</span>
                </v-tooltip>
              </template>
              <!-- 仮伝印刷フラグ -->
              <template v-slot:[`item.tempSlipPrintFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.tempSlipPrintFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeTempSlipPrintFlg(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 備考 -->
              <template v-slot:[`item.detailsDescription`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.detailsDescription"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.detailsDescription }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">備考</div>
                    <v-text-field
                      v-model="item.detailsDescription"
                      id="detailsDescription"
                      :rules="[limit_length50]"
                      single-line
                      counter="50"
                      autofocus
                      clearable
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 現場名 -->
              <template v-slot:[`item.spotName`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.spotName"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.spotName }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">現場名</div>
                    <v-text-field
                      v-model="item.spotName"
                      id="spotName"
                      :rules="[limit_length20]"
                      single-line
                      counter="20"
                      autofocus
                      clearable
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 次回請求 -->
              <template v-slot:[`item.nextBillingFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.nextBillingFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 売上伝票日付 -->
              <template v-slot:[`item.salesSlipDate`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.salesSlipDate"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.salesSlipDate }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">売上伝票日付</div>
                    <v-menu
                      v-model="item.salesSlipDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      left
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.salesSlipDate"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          @click:append="item.salesSlipDateMenu = !item.salesSlipDateMenu"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.salesSlipDate"
                        @input="item.salesSlipDateMenu = !item.salesSlipDateMenu"
                        :day-format="(date) => new Date(date).getDate()"
                      ></v-date-picker>
                    </v-menu>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 日 -->
              <template v-slot:[`item.salesSlipDateToday`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="m-0"
                      fab
                      dark
                      x-small
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      @click="salesSlipDateToday(item)"
                    >
                      日
                    </v-btn>
                  </template>
                  <span>売上伝票日付に当日日付を設定</span>
                </v-tooltip>
              </template>
              <!-- メーカー卸価格 -->
              <template v-slot:[`item.wholesaleCost`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.wholesaleCost"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>
                    <div v-if="item.wholesaleCost==null || item.wholesaleCost==``">{{item.wholesaleCost}}</div>
                    <div v-else>{{ new Number(item.wholesaleCost).toLocaleString() }}</div></v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">メーカー卸価格</div>
                    <v-text-field
                      v-model.number="item.wholesaleCost"
                      id="wholesaleCost"
                      :rules="[price]"
                      single-line
                      counter="11"
                      :counter-value="numCount"
                      clearable
                      ref="moneyInput"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 定価 -->
              <template v-slot:[`item.listPrice`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.listPrice"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>
                    <div v-if="item.listPrice==null || item.listPrice==``">{{item.listPrice}}</div>
                    <div v-else>{{ new Number(item.listPrice).toLocaleString() }}</div></v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">定価</div>
                    <v-text-field
                      v-model.number="item.listPrice"
                      id="listPrice"
                      :rules="[number, limit_length9]"
                      single-line
                      counter="9"
                      :counter-value="numCount"
                      clearable
                      ref="moneyInput"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 一括 -->
              <template v-slot:[`item.summarySlipStatus`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="m-0"
                      fab
                      dark
                      x-small
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      @click="summarySlipStatus(item)"
                    >
                      一括
                    </v-btn>
                  </template>
                  <span
                    >入荷、仮伝、本伝を設定<br />入荷がチェックなし：入荷、仮伝、本伝をチェックあり<br />入荷がチェックあり：入荷、仮伝、本伝をチェックなし
                  </span>
                </v-tooltip>
              </template>
              <!-- 入荷 -->
              <template v-slot:[`item.inFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.inFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 仮伝 -->
              <template v-slot:[`item.tempSlipFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.tempSlipFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 本伝 -->
              <template v-slot:[`item.slipFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.slipFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- トシン経由区分 -->
              <template v-slot:[`item.toshinSection`]="{ item }">
                <v-simple-checkbox
                  v-model="item.toshinSection"
                  color="secondary"
                  :ripple="false"
                  disabled
                ></v-simple-checkbox>
              </template>
              <!-- トシン伝票日付 -->
              <template v-slot:[`item.toshinSplitDate`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.toshinSplitDate"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="toshinSplitDate(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.toshinSplitDate }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">トシン伝票日付</div>
                    <v-menu
                      v-model="item.toshinSplitDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      left
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.toshinSplitDate"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          @click:append="item.toshinSplitDateMenu = !item.toshinSplitDateMenu"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.toshinSplitDate"
                        @input="item.toshinSplitDateMenu = !item.toshinSplitDateMenu"
                        :day-format="(date) => new Date(date).getDate()"
                      ></v-date-picker>
                    </v-menu>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- トシン伝票番号 -->
              <template v-slot:[`item.toshinSplitNo`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.toshinSplitNo"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.toshinSplitNo }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">トシン伝票番号</div>
                    <v-text-field
                      v-model="item.toshinSplitNo"
                      id="toshinSplitNo"
                      :rules="[limit_length20]"
                      single-line
                      counter="20"
                      autofocus
                      clearable
                      @change="toshinSplitNo(item)"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- トシン伝票印刷済 -->
              <template v-slot:[`item.toshinSplitPrintFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.toshinSplitPrintFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeToshinSplitPrintFlg(item)"
                ></v-simple-checkbox>
              </template>
              <!-- メール送信済 -->
              <template v-slot:[`item.sentEmailFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.sentEmailFlg"
                  color="secondary"
                  :ripple="false"
                  disabled
                ></v-simple-checkbox>
              </template>
              <!-- 返品 -->
              <template v-slot:[`item.returnFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.returnFlg"
                  color="secondary"
                  :ripple="false"
                  disabled
                ></v-simple-checkbox>
              </template>
              <!-- 値引 -->
              <template v-slot:[`item.discountFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.discountFlg"
                  color="secondary"
                  :ripple="false"
                  disabled
                ></v-simple-checkbox>
              </template>
              <!-- 良品/不良品 -->
              <template v-slot:[`item.returnStatus`]="{ item }">
                <v-radio-group
                  v-model="item.returnStatus"
                  label=""
                  row
                  dense
                  disabled
                  hide-details="false"
                >
                  <v-radio
                    v-for="returnStatus in returnStatusList"
                    :key="returnStatus.id"
                    :label="returnStatus.name"
                    :value="returnStatus.id"
                  ></v-radio>
                </v-radio-group>
              </template>
              <!-- 引上 -->
              <template v-slot:[`item.pullUpFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.pullUpFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 依頼 -->
              <template v-slot:[`item.returnRequestFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.returnRequestFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 返却 -->
              <template v-slot:[`item.returnProductFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.returnProductFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 仕入赤伝 -->
              <template v-slot:[`item.purchaseAkaCsvOutputFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.purchaseAkaCsvOutputFlg"
                  color="secondary"
                  :ripple="false"
                  disabled
                ></v-simple-checkbox>
              </template>
              <!-- 売上赤伝 -->
              <template v-slot:[`item.salesCsvAkaCsvOutputFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.salesCsvAkaCsvOutputFlg"
                  color="secondary"
                  :ripple="false"
                  disabled
                ></v-simple-checkbox>
              </template>
            </v-data-table>
          </v-form>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-card-actions>
          <!-- 保存ボタン -->
          <v-btn color="secondary" @click="onClickRegister" rounded>
            <v-icon left> mdi-content-save </v-icon>保存
          </v-btn>
        </v-card-actions>
      </v-row>
      <v-spacer></v-spacer>
    </v-container>
  </div>
</template>
<script>
import MailForm from "../components/MailForm.vue";
// graphqlのインポート
import AWS from "aws-sdk";
import { Auth, Amplify, API, graphqlOperation, Storage } from "aws-amplify";
import {
  getM_SHOKISETTEI,
  listV_HACCHU_DATA_WITH_MEISAI,
  listM_SHIIRESAKI,
  getM_SHIIRESAKI
} from "@/graphql/queries";
import {
  executeHanbaikanriSql,
  executeTransactSql,
  executeCsvFileCreation,
  executeExcelFileCreation,
 } from "@/graphql/mutations";
import { base64Encode } from '@/assets/js/common';
import { MAILINGLIST_ADDRESS, AWS_DEFAULT_REGION, RETURN_PRODUCT_STATUS, UNIT_LIST, SES_API_VERSION, CUSTOM_FOOTER_PROPS_SEARCH_AREA, RESULT_ROWS_COUNT_LIMIT } from '@/assets/js/const';
import { CONFIRM_MESSAGE_UNSAVED_DATA, CONFIRM_MESSAGE_SAVE_DATA, CONFIRM_MESSAGE_CSV_INSERT_DATA, CONFIRM_MESSAGE_ZERO_CUSTOMER_PRICE, CONFIRM_MESSAGE_LEAVE_EMAIL_FORM, CONFIRM_MESSAGE_INSERT_UNIT_PRICE, ALERT_MESSAGE_COMMON_ERROR, ALERT_COMPLETE_CRUD_DATA, ALERT_UNSAVED_DATA, ALERT_SEARCH_ZERO_DATA, ALERT_ALREADY_SAVED_DATA, ALERT_UNSELECTED_DATA, ALERT_MESSAGE_EMAIL_AUTH_ERROR, ALERT_NO_CSV_DATA, ALERT_ROWS_COUNT_LIMIT, ALERT_TOO_MANY_ROWS_COUNT } from "@/assets/js/dialog_messages";
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
export default {
  name: "OrderSearch",
  props: {
    reqOrderDataId: Number,
  },
  components: {
    MailForm,
  },
  data: () => ({
    filterValue: 0,
    filterList: [
      { comboItem: "全部", code: 0 },
      { comboItem: "通常", code: 7 },
      { comboItem: "納期", code: 1 },
      { comboItem: "売上準備", code: 2 },
      { comboItem: "売上", code: 3 },
      { comboItem: "入荷・仕入", code: 4 },
      { comboItem: "トシン", code: 5 },
      { comboItem: "返品", code: 6 },
    ],

    // 発注明細VIEWデータリスト
    // 画面上の設定値（発注明細VIEWデータ）
    originalParamsViews: {
      // 発注データID
      orderDataId: null,
      // 部門コード
      departmentCode: null,
      // パナソニック区分
      panasonicSection: null,
      // 注文番号
      orderNo: null,
      // 注文日付
      orderDate: null,
      // 仕入先コード
      supplierCode: null,
      // 仕入先名
      supplierName: null,
      // 手数料区分
      commissionSection: null,
      // トシン経由区分
      toshinSection: 0,
      // トシンお客様コード
      toshinClientNo: null,
      // トシン受発注番号
      toshinOrderNo: null,
      // トシン発注者注文番号
      toshinManagerOrderNo: null,
      // 担当者コード
      managerCode: null,
      // 担当者名
      managerName: null,
      // 摘要
      description: null,
      // 明細No
      orderBranchNo: null,
      // 商品コード
      productCode: null,
      // 品番
      productNo: null,
      // 品名
      productName: null,
      // 数量
      amount: 0,
      // NET
      net: 0,
      // 最終仕入価格
      lastCost: 0,
      // 得意先名
      cssClientName: null,
      // 着日
      inDate: null,
      // 着日未定
      inDateUndecidedFlg: false,
      // 備考
      detailsDescription: null,
      // 売伝NO
      tempSlipNo: null,
      // 返伝NO
      returnSlipNo: null,
      // 卸価格原価
      wholesaleCost: null,
      // 入荷フラグ
      inFlg: false,
      // 仮伝フラグ
      tempSlipFlg: false,
      // 本伝フラグ
      slipFlg: false,
      // 単位
      unit: "個",
      // 定価
      listPrice: null,
      // CSS得意先コード
      cssClientCode: null,
      // 現場名
      spotName: null,
      // 仮伝印刷フラグ
      tempSlipPrintFlg: false,
      // 仮伝日付
      tempSlipPrintDate: null,
      // JANコード
      productJanCode: null,
      // 入荷時仕入価格
      arrivalPurchasePrice: null,
      // 仕入先伝票番号
      purchaseSlipNo: null,
      // リベートフラグ
      rebateFlg: false,
      // CSS売上担当者コード
      cssSalesManagerCode: null,
      // 売上伝票日付
      salesSlipDate: null,
      // 次回請求フラグ
      nextBillingFlg: false,
      // お客様卸価格
      customerWholesaleCost: 0,
      // 計算方式
      calcMethod: null,
      // 掛率
      multiplationRate: null,
      // トシン伝票日付
      toshinSplitDate: null,
      // トシン伝票NO
      toshinSplitNo: null,
      // トシン伝票印刷フラグ
      toshinSplitPrintFlg: false,
      // トシン伝票印刷日付
      toshinSplitPrintDate: null,
      // 売上CSVフラグ
      salesCsvFlg: false,
      // 売上CSV出力日付
      salesCsvOutputDate: null,
      // 仕入CSVフラグ
      purchaseCsvFlg: false,
      // 仕入CSV出力日付
      purchaseCsvOutputDate: null,
      // 着日備考
      arrivalDescription: null,
      // 原価依頼フラグ
      costChangeFlg: false,
      // キャンセルフラグ
      cancelFlg: false,
      // メール送信済みフラグ
      sentEmailFlg: false,
      // 返品ステータス
      returnStatus: 0,
      // 伝票区分
      splitSection: 1,
      // 引上フラグ
      pullUpFlg: false,
      // 依頼フラグ
      returnRequestFlg: false,
      // 返却フラグ
      returnProductFlg: false,
      // 仕入赤伝フラグ
      purchaseAkaCsvOutputFlg: false,
      // 仕入赤伝日付
      purchaseAkaCsvOutputDate: null,
      // 売上赤伝フラグ
      salesCsvAkaCsvOutputFlg: false,
      // 売上赤伝日付
      salesAkaCsvOutputDate: null,
      // CSS仕入先コード
      cssSupplierCode: null,
      // 仕入先リベート区分
      supplierRebateSection: 0,
      // 着日区分
      inDateSection: 0,
      // 発注書区分
      purchaseOrderSection: 3,

      // テーブルの一意キー
      tableKey: null,
      // データ更新フラグ
      isUpdatedItem: false,
    },

    // 作成日付
    // ●TODO 6か月前の1日
    scOrderDates: [
      new Date(
        Date.now() -
          new Date().getTimezoneOffset() * 60000 -
          60 * 24 * 30 * 6 * 60000
      )
        .toISOString()
        .substr(0, 10),
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    ],
    scOrderDateMenu: false,

    // 検索条件 注文番号開始
    scOrderNoStart: null,
    // 検索条件 注文番号終了
    scOrderNoEnd: null,
    // 検索条件 仕入先名
    scSupplierName: null,
    // 検索条件 仕入先コード
    scSupplierCode: null,
    // 検索条件 仕入先伝票番号
    scPurchaseSlipNo: null,
    // 検索条件 得意先コード開始
    scCssClientCodeStart: null,
    // 検索条件 得意先名
    scCssClientName: null,
    // 検索条件 得意先コード終了
    scCssClientCodeEnd: null,
    // 検索条件 着日
    scInDates: [],
    // 検索条件 売上担当者コード開始
    scCssSalesManagerCodeStart: null,
    // 検索条件 売上担当者コード終了
    scCssSalesManagerCodeEnd: null,
    // 検索条件 仮伝出力ナンバー
    scTempSlipNo: null,
    // 検索条件 現場名
    scSpotName: null,
    // 検索条件 経由分伝票番号
    scToshinSplitNo: null,
    // 検索条件 売上CSV
    scSalesCsvFlgRadioGroup: 0,
    // 検索条件 仕入CSV
    scPurchaseCsvFlgRadioGroup: 0,
    // 検索条件 トシン経由
    scToshinSectionRadioGroup: 0,
    // 検索条件 トシン入力
    scToshinInputRadioGroup: 0,
    // 検索条件 キャンセル
    scCancelRadioGroup: 0,
    // 検索条件 売上CSVフラグ
    scSalesSalesCsvFlgRadio: 0,
    // 検索条件 現場名混在
    scSalesMixedSpotNameRadio: 0,
    // 検索条件 項目チェック
    scEnableCheckBox: false,
    // 検索条件 入荷
    scInFlgCheckBox: false,
    // 検索条件 仮伝
    scTempSlipFlgCheckBox: false,
    // 検索条件 本伝
    slipFlgCheckBox: false,

    // 売上日付
    scSalesDates: [
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    ],
    salesDateMenu: false,

    // アコーディオン
    panel: [1, 0],

    // テーブルフッターオプション
    footerProps: CUSTOM_FOOTER_PROPS_SEARCH_AREA,
    // 入力規則
    // 入力必須の制約
    required: (value) => !!value || "必ず入力してください",
    numberRequired: (value) =>
      (!!`${value}` && value !== null) || "必ず入力してください",
    // 桁数の制約 最大5桁
    limit_length5: (value) =>
      value == null || value.length <= 5 || "5桁以内で入力してください",
    // 桁数の制約 最大9文字
    limit_length9: (value) =>
      value == null || `${value}`.replace(/,/g, "").trim().length <= 9 || "9桁以内で入力してください",
    // 桁数の制約 最大10文字
    limit_length10: (value) =>
      value == null || value.length <= 10 || "10文字以内で入力してください",
    // 文字数の制約 最大20文字
    limit_length20: (value) =>
      value == null || value.length <= 20 || "20文字以内で入力してください",
    // 文字数の制約 最大50文字
    limit_length50: (value) =>
      value == null || value.length <= 50 || "50文字以内で入力してください",
    // 文字数の制約 半角40文字、全角20文字
    limit_lengthProductName: value => {
      let len = 0;
      if (value !== null) {
        // 半角1文字、全角2文字としてカウント
        for (let i = 0; i < value.length; i++) {
          (value[i].match(/[ -~]/)) || (value[i].match(/[ｦ-ﾟ]/)) ? (len += 1) : (len += 2);
        }
      }
      return len <= 40 || `半角40文字、全角20文字以内で入力してください`
    },
    // 数値の制約
    number: (value) =>
      value == null || /^[0-9,.-]+$/.test(value) || "数値で入力してください",
    // 掛率の制約
    rate: (value) =>
      value == null ||
      /^([1-9][0-9]{0,2}|0)(\.[0-9]{1,5})?$/.test(value) ||
      "小数点以下5桁で入力してください",
    // 価格の制約
    price: (value) =>
      value == null ||
      /^([-+]?[1-9][0-9]{0,7}|0)(\.[0-9]{1,2})?$/.test(
        String(value).replace(/,/g, "").trim()
      ) ||
      "整数値8桁、小数値2桁で入力してください",
    // 数値系のカウント
    numCount: (v) => {
      if (v !== null) {
        return `${v}`.replace(/-/g, "").trim().length;
      } else {
        return 0;
      }
    },
    // 商品項目（sjis）のカウント
    productCount: (v) => {
      if (v !== null) {
        let len = 0;
        // 半角1文字、全角2文字としてカウント
        for (let i = 0; i < v.length; i++) {
          (v[i].match(/[ -~]/)) || (v[i].match(/[ｦ-ﾟ]/))  ? (len += 1) : (len += 2);
        }
        return len;
      } else {
        return 0;
      }
    },
    // チェックボックス
    selected: [],
    // 追加編集ダイアログ
    dialog: false,
    // 削除ダイアログ
    dialogDelete: false,
    // コンポーネント引き渡し用変数
    reqMailList: [],
    // Datatablesのヘッダー
    headers: [
      { text: "", value: "data-table-select", width: "50px" },
      { text: "操作", value: "actions", sortable: false, width: "42px" },
      { text: "発注日付", value: "orderDate", width: "85px" },
      { text: "注文番号", value: "orderNo", width: "40px" },
      { text: "明細No", value: "orderBranchNo", width: "40px" },
      { text: "品名", value: "productName", width: "200px" },
      { text: "商品コード", value: "productCode", width: "80px" },
      { text: "CSS仕入先コード", value: "cssSupplierCode", width: "80px" },
      { text: "仕入先名", value: "supplierName", width: "100px" },
      { text: "数量", value: "amount", width: "50px" },
      { text: "単位", value: "unit", width: "50px" },
      { text: "キャンセル", value: "cancelFlg", width: "50px"},
      { text: "仕入先伝票番号", value: "purchaseSlipNo", width: "100px" },
      { text: "NET", value: "net", width: "30px" },
      { text: "リベート区分", value: "rebateFlg", width: "60px" },
      { text: "お客様卸価格", value: "customerWholesaleCost", width: "50px" },
      { text: "仕入CSV", value: "purchaseCsvFlg", width: "40px" },
      { text: "売上CSV", value: "salesCsvFlg", width: "40px" },
      { text: "計算方式", value: "calcMethod", width: "70px" },
      { text: "掛率", value: "multiplationRate", width: "50px" },
      { text: "原", value: "calcCost", width: "30px" },
      { text: "定", value: "calcListPrice", width: "30px" },
      { text: "卸", value: "calcWholesalePrice", width: "30px" },
      { text: "入荷時仕入価格", value: "arrivalPurchasePrice", width: "70px" },
      { text: "最終仕入価格", value: "lastCost", width: "60px" },
      { text: "利益率", value: "profitRatio", width: "40px" },
      { text: "原価変更依頼", value: "costChangeFlg", width: "60px" },
      { text: "得意先コード", value: "cssClientCode", width: "110px" },
      { text: "得意先名", value: "cssClientName", width: "200px" },
      { text: "着日", value: "inDate", width: "85px" },
      { text: "着日未定", value: "inDateUndecidedFlg", width: "40px" },
      { text: "当", value: "inDateToday", width: "40px" },
      { text: "翌", value: "inDateNextday", width: "40px" },
      { text: "同注番Copy", value: "copySameOrderInDate", width: "50px" },
      { text: "AM・PM", value: "arrivalDescription", width: "80px" },
      { text: "仮伝日付", value: "tempSlipPrintDate", width: "85px" },
      { text: "当", value: "tempSlipPrintDateToday", width: "40px" },
      { text: "翌", value: "tempSlipPrintDateNextday", width: "40px" },
      { text: "仮伝印刷フラグ", value: "tempSlipPrintFlg", width: "60px" },
      { text: "備考", value: "detailsDescription", width: "200px" },
      { text: "売伝No", value: "tempSlipNo", width: "100px" },
      { text: "現場名", value: "spotName", width: "200px" },
      { text: "次回請求", value: "nextBillingFlg", width: "40px" },
      { text: "売上伝票日付", value: "salesSlipDate", width: "85px" },
      { text: "日", value: "salesSlipDateToday", width: "30px" },
      { text: "メーカー卸価格", value: "wholesaleCost", width: "60px" },
      { text: "定価", value: "listPrice", width: "40px" },
      { text: "一括", value: "summarySlipStatus", width: "40px" },
      { text: "入荷", value: "inFlg", width: "40px" },
      { text: "仮伝", value: "tempSlipFlg", width: "40px" },
      { text: "本伝", value: "slipFlg", width: "40px" },
      { text: "トシン経由区分", value: "toshinSection", width: "70px" },
      { text: "トシン伝票日付", value: "toshinSplitDate", width: "80px" },
      { text: "トシン伝票番号", value: "toshinSplitNo", width: "80px" },
      { text: "トシン伝票印刷済", value: "toshinSplitPrintFlg", width: "80px" },
      { text: "トシン伝票印刷日付", value: "toshinSplitPrintDate", width: "100px" },
      { text: "メール送信済", value: "sentEmailFlg", width: "50px" },
      { text: "返品", value: "returnFlg", width: "40px" },
      { text: "値引", value: "discountFlg", width: "40px" },
      { text: "引上", value: "pullUpFlg", width: "40px" },
      { text: "依頼", value: "returnRequestFlg", width: "40px" },
      { text: "返却", value: "returnProductFlg", width: "40px" },
      { text: "返伝No", value: "returnSlipNo", width: "40px" },
      { text: "仕入赤伝", value: "purchaseAkaCsvOutputFlg", width: "40px" },
      { text: "仕入赤伝日付", value: "purchaseAkaCsvOutputDate", width: "85px" },
      { text: "売上赤伝", value: "salesCsvAkaCsvOutputFlg", width: "40px" },
      { text: "売上赤伝日付", value: "salesAkaCsvOutputDate", width: "85px" },
      { text: "登録者名", value: "managerName", width: "80px" },
      { text: "売上CSV作成日付", value: "salesCsvOutputDate", width: "85px" },
      { text: "仕入CSV作成日付", value: "purchaseCsvOutputDate", width: "85px" },
      { text: "売上担当者コード", value: "cssSalesManagerCode", width: "80px" },
    ],
    // テーブルに格納するデータの初期値
    searchItemList: [],
    // 未保存チェック用
    originalParamsDetails: [],
    // 単位のコンボボックスを追加
    unitList: UNIT_LIST,
    unit: "個",
    viewUnit: null,
    // ラジオボタン初期設定
    returnStatusList: RETURN_PRODUCT_STATUS,

    // ログインユーザー情報
    loginUserInfo: null,
    // 認証済みEメールフラグ
    authEmail: false,
    // 検索条件 仕入先リスト（コード、名前）
    scSupplierList: [],
    // 検索条件 担当者リスト（コード、名前）
    scCssSalesManagerList: [],
    // 検索条件 得意先リスト（コード、名前）
    scCssClientNameList: [],
    // 得意先リストAPI検索用（コード、名前）
    cssClientNameList: [],
    // 商品リスト（コード、名前）
    productList: [],

    // データテーブル内ダイアログでのカレンダー入力
    inDateSelectMenu: false,
    tempSlipDateMenu: false,
    scInDateMenu: false,
    salesSlipDateMenu: false,
    toshinSplitDateMenu: false,
    purchaseAkaCsvOutputDateMenu: false,
    salesAkaCsvOutputDateMenu: false,

    // 検索用テキストボックス無効化フラグ
    isDisableText: false,

    // API検索用オートコンプリート
    isLoading: false,
    searchCssClientName: null,
    cssClientObj: null,

    // ダイアログ表示フラグ
    dialogDeliveryDateList: false,
    // ダイアログ内フォーム検証
    isDescriptionValid: true,
    // 納期一覧表得意先リスト
    tokuisakiForDeliveryDateList: [],
    // 納期一覧表ダイアログ内摘要欄
    descriptionForDeliveryDateList: "～ご注文を頂きありがとうございました。又どうぞ宜しくお願い致します～",

    // トシン売上伝票明細データなしダイアログ表示フラグ
    dataNotExistDialog: false,

    // 着日未定チェックボックス
    inDateUndecidedCheckBox: false,
  }),
  computed: {
    scOrderDateRange: {
      get() {
        let rangeText = null;
        if (this.scOrderDates.length > 0) {
          if (this.scOrderDates[0] === this.scOrderDates[1]) {
            // startとendで同じ日付が選択された場合、日付1つのみ表示
            rangeText = this.scOrderDates[0];
          } else if (this.scOrderDates[0] > this.scOrderDates[1]) {
            // startよりendの方が日付が若い場合、日付を左右入れ替え、" ~ "で分けて表示
            rangeText = `${this.scOrderDates[1]} ~ ${this.scOrderDates[0]}`;
          } else {
            // startとendを" ~ "で分けて表示
            rangeText = this.scOrderDates.join(" ~ ");
          }
        }
        return rangeText;
      },
      set(value) {
        console.log("set： value = " + value);
      },
    },
    scInDateRange: {
      get() {
        let rangeText = null;
        if (this.scInDates.length > 0) {
          if (this.scInDates[0] === this.scInDates[1]) {
            // startとendで同じ日付が選択された場合、日付1つのみ表示
            rangeText = this.scInDates[0];
          } else if (this.scInDates[0] > this.scInDates[1]) {
            // startよりendの方が日付が若い場合、日付を左右入れ替え、" ~ "で分けて表示
            rangeText = `${this.scInDates[1]} ~ ${this.scInDates[0]}`;
          } else {
            // startとendを" ~ "で分けて表示
            rangeText = this.scInDates.join(" ~ ");
          }
        }
        return rangeText;
      },
      set(value) {
        console.log("set： value = " + value);
      },
    },
    scSalesDateRange: {
      get() {
        let rangeText = null;
        if (this.scSalesDates.length > 0) {
          if (this.scSalesDates[0] === this.scSalesDates[1]) {
            // startとendで同じ日付が選択された場合、日付1つのみ表示
            rangeText = this.scSalesDates[0];
          } else if (this.scSalesDates[0] > this.scSalesDates[1]) {
            // startよりendの方が日付が若い場合、日付を左右入れ替え、" ~ "で分けて表示
            rangeText = `${this.scSalesDates[1]} ~ ${this.scSalesDates[0]}`;
          } else {
            // startとendを" ~ "で分けて表示
            rangeText = this.scSalesDates.join(" ~ ");
          }
        }
        return rangeText;
      },
      set(value) {
        console.log("set： value = " + value);
      },
    },
    differentCssCode: {
      get() {
        let differentCssCode;
        for (const data of this.selected) {
          if (data.cssClientCode !== this.selected[0].cssClientCode) {
            differentCssCode = true;
            return differentCssCode;
          }
        }
        differentCssCode = false;
        return differentCssCode;
      },
      set(value) {
        console.log("differentCssCode set value = " + value);
      },
    },
    isDisabled: {
      get() {
        for (const data of this.selected) {
          // メール送信済みのデータがあればdialogをdisabledにする
          if (data.sentEmailFlg == true) {
            return true;
          }
          // 品名が未入力ならばdialogをdisabledにする
          if (!data.productName) {
            return true;
          }
          // 数量が未入力ならばdialogをdisabledにする
          if (!data.amount) {
            return true;
          }
          // 数量がマイナスならばdialogをdisabledにする
          if (String(data.amount).indexOf("-") != -1) {
            return true;
          }
          // 着日が未入力ならばdialogをdisabledにする
          if (!data.inDate) {
            return true;
          }
          // 返品値引のレコードならばdialogをdisabledにする
          if (data.returnFlg || data.discountFlg) {
            return true;
          }
        }
        // 未保存のデータがあればdialogをdisabledにする
        if (!this.isSavedData()) {
          return true;
        }
        // Eメールが未認証ならばdialogをdisabledにする
        if (!this.authEmail) {
          return true;
        }
        return false;
      },
      // dialogのdisabledをfalseにする
      set(value) {
        if (value == false) {
          this.dialog = true;
        }
      },
    },
  },
  /**
   * 特定のデータや算出プロパティの状態の変化を監視
   */
  watch: {
    /**
     * 得意先コードのコンボボックス作成
     */
    async searchCssClientName(val) {
      // メソッド名を定義
      const method_name="searchCssClientName"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 入力値がNULL、2文字以内、オートコンプリートの選択値（7文字以上）だった場合
      if (val == null || val.length < 2 || val.length > 7) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // ローディング中は検索しない
      if (this.isLoading) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 半角カナチェック
      if (!val.match(/^[ｦ-ﾟ]*$/)) {
        console.log("ｶﾀｶﾅではない")
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      console.log("val = " + val);
      // オートコンプリートをローディング中にする
      this.isLoading = true
      // 得意先コードのリストを初期化
      this.cssClientNameList = [];
      let sqlList = [
        `SELECT TK_KANA, TK_TORCD, TK_TORNM1, TK_BASCD, TK_TANCD FROM TOKUI` +
          ` WHERE TK_KANA LIKE '${val}%'` +
          ` AND TK_BASCD = ` + this.loginUserInfo.BUMON_CD +
          ` AND TK_EDELKB <> 1` +
          ` AND SUBSTR(TK_TORCD, -2) <> 99` +
          ` ORDER BY TK_KANA`,
      ];
      let resultClientSerchData = await this.executeHanbaikanriSql(sqlList);
      if (resultClientSerchData == null || resultClientSerchData == void 0) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // オートコンプリートのローディングを解除
        this.isLoading = false;
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      console.log("get resultClientSerchData = " + resultClientSerchData[0].length)
      for (const data of resultClientSerchData[0]) {
        this.cssClientNameList.push({
          comboItem: data.TK_TORCD + "：" + data.TK_KANA,
          kana: data.TK_KANA,
          name: data.TK_TORNM1,
          code: data.TK_TORCD,
          managerCode: data.TK_TANCD,
        });
      }
      // オートコンプリートのローディング中を解除する
      this.isLoading = false;
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
  },
  /**
   * created ライフサイクルフック
   */
  created: async function () {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0006"
    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // ローディング画面の開始
    this.$store.commit("setLoading", true);

    // ログイン中のユーザー情報を取得
    if (!(await this.getLoginUserInfo())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // 初期化処理の実行
    if (!(await this.initialize())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }
    // ローディングを解除
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },

  /**
   * mounted ライフサイクルフック
   */
  mounted: async function () {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0007"
    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    
    // バリデーションエラーをリセット
    this.resetValidate();

    // ローディングを解除
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * beforeRouteLeave ライフサイクルフック
   */
  async beforeRouteLeave(to, from, next) {
    // メソッド名を定義
    const method_name="beforeRouteLeave"
    // オペレーションタイプを定義
    const operation_type="0008"
    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

    // 未保存チェックを実施し、未保存の場合はエラーを表示
    if (!this.isSavedData()) {
      if (confirm(CONFIRM_MESSAGE_UNSAVED_DATA)) {
        // バリデーションチェックでエラーの際は登録しない
        if (!this.validateCheck()) {
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // ローディング画面の開始
        this.$store.commit("setLoading", true);
        // 修正：入力データで更新
        if (!(await this.updateTable())) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // エラーダイアログを表示
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // 画面遷移しない
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // 完了ダイアログを表示
        alert(ALERT_COMPLETE_CRUD_DATA);
      }
    }
    next();
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  methods: {
    /**
     * ログインユーザー情報の取得処理
     * ・ログインユーザーコードの取得
     * ・ログインユーザーの所属部門の取得
     */
    async getLoginUserInfo() {
      // メソッド名を定義
      const method_name="getLoginUserInfo"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ログイン中のユーザー情報を取得
      this.loginUserInfo = this.$store.getters.user;

      // データ取得
      let condition = { BUMON_CD: this.loginUserInfo.BUMON_CD };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(getM_SHOKISETTEI, condition)
        );
        // 結果判定
        if (result.data.getM_SHOKISETTEI) {
          // データを取得できた場合
          // ログイン中ユーザーの所属部門情報を取得
          this.departmentInfo = result.data.getM_SHOKISETTEI;
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query:"getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          if (result.errors) {
            // データを取得できなかった場合
            // @TODO データ取得無しの場合の処理を以下に記載。
            // ログ出力(SQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3010"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // データ0件時
            // ログ出力(SQL-end_0)
            addLog('INFO', {message: MSG.INFO["1005"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          }
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 初期化処理
     * ・コンボボックスのデータ取得
     * ・テキストボックスのデータ取得
     */
    async initialize() {
      // メソッド名を定義
      const method_name="initialize"
      // オペレーションタイプを定義
      const operation_type="0001"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の表示
      this.$store.commit("setLoading", true);

      // マスタ系テーブルから値を取得
      if (!(await this.getMasterData())) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }

      // 未保存チェック用配列を更新
      this.saveOriginalParams();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 検索ボタン押下処理
     */
    async onClickSearchOrderData() {
      // メソッド名を定義
      const method_name="onClickSearchOrderData"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 未保存チェックを実施し、未保存の場合はエラーを表示
      if (!this.isSavedData()) {
        if (confirm(CONFIRM_MESSAGE_UNSAVED_DATA)) {
          // バリデーションチェックでエラーの際は登録しない
          if (!this.validateCheck()) {
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }
          // ローディング画面の開始
          this.$store.commit("setLoading", true);
          // 修正：入力データで更新
          if (!(await this.updateTable())) {
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // エラーダイアログを表示
            alert(ALERT_MESSAGE_COMMON_ERROR);
            // 画面遷移しない
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // 完了ダイアログを表示
          alert(ALERT_COMPLETE_CRUD_DATA);
        }
      }

      // バリデーションチェックでエラーの際は検索しない
      if (!this.validateCheckForSearch(true)) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // チェックボックスを解除
      this.selected = [];
      // 発注検索のアコーディオンのみ表示する
      this.panel = [0];

      // 画面上の検索条件でWHERE句を作成
      let whereOptions = await this.setWhereOptionsForSearchOrder();
      if (!(await this.getOrderList(whereOptions))) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    },
    /**
     * 検索条件用のWHERE句生成処理（発注検索）
     */
    async setWhereOptionsForSearchOrder() {
      // メソッド名を定義
      const method_name="setWhereOptionsForSearchOrder"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let whereOptions = "";

      // 検索条件 作成日付
      // 作成日付を昇順ソートする
      this.scOrderDates.sort((a, b) => new Date(a) - new Date(b));
      if (this.scOrderDateRange !== null && this.scOrderDateRange !== "") {
        if (this.scOrderDates.length > 1) {
          whereOptions += ` AND CHUMON_DATE BETWEEN '${this.scOrderDates[0]}' AND '${this.scOrderDates[1]}'`;
        } else {
          whereOptions += ` AND CHUMON_DATE = '${this.scOrderDates[0]}'`;
        }
      }

      // 検索条件 注文番号
      if (this.scOrderNoStart !== null && this.scOrderNoStart !== ""
          || this.scOrderNoEnd !== null && this.scOrderNoEnd !== "") {
        if (this.scOrderNoStart !== null && this.scOrderNoEnd !== null) {
          whereOptions += ` AND CHUMON_NO BETWEEN ${this.scOrderNoStart} AND ${this.scOrderNoEnd}`;
        } else if (this.scOrderNoStart !== null) {
          whereOptions += ` AND CHUMON_NO = ${this.scOrderNoStart}`;
        } else {
          whereOptions += ` AND CHUMON_NO = ${this.scOrderNoEnd}`;
        }
      }

      // 検索条件 仕入先名
      whereOptions +=
        this.scSupplierName == null || this.scSupplierName == ""
          ? ""
          : ` AND SHIIRESAKI_NAME LIKE '%${this.scSupplierName}%'`;

      // 検索条件 仕入先コード
      whereOptions +=
        this.scSupplierCode == null || this.scSupplierCode == ""
          ? ""
          : ` AND SHIIRESAKI_CD = ${this.scSupplierCode}`;

      // 検索条件 仕入先伝票番号
      whereOptions +=
        this.scPurchaseSlipNo == null || this.scPurchaseSlipNo == ""
          ? ""
          : ` AND SHIIRESAKI_DENPYO_NO = '${this.scPurchaseSlipNo}'`;

      // 検索条件 得意先名
      whereOptions +=
        this.scCssClientName == null || this.scCssClientName == ""
          ? ""
          : ` AND CSS_TOKUISAKI_NAME LIKE '%${this.scCssClientName}%'`;

      // 検索条件 得意先コード
      if (this.scCssClientCodeStart !== null && this.scCssClientCodeStart !== ""
          || this.scCssClientCodeEnd !== null && this.scCssClientCodeEnd !== "") {
        if (this.scCssClientCodeStart !== null && this.scCssClientCodeEnd !== null) {
          whereOptions += ` AND CSS_TOKUISAKI_CD BETWEEN ${this.scCssClientCodeStart} AND ${this.scCssClientCodeEnd}`;
        } else if (this.scCssClientCodeStart !== null) {
          whereOptions += ` AND CSS_TOKUISAKI_CD = ${this.scCssClientCodeStart}`;
        } else {
          whereOptions += ` AND CSS_TOKUISAKI_CD = ${this.scCssClientCodeEnd}`;
        }
      }

      // 検索条件 着日
      if(this.inDateUndecidedCheckBox) {
        whereOptions += ` AND CHAKUBI_DATE IS NULL`;
      } else {
        // 着日を昇順ソートする
        this.scInDates.sort((a, b) => new Date(a) - new Date(b));
        if (this.scInDateRange !== null && this.scInDateRange !== "") {
          if (this.scInDates.length > 1) {
            whereOptions += ` AND CHAKUBI_DATE BETWEEN '${this.scInDates[0]}' AND '${this.scInDates[1]}'`;
          } else {
            whereOptions += ` AND CHAKUBI_DATE = '${this.scInDates[0]}'`;
          }
        }
      }

      // 検索条件 売上担当者コード
      if (this.scCssSalesManagerCodeStart !== null && this.scCssSalesManagerCodeStart !== ""
          || this.scCssSalesManagerCodeEnd !== null && this.scCssSalesManagerCodeEnd !== "") {
        if (this.scCssSalesManagerCodeStart !== null && this.scCssSalesManagerCodeEnd !== null) {
          let startCd = null;
          let endCd = null;
          // 開始よりも終了の方がコード値が大きい場合、検索条件用に逆転
          if (this.scCssSalesManagerCodeStart > this.scCssSalesManagerCodeEnd) {
            startCd = this.scCssSalesManagerCodeEnd;
            endCd = this.scCssSalesManagerCodeStart;
          } else {
            startCd = this.scCssSalesManagerCodeStart;
            endCd = this.scCssSalesManagerCodeEnd;
          }
          whereOptions += ` AND CSS_URIAGE_TANTOSHA_CD BETWEEN ${startCd} AND ${endCd}`;
        } else if (this.scCssSalesManagerCodeStart !== null) {
          whereOptions += ` AND CSS_URIAGE_TANTOSHA_CD = ${this.scCssSalesManagerCodeStart}`;
        } else {
          whereOptions += ` AND CSS_URIAGE_TANTOSHA_CD = ${this.scCssSalesManagerCodeEnd}`;
        }
      }

      // 検索条件 仮伝出力ナンバー
      whereOptions +=
        this.scTempSlipNo == null || this.scTempSlipNo == ""
          ? ""
          : ` AND URIDEN_NO = ${this.scTempSlipNo}`;

      // 検索条件 現場名
      whereOptions +=
        this.scSpotName == null || this.scSpotName == ""
          ? ""
          : ` AND GENBA_NAME LIKE '%${this.scSpotName}%'`;

      // 検索条件 経由分伝票番号
      whereOptions +=
        this.scToshinSplitNo == null || this.scToshinSplitNo == ""
          ? ""
          : ` AND TOSHIN_DENPYO_NO = ${this.scToshinSplitNo}`;

      // 検索条件 売上CSV
      switch (Number(this.scSalesCsvFlgRadioGroup)) {
        case 0: // 全部
          break;
        case 1: // チェック
          whereOptions += ` AND URIAGE_CSV_FLG = 1`;
          break;
        case 2: // なし
          whereOptions += ` AND URIAGE_CSV_FLG = 0`;
          break;
        default:
          break;
      }

      // 検索条件 仕入CSV
      switch (Number(this.scPurchaseCsvFlgRadioGroup)) {
        case 0: // 全部
          break;
        case 1: // チェック
          whereOptions += ` AND SHIIRE_CSV_FLG = 1`;
          break;
        case 2: // なし
          whereOptions += ` AND SHIIRE_CSV_FLG = 0`;
          break;
        default:
          break;
      }

      // 検索条件 トシン経由
      switch (Number(this.scToshinSectionRadioGroup)) {
        case 0: // 全部
          break;
        case 1: // チェック
          whereOptions += ` AND TOSHIN_KEIYU_KBN = 1`;
          break;
        case 2: // なし
          whereOptions += ` AND TOSHIN_KEIYU_KBN <> 1`;
          break;
        default:
          break;
      }

      // 検索条件 トシン入力
      switch (Number(this.scToshinInputRadioGroup)) {
        case 0: // 全部
          break;
        case 1: // チェック
          whereOptions += ` AND TOSHIN_DENPYO_NO IS NOT NULL AND TOSHIN_DENPYO_DATE IS NOT NULL`;
          break;
        case 2: // なし
          whereOptions += ` AND TOSHIN_DENPYO_NO IS NULL AND TOSHIN_DENPYO_DATE IS NULL`;
          break;
        default:
          break;
      }

      // 検索条件 キャンセル
      switch (Number(this.scCancelRadioGroup)) {
        case 0: // 全部
          break;
        case 1: // チェック
          whereOptions += ` AND CANCEL_FLG = 1`;
          break;
        case 2: // なし
          whereOptions += ` AND CANCEL_FLG = 0`;
          break;
        default:
          break;
      }

      // 検索条件 項目チェック（入荷、仮伝、本伝）
      whereOptions += !this.scEnableCheckBox
        ? ""
        : ` AND NYUKA_FLG = ${this.scInFlgCheckBox} AND KARIDEN_FLG = ${this.scTempSlipFlgCheckBox} AND HONDEN_FLG = ${this.slipFlgCheckBox}`;

      // 検索条件(共通) 所属部門の明細、発注データID、明細データでソート
      whereOptions += ` AND BUMON_CD = ${this.loginUserInfo.BUMON_CD} ORDER BY HACCHU_DATA_ID DESC, MEISAI_NO ASC LIMIT ${RESULT_ROWS_COUNT_LIMIT}`;

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return whereOptions;
    },
    /**
     * 発注データの取得処理（発注明細データ取得VIEW）
     */
    async getOrderList(whereOptions) {
      // メソッド名を定義
      const method_name="getOrderList"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 検索条件
      let condition = {
        where_options: whereOptions,
      };
      console.log("condition = " + JSON.stringify(condition));

      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listV_HACCHU_DATA_WITH_MEISAI, condition)
        );
        // 結果判定
        if (result.data.listV_HACCHU_DATA_WITH_MEISAI) {
          // データを取得できた場合
          // 取得結果を格納
          this.searchItemList = [];
          let resultData = result.data.listV_HACCHU_DATA_WITH_MEISAI;
          console.log({resultData});
          if(resultData.length === RESULT_ROWS_COUNT_LIMIT) {
            alert(ALERT_ROWS_COUNT_LIMIT);
          }
          for (const data of resultData) {
            // 利益率計算
            let profitRatio;
            if(!data.OKYAKUSAMA_OROSHIKAKAKU_GK) {
              profitRatio = 0;
            } else {
              if (!data.SAISHU_SHIIRE_KAKAKU_GK) {
                profitRatio = 100; 
              } else {
                profitRatio = Math.round((1-(data.SAISHU_SHIIRE_KAKAKU_GK / data.OKYAKUSAMA_OROSHIKAKAKU_GK)) * 100 * 100) / 100;
              }
            }

            this.searchItemList.push({
              orderDataId: data.HACCHU_DATA_ID,
              departmentCode: data.BUMON_CD,
              panasonicSection: data.PANASONIC_KBN,
              orderNo: data.CHUMON_NO,
              orderDate: data.CHUMON_DATE,
              supplierCode: data.SHIIRESAKI_CD,
              supplierName: data.SHIIRESAKI_NAME,
              commissionSection: data.TESURYO_KBN,
              toshinSection: Boolean(data.TOSHIN_KEIYU_KBN),
              toshinClientNo: data.TOSHIN_OKYAKUSAMA_CD,
              toshinOrderNo: data.TOSHIN_JUHACCHU_NO,
              toshinManagerOrderNo: data.TOSHIN_HACCHUSHA_CHUMON_NO,
              managerCode: data.TANTOSHA_CD,
              managerName: data.TANTOSHA_NAME,
              description: data.TEKIYO,
              orderBranchNo: data.MEISAI_NO,
              productCode: data.SHOHIN_CD,
              productNo: data.HINBAN_NO,
              productName: data.SHOHIN_NAME,
              amount: data.SURYO_SU,
              net: data.NET_GK,
              lastCost: data.SAISHU_SHIIRE_KAKAKU_GK,
              cssClientName: data.CSS_TOKUISAKI_NAME,
              inDate: data.CHAKUBI_DATE,
              inDateUndecidedFlg: data.CHAKUBI_DATE ? false : true,
              detailsDescription: data.BIKO,
              tempSlipNo: data.URIDEN_NO,
              returnSlipNo: data.HENDEN_NO,
              wholesaleCost: data.OROSHI_KAKAKU_GENKA_GK,
              inFlg: Boolean(data.NYUKA_FLG),
              tempSlipFlg: Boolean(data.KARIDEN_FLG),
              slipFlg: Boolean(data.HONDEN_FLG),
              unit: data.TANI_NAME,
              listPrice: data.TEKA_GK,
              cssClientCode: data.CSS_TOKUISAKI_CD,
              spotName: data.GENBA_NAME,
              tempSlipPrintFlg: Boolean(data.KARIDEN_INSATSU_FLG),
              tempSlipPrintDate: data.KARIDEN_DATE,
              productJanCode: data.JAN_CD,
              arrivalPurchasePrice: data.NYUKAJI_SHIIRE_KAKAKU_GK,
              purchaseSlipNo: data.SHIIRESAKI_DENPYO_NO,
              rebateFlg: Boolean(data.REBATE_FLG),
              cssSalesManagerCode: data.CSS_URIAGE_TANTOSHA_CD,
              salesSlipDate: data.URIAGE_DENPYO_DATE,
              nextBillingFlg: Boolean(data.JIKAI_SEIKYU_FLG),
              customerWholesaleCost: data.OKYAKUSAMA_OROSHIKAKAKU_GK,
              calcMethod: data.KEISAN_HOSHIKI_NAME,
              multiplationRate: data.KAKERITSU_SU,
              toshinSplitDate: data.TOSHIN_DENPYO_DATE,
              toshinSplitNo: data.TOSHIN_DENPYO_NO,
              toshinSplitPrintFlg: Boolean(data.TOSHIN_DENPYO_INSATSU_FLG),
              toshinSplitPrintDate: data.TOSHIN_DENPYO_INSATSU_DATE,
              salesCsvFlg: Boolean(data.URIAGE_CSV_FLG),
              salesCsvOutputDate: data.URIAGE_CSV_OUTPUT_DATE,
              purchaseCsvFlg: Boolean(data.SHIIRE_CSV_FLG),
              purchaseCsvOutputDate: data.SHIIRE_CSV_OUTPUT_DATE,
              waitingPurchaseFlg: Boolean(data.SHIIRE_MACHI_CHECK_FLG),
              arrivalDescription: data.CHAKUBI_BIKO,
              costChangeFlg: Boolean(data.GENKA_IRAI_FLG),
              cancelFlg: Boolean(data.CANCEL_FLG),
              sentEmailFlg: Boolean(data.EMAIL_SENT_FLG),
              returnStatus: data.HENPIN_STATUS_KBN,
              splitSection: data.DENPYO_KBN,
              pullUpFlg: Boolean(data.HIKIAGE_FLG),
              returnRequestFlg: Boolean(data.IRAI_FLG),
              returnProductFlg: Boolean(data.HENKYAKU_FLG),
              purchaseAkaCsvOutputFlg: Boolean(data.SHIIRE_AKA_FLG),
              purchaseAkaCsvOutputDate: data.SHIIRE_AKA_DATE,
              salesCsvAkaCsvOutputFlg: Boolean(data.URIAGE_AKA_FLG),
              salesAkaCsvOutputDate: data.URIAGE_AKA_DATE,
              cssSupplierCode: data.CSS_SHIIRESAKI_CD,
              supplierRebateSection: data.SHIIRESAKI_REBATE_KBN,
              inDateSection: data.CHAKUBI_KBN,
              purchaseOrderSection: data.HACCHUSHO_KBN,
              // 以下、検索画面で使用するデータを格納
              tableKey: String(data.HACCHU_DATA_ID) + String(data.MEISAI_NO),
              isUpdatedItem: false,
              // データテーブル内ダイアログでのカレンダー入力
              inDateSelectMenu: false,
              tempSlipDateMenu: false,
              salesSlipDateMenu: false,
              toshinSplitDateMenu: false,
              // 利益率計算
              profitRatio: profitRatio,
            });
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query:"listV_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // 業務ログ出力
          console.log("getOrderList：error 1");
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query:"listV_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        // 業務ログ出力
        console.log(
          "getOrderList：error 2 : " + JSON.stringify(error)
        );
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listV_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // 取得したデータを加工する。
      for (const data of this.searchItemList) {
        // 伝票区分から返品値引フラグを設定する。
        if (data.splitSection == 2) {
          data.returnFlg = true;
          data.discountFlg = false;
        } else if (data.splitSection == 3) {
          data.returnFlg = false;
          data.discountFlg = true;
        } else {
          data.returnFlg = false;
          data.discountFlg = false;
        }
      }

      // 未保存チェック用配列を更新
      this.saveOriginalParams();

      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 発注データの取得処理（発注明細データ取得VIEW）
     * リストを返却
     */
    async getOrders(whereOptions) {
      // メソッド名を定義
      const method_name="getOrders"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 検索条件
      let condition = {
        where_options: whereOptions,
      };
      console.log("condition = " + JSON.stringify(condition));

      let orders = [];

      // データ取得
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listV_HACCHU_DATA_WITH_MEISAI, condition)
        );
        // 結果判定
        if (result.data.listV_HACCHU_DATA_WITH_MEISAI) {
          // データを取得できた場合
          // 取得結果を格納
          let resultData = result.data.listV_HACCHU_DATA_WITH_MEISAI;
          for (const data of resultData) {
            orders.push({
              orderDataId: data.HACCHU_DATA_ID,
              departmentCode: data.BUMON_CD,
              panasonicSection: data.PANASONIC_KBN,
              orderNo: data.CHUMON_NO,
              orderDate: data.CHUMON_DATE,
              supplierCode: data.SHIIRESAKI_CD,
              supplierName: data.SHIIRESAKI_NAME,
              commissionSection: data.TESURYO_KBN,
              toshinSection: Boolean(data.TOSHIN_KEIYU_KBN),
              toshinClientNo: data.TOSHIN_OKYAKUSAMA_CD,
              toshinOrderNo: data.TOSHIN_JUHACCHU_NO,
              toshinManagerOrderNo: data.TOSHIN_HACCHUSHA_CHUMON_NO,
              managerCode: data.TANTOSHA_CD,
              managerName: data.TANTOSHA_NAME,
              description: data.TEKIYO,
              orderBranchNo: data.MEISAI_NO,
              productCode: data.SHOHIN_CD,
              productNo: data.HINBAN_NO,
              productName: data.SHOHIN_NAME,
              amount: data.SURYO_SU,
              net: data.NET_GK,
              lastCost: data.SAISHU_SHIIRE_KAKAKU_GK,
              cssClientName: data.CSS_TOKUISAKI_NAME,
              inDate: data.CHAKUBI_DATE,
              detailsDescription: data.BIKO,
              tempSlipNo: data.URIDEN_NO,
              returnSlipNo: data.HENDEN_NO,
              wholesaleCost: data.OROSHI_KAKAKU_GENKA_GK,
              inFlg: Boolean(data.NYUKA_FLG),
              tempSlipFlg: Boolean(data.KARIDEN_FLG),
              slipFlg: Boolean(data.HONDEN_FLG),
              unit: data.TANI_NAME,
              listPrice: data.TEKA_GK,
              cssClientCode: data.CSS_TOKUISAKI_CD,
              spotName: data.GENBA_NAME,
              tempSlipPrintFlg: Boolean(data.KARIDEN_INSATSU_FLG),
              tempSlipPrintDate: data.KARIDEN_DATE,
              productJanCode: data.JAN_CD,
              arrivalPurchasePrice: data.NYUKAJI_SHIIRE_KAKAKU_GK,
              purchaseSlipNo: data.SHIIRESAKI_DENPYO_NO,
              rebateFlg: Boolean(data.REBATE_FLG),
              cssSalesManagerCode: data.CSS_URIAGE_TANTOSHA_CD,
              salesSlipDate: data.URIAGE_DENPYO_DATE,
              nextBillingFlg: Boolean(data.JIKAI_SEIKYU_FLG),
              customerWholesaleCost: data.OKYAKUSAMA_OROSHIKAKAKU_GK,
              calcMethod: data.KEISAN_HOSHIKI_NAME,
              multiplationRate: data.KAKERITSU_SU,
              toshinSplitDate: data.TOSHIN_DENPYO_DATE,
              toshinSplitNo: data.TOSHIN_DENPYO_NO,
              toshinSplitPrintFlg: Boolean(data.TOSHIN_DENPYO_INSATSU_FLG),
              toshinSplitPrintDate: data.TOSHIN_DENPYO_INSATSU_DATE,
              salesCsvFlg: Boolean(data.URIAGE_CSV_FLG),
              salesCsvOutputDate: data.URIAGE_CSV_OUTPUT_DATE,
              purchaseCsvFlg: Boolean(data.SHIIRE_CSV_FLG),
              purchaseCsvOutputDate: data.SHIIRE_CSV_OUTPUT_DATE,
              waitingPurchaseFlg: Boolean(data.SHIIRE_MACHI_CHECK_FLG),
              arrivalDescription: data.CHAKUBI_BIKO,
              costChangeFlg: Boolean(data.GENKA_IRAI_FLG),
              cancelFlg: Boolean(data.CANCEL_FLG),
              sentEmailFlg: Boolean(data.EMAIL_SENT_FLG),
              returnStatus: data.HENPIN_STATUS_KBN,
              splitSection: data.DENPYO_KBN,
              pullUpFlg: Boolean(data.HIKIAGE_FLG),
              returnRequestFlg: Boolean(data.IRAI_FLG),
              returnProductFlg: Boolean(data.HENKYAKU_FLG),
              purchaseAkaCsvOutputFlg: Boolean(data.SHIIRE_AKA_FLG),
              purchaseAkaCsvOutputDate: data.SHIIRE_AKA_DATE,
              salesCsvAkaCsvOutputFlg: Boolean(data.URIAGE_AKA_FLG),
              salesAkaCsvOutputDate: data.URIAGE_AKA_DATE,
              tableKey: String(data.HACCHU_DATA_ID) + String(data.MEISAI_NO),
              isUpdatedItem: false,
              // データテーブル内ダイアログでのカレンダー入力
              inDateSelectMenu: false,
              tempSlipDateMenu: false,
              salesSlipDateMenu: false,
              toshinSplitDateMenu: false,
            });
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query:"listV_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // 業務ログ出力
          console.log("getOrders：error 1");
          //ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query:"listV_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        // 業務ログ出力
        console.log(
          "getOrders：error 2 : " + JSON.stringify(error)
        );
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listV_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // 取得したデータを加工する。
      for (const data of orders) {
        // 伝票区分から返品値引フラグを設定する。
        if (data.splitSection == 2) {
          data.returnFlg = true;
          data.discountFlg = false;
        } else if (data.splitSection == 3) {
          data.returnFlg = false;
          data.discountFlg = true;
        } else {
          data.returnFlg = false;
          data.discountFlg = false;
        }
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return orders;
    },
    /**
     * 発注明細Viewデータ更新処理
     */
    async updateTable() {
      // メソッド名を定義
      const method_name="updateTable"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      console.log("updateTable [in]");

      let sqlList = [];
      let toshinManagerOrderNo = null;
      let managerName = null;
      let description = null;
      let supplierName = null;
      let toshinOrderNo = null;
      let managerLoginUserCode = null;
      let userName = null;
      for (const tableData of this.searchItemList) {
        // 変更があったもののみ更新
        if (!tableData.isUpdatedItem) {
          console.log(
            "tableData.isUpdatedItem = FALSE [" +
              tableData.orderDataId +
              "]"
          );
          continue;
        }
        console.log(
          "tableData.isUpdatedItem = TRUE [" +
            tableData.orderDataId +
            "] " +
            JSON.stringify(tableData)
        );

        toshinManagerOrderNo = !tableData.toshinManagerOrderNo ? null : `'${await this.deleteTabStr(tableData.toshinManagerOrderNo)}'`;
        description = !tableData.description ? null : `'${await this.deleteTabStr(tableData.description)}'`;
        managerLoginUserCode = !tableData.managerLoginUserCode? null: `'${await this.deleteTabStr(tableData.managerLoginUserCode)}'`;
        managerName = !tableData.managerName ? null : `'${await this.deleteTabStr(tableData.managerName)}'`;
        supplierName = !tableData.supplierName ? null : `'${await this.deleteTabStr(tableData.supplierName)}'`;
        toshinOrderNo = !tableData.toshinOrderNo ? null : `'${tableData.toshinOrderNo}'`;
        managerLoginUserCode = !tableData.managerLoginUserCode ? null : `'${await this.deleteTabStr(tableData.managerLoginUserCode)}'`;
        userName = !this.loginUserInfo.LOGIN_USER_CD ? null : `'${this.loginUserInfo.LOGIN_USER_CD}'`;
        // 発注データ用SQLを追加
        sqlList.push(
          `UPDATE T_HACCHU_DATA SET` +
            ` SHIIRESAKI_CD = ${tableData.supplierCode},` +
            ` SHIIRESAKI_NAME = ${supplierName},` +
            ` TESURYO_KBN = ${tableData.commissionSection},` +
            ` TOSHIN_KEIYU_KBN = ${tableData.toshinSection},` +
            ` TOSHIN_OKYAKUSAMA_CD = ${tableData.toshinClientNo},` +
            ` TOSHIN_JUHACCHU_NO = ${toshinOrderNo},` +
            ` TOSHIN_HACCHUSHA_CHUMON_NO = ${toshinManagerOrderNo},` +
            ` LOGIN_USER_CD = ${managerLoginUserCode},` +
            ` TANTOSHA_CD = ${tableData.managerCode},` +
            ` TANTOSHA_NAME = ${managerName},` +
            ` TEKIYO = ${description},` +
            ` UPDATE_DATE = CURDATE(),` +
            ` UPDATE_USER_NAME = ${userName}` +
            ` WHERE HACCHU_DATA_ID = ${tableData.orderDataId};`
        );
      let detailsDescription = "";
      let spotName = "";
      let inDate = "";
      let unit = "";
      let cssClientName = "";
      let calcMethod = "";
      let purchaseSlipNo = "";
      let productNo = null;
      let productJanCode = null;
      let productName = null;
      let tempSlipPrintDate = null;
      let toshinSplitNo = null;
      let toshinSplitDate = null;
      let toshinSplitPrintDate = null;
      let purchaseCsvOutputDate = null;
      let purchaseAkaCsvOutputDate = null;
      let salesSlipDate = null;
      let salesCsvOutputDate = null;
      let salesAkaCsvOutputDate = null;
      let arrivalDescription = null;
        // 発注明細データ用SQLを追加
        detailsDescription = !tableData.detailsDescription ? null : `'${await this.deleteTabStr(tableData.detailsDescription)}'`;
        spotName = !tableData.spotName ? null : `'${await this.deleteTabStr(tableData.spotName)}'`;
        inDate = !tableData.inDate ? null : `'${tableData.inDate}'`;
        unit = !tableData.unit ? null : `'${await this.deleteTabStr(tableData.unit)}'`;
        cssClientName = !tableData.cssClientName ? null : `'${await this.deleteTabStr(tableData.cssClientName)}'`;
        calcMethod = !tableData.calcMethod ? null : `'${await this.deleteTabStr(tableData.calcMethod)}'`;
        purchaseSlipNo = !tableData.purchaseSlipNo ? null : `'${await this.deleteTabStr(tableData.purchaseSlipNo)}'`;
        productNo = !tableData.productNo ? null : `'${await this.deleteTabStr(tableData.productNo)}'`;
        productJanCode = !tableData.productJanCode ? null : `'${await this.deleteTabStr(tableData.productJanCode)}'`;
        productName = !tableData.productName ? null : `'${await this.deleteTabStr(tableData.productName)}'`;
        tempSlipPrintDate = !tableData.tempSlipPrintDate ? null : `'${await this.deleteTabStr(tableData.tempSlipPrintDate)}'`;
        toshinSplitNo = !tableData.toshinSplitNo ? null : `'${await this.deleteTabStr(tableData.toshinSplitNo)}'`;
        toshinSplitDate = !tableData.toshinSplitDate ? null : `'${tableData.toshinSplitDate}'`;
        toshinSplitPrintDate = !tableData.toshinSplitPrintDate ? null : `'${tableData.toshinSplitPrintDate}'`;
        purchaseCsvOutputDate = !tableData.purchaseCsvOutputDate ? null : `'${tableData.purchaseCsvOutputDate}'`;
        purchaseAkaCsvOutputDate = !tableData.purchaseAkaCsvOutputDate ? null : `'${tableData.purchaseAkaCsvOutputDate}'`;
        salesSlipDate = !tableData.salesSlipDate ? null : `'${tableData.salesSlipDate}'`;
        salesCsvOutputDate = !tableData.salesCsvOutputDate ? null : `'${tableData.salesCsvOutputDate}'`;
        salesAkaCsvOutputDate = !tableData.salesAkaCsvOutputDate ? null : `'${tableData.salesAkaCsvOutputDate}'`;
        arrivalDescription = !tableData.arrivalDescription ? null : `'${await this.deleteTabStr(tableData.arrivalDescription)}'`;
        sqlList.push(
          `UPDATE T_HACCHU_MEISAI_DATA SET` +
            ` SHOHIN_CD = ${tableData.productCode},` +
            ` HINBAN_NO = ${productNo},` +
            ` SHOHIN_NAME = ${productName},` +
            ` SURYO_SU = ${tableData.amount},` +
            ` NET_GK = ${tableData.net},` +
            ` SAISHU_SHIIRE_KAKAKU_GK = ${tableData.lastCost},` +
            ` CSS_TOKUISAKI_NAME = ${cssClientName},` +
            ` CHAKUBI_DATE = ${inDate},` +
            ` BIKO = ${detailsDescription},` +
            ` URIDEN_NO = ${tableData.tempSlipNo},` +
            ` HENDEN_NO = ${tableData.returnSlipNo},` +
            ` OROSHI_KAKAKU_GENKA_GK = ${tableData.wholesaleCost},` +
            ` NYUKA_FLG = ${Number(tableData.inFlg)},` +
            ` KARIDEN_FLG = ${Number(tableData.tempSlipFlg)},` +
            ` HONDEN_FLG = ${Number(tableData.slipFlg)},` +
            ` TANI_NAME = ${unit},` +
            ` TEKA_GK = ${tableData.listPrice},` +
            ` CSS_TOKUISAKI_CD = ${tableData.cssClientCode},` +
            ` GENBA_NAME = ${spotName},` +
            ` KARIDEN_INSATSU_FLG = ${Number(tableData.tempSlipPrintFlg)},` +
            ` KARIDEN_DATE = ${tempSlipPrintDate},` +
            ` JAN_CD = ${productJanCode},` +
            ` NYUKAJI_SHIIRE_KAKAKU_GK = ${tableData.arrivalPurchasePrice},` +
            ` SHIIRESAKI_DENPYO_NO = ${purchaseSlipNo},` +
            ` REBATE_FLG = ${Number(tableData.rebateFlg)},` +
            ` CSS_URIAGE_TANTOSHA_CD = ${tableData.cssSalesManagerCode},` +
            ` URIAGE_DENPYO_DATE = ${salesSlipDate},` +
            ` JIKAI_SEIKYU_FLG = ${tableData.nextBillingFlg},` +
            ` OKYAKUSAMA_OROSHIKAKAKU_GK = ${tableData.customerWholesaleCost},` +
            ` KEISAN_HOSHIKI_NAME = ${calcMethod},` +
            ` KAKERITSU_SU = ${tableData.multiplationRate},` +
            ` TOSHIN_DENPYO_DATE = ${toshinSplitDate},` +
            ` TOSHIN_DENPYO_NO = ${toshinSplitNo},` +
            ` TOSHIN_DENPYO_INSATSU_FLG = ${Number(tableData.toshinSplitPrintFlg)},` +
            ` TOSHIN_DENPYO_INSATSU_DATE = ${toshinSplitPrintDate},` +
            ` URIAGE_CSV_FLG = ${Number(tableData.salesCsvFlg)},` +
            ` URIAGE_CSV_OUTPUT_DATE = ${salesCsvOutputDate},` +
            ` SHIIRE_CSV_FLG = ${Number(tableData.purchaseCsvFlg)},` +
            ` SHIIRE_CSV_OUTPUT_DATE = ${purchaseCsvOutputDate},` +
            ` SHIIRE_MACHI_CHECK_FLG = ${Number(tableData.waitingPurchaseFlg)},` +
            ` CHAKUBI_BIKO = ${arrivalDescription},` +
            ` GENKA_IRAI_FLG = ${Number(tableData.costChangeFlg)},` +
            ` CANCEL_FLG = ${Number(tableData.cancelFlg)},` +
            ` EMAIL_SENT_FLG = ${Number(tableData.sentEmailFlg)},` +
            ` HENPIN_STATUS_KBN = ${tableData.returnStatus},` +
            ` DENPYO_KBN = ${tableData.splitSection},` +
            ` HIKIAGE_FLG = ${Number(tableData.pullUpFlg)},` +
            ` IRAI_FLG = ${Number(tableData.returnRequestFlg)},` +
            ` HENKYAKU_FLG = ${Number(tableData.returnProductFlg)},` +
            ` SHIIRE_AKA_FLG = ${Number(tableData.purchaseAkaCsvOutputFlg)},` +
            ` SHIIRE_AKA_DATE = ${purchaseAkaCsvOutputDate},` +
            ` URIAGE_AKA_FLG = ${Number(tableData.salesCsvAkaCsvOutputFlg)},` +
            ` URIAGE_AKA_DATE = ${salesAkaCsvOutputDate},` +
            ` UPDATE_DATE = CURDATE(),` +
            ` UPDATE_USER_NAME = ${userName}` +
            ` WHERE HACCHU_DATA_ID = ${tableData.orderDataId}` +
            ` AND MEISAI_NO = ${tableData.orderBranchNo};`
        );
      }
      console.log(
        "updateTable：before executeTransactSql = " +
          JSON.stringify(sqlList)
      );
      // トランザクション関数の実行
      if (!(await this.executeTransactSql(sqlList))) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      // 未保存チェック用配列を更新
      this.saveOriginalParams();

      // バリデーションエラーをリセット
      this.resetValidate();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * トランザクション関数の実行処理
     */
    async executeTransactSql(sqlList) {
      // メソッド名を定義
      const method_name="executeTransactSql"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let condition = { SQLs: sqlList };
      console.log(
        "executeTransactSql：condition = " +
          JSON.stringify(condition)
      );
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeTransactSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            // console.info({responseBody});
            // // レスポンスメッセージ
            // let message = responseBody.message;
            // console.error({message});
            // // エラー内容
            // const errorMessage = responseBody.error;
            // console.error({errorMessage});
            console.log(
              "executeTransactSql：error : " + JSON.stringify(result)
            );
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              for (const rows of responseBody.data) {
                console.dir(rows, { depth: null });
              }
            }
            console.log("executeTransactSql：SUCCESS ");
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          console.log("executeTransactSql : " + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        console.log("executeTransactSql : 異常終了 = " + JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 販売管理システム用関数の実行処理
     */
    async executeHanbaikanriSql(sqlList) {
      // メソッド名を定義
      const method_name="executeHanbaikanriSql"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let condition = { SQLs: sqlList };
      console.log(
        "executeHanbaikanriSql：condition = " + JSON.stringify(condition)
      );

      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeHanbaikanriSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(
            result.data.executeHanbaikanriSql.body
          );

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeHanbaikanriSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            console.log("executeHanbaikanriSql：error");
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return null;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              for (const rows of responseBody.data) {
                console.dir(rows, { depth: null });
              }
            }
            console.log("executeHanbaikanriSql：SUCCESS ");
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return responseBody.data;
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          console.log("executeHanbaikanriSql : " + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return null;
        }
      } catch (error) {
        console.log(
          "executeHanbaikanriSql : 異常終了 = " + JSON.stringify(error)
        );
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return null;
      }
    },
    /**
     * 未保存チェック用比較データの更新(発注明細データVIEW)
     */
    saveOriginalParams() {
      // メソッド名を定義
      const method_name="saveOriginalParams"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 行ごとの更新フラグをすべてFALSEに戻す
      for (const data of this.searchItemList) {
        data.isUpdatedItem = false;
      }
      // 保存時の入力データを未保存チェック時の比較データとして更新
      this.originalParamsDetails = JSON.parse(JSON.stringify(this.searchItemList));
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 未保存チェック
     */
    isSavedData() {
      // メソッド名を定義
      const method_name="isSavedData"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let listLength = this.searchItemList.length;
      if (this.originalParamsDetails.length > this.searchItemList.length) {
        listLength = this.originalParamsDetails.length;
      }
      for (var i = 0; i <= listLength; i++) {
        if (
          JSON.stringify(this.originalParamsDetails[i]) !==
          JSON.stringify(this.searchItemList[i])
        ) {
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return false;
        }
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * バリデーションチェック(登録処理時)
     */
    validateCheck() {
      // メソッド名を定義
      const method_name="validateCheck"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // バリデーションチェックでエラーの際は登録しない
      const form = this.$refs.formDetails;
      if (!form.validate()) {
        // エラー情報を取得
        let result = form.inputs.find((input) => input.hasError);
        console.dir(result);
        for (const header of this.headers) {
          if (header.value == result.id) {
            alert(header.text + "の値が不正です。\n" + result.value);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return false;
          }
        }
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * バリデーションチェック(検索ボタン押下時)
     */
    validateCheckForSearch(isOrder) {
      // メソッド名を定義
      const method_name="validateCheckForSearch"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 発注検索時と売上検索時で対象フォームを分ける
      const form = isOrder
        ? this.$refs.formSearchConditionsOrder
        : this.$refs.formSearchConditionsSales;
      if (!form.validate()) {
        // 最初のエラー箇所へスクロール
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    // EXCEL出力ボタン
    async outputExcel() {
      // メソッド名を定義
      const method_name="outputExcel"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 未保存のデータがある場合はエラー
      if (!this.isSavedData()) {
        alert(ALERT_UNSAVED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      
      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // 画面上の検索条件でWHERE句を作成
      let whereOptions = await this.setWhereOptionsForSearchOrder();
      // 発注データ取得
      const orders = await this.getOrders(whereOptions);

      // console.log(orders);

      if(orders.length === RESULT_ROWS_COUNT_LIMIT) {
        alert(ALERT_ROWS_COUNT_LIMIT);
      }

      // Excel出力関数用パラメータ
      const condition = {
        ExcelKind: `order`,
        ExcelData: JSON.stringify(orders),
      };

      if (orders) {
        // ログ出力(SQL-start)
        addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
        try {
          // AppSync→AuroraServerless(MySQL)でデータ取得
          let result = await API.graphql(
            graphqlOperation(executeExcelFileCreation, condition)
          );
          // レスポンスデータを取得できた際の処理
          // console.log({result});

          /**
           * JSON.parseする際に
           * result.data.executeExcelFileCreation.bodyだと
           * dataがundefinedに何故かなるためこのタイミングで代入
           */
          const resultData = result.data;

          if (result) {
            console.log({result});
            // 関数実行でエラーが発生した場合の処理
            if (result.data.executeExcelFileCreation.statusCode > 200) {
              console.log(
                "executeExcelFileCreation：error : " + JSON.stringify(result)
              );
              // ログ出力(Lamda関数でのSQL実行でステータスエラー)
              addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeExcelFileCreation", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
              alert(ALERT_MESSAGE_COMMON_ERROR);
            } else {
              // 関数が正常に終了した際の処理
              // console.log("executeExcelFileCreation：SUCCESS ");

              const parsedBody = JSON.parse(resultData.executeExcelFileCreation.body);
              console.log({parsedBody});
              
              // S3からファイルを取得
              const downloadingFileName = parsedBody.fileName.split('/')[2];
              console.log({downloadingFileName});
              const storeGetResult = await Storage.get(`excelOutputs/${downloadingFileName}`, {download: true});
              
              // S3から取得したファイルをダウンロード
              const url = URL.createObjectURL(storeGetResult.Body);
              const a = document.createElement('a');
              a.href = url;
              a.download = downloadingFileName || 'download';
              const clickHandler = () => {
                setTimeout(() => {
                  URL.revokeObjectURL(url);
                  a.removeEventListener('click', clickHandler);
                }, 150);
              };
              a.addEventListener('click', clickHandler, false);
              a.click();

              // ExcelファイルをS3から削除
              await Storage.remove(`excelOutputs/${downloadingFileName}`);

              // ローディング画面の終了
              this.$store.commit("setLoading", false);
              // ログ出力(SQL-end_OK)
              addLog('INFO', {message: MSG.INFO["1004"], query: "executeExcelFileCreation", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
              // ログ出力(method-end)
              addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
              // ファイルを返却
              return a;
            }
          } else {
            // レスポンスデータを取得できなかった際の処理
            console.log("executeExcelFileCreation : responseBody = null：" + JSON.stringify(result));
            // ログ出力(Lamda関数でのSQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeExcelFileCreation", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            alert(ALERT_MESSAGE_COMMON_ERROR);
          }
        } catch (error) {
          console.log({error});
          console.log("executeExcelFileCreation : 異常終了 = " + JSON.stringify(error));
          // ログ出力(exception)
          addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeExcelFileCreation", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
          alert(ALERT_MESSAGE_COMMON_ERROR);
        }
      } else {
        //TODO データが無かった場合の処理
      }
      // ローディング画面の終了
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 仕入CSV出力ボタン押下処理
     */
    async onClickPurchaseCsv() {
      // メソッド名を定義
      const method_name="onClickPurchaseCsv"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // データ未保存の場合はCSV出力画面に遷移しない
      if (!this.isSavedData()) {
        alert(ALERT_UNSAVED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 検索明細に1レコードもない場合
      if (this.searchItemList.length < 1) {
        alert(ALERT_SEARCH_ZERO_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // SQL作成用に条件判定
      let sqlList = [];
      let whereInOption_M_SHIIRESAKI = [];
      let supplierCodeList = [];
      let whereInOption_SHIIRE = [];
      let whereInOption_OK_V_EIGYOUDATE = [];
      let whereInOption_SHOHIN = [];
      for (const data of this.searchItemList) {
        // 仕入先伝票番号がnull、仕入CSV出力済みフラグがTRUEの場合
        if (data.purchaseSlipNo == null || data.purchaseCsvFlg) {
          // CSV出力対象から外す
          data.removeFlg = true;
          continue;
        }

        // 着日のnullチェック
        if (data.inDate == null) {
          alert(
            `着日が入っていない明細があります。\n注文番号      ：${data.orderNo}\n明細No       ：${data.orderBranchNo}`
          );
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }

        // 品名のnullチェック
        if (data.productName == null) {
          alert(
            `品名が入っていない明細があります。\n注文番号      ：${data.orderNo}\n明細No       ：${data.orderBranchNo}`
          );
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }

        // 最終仕入価格のnullチェック
        if (data.lastCost == null) {
          alert(
            `最終仕入価格が入っていない明細があります。\n注文番号      ：${data.orderNo}\n明細No       ：${data.orderBranchNo}`
          );
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }

        // NETのnullチェック
        if (data.net == null) {
          alert(
            `NETが入っていない明細があります。\n注文番号      ：${data.orderNo}\n明細No       ：${data.orderBranchNo}`
          );
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }

        // 仕入先マスタからCSS仕入先コードを取得
        whereInOption_M_SHIIRESAKI.push(data.supplierCode);

        // 商品コードの存在チェック
        whereInOption_SHOHIN.push(data.productCode);

        // 営業日の取得
        let dateStr = data.inDate.split(`-`);
        whereInOption_OK_V_EIGYOUDATE.push(
          `${dateStr[0]}${dateStr[1]}${dateStr[2]}`
        );
      }

      if (whereInOption_M_SHIIRESAKI.length == 0) {
        for (const data of this.searchItemList) {
          delete data.removeFlg
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        alert(ALERT_NO_CSV_DATA);
        return;
      }

      // データ取得
      let condition = {
        where_options: `AND SHIIRESAKI_CD IN (${whereInOption_M_SHIIRESAKI.join(
          `, `
        )}) AND BUMON_CD = ${this.loginUserInfo.BUMON_CD}`,
      };

      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listM_SHIIRESAKI, condition)
        );
        // 結果判定
        if (result.data.listM_SHIIRESAKI) {
          // データを取得できた場合
          // 取得結果をリストに追加
          let resultData = result.data.listM_SHIIRESAKI;
          for (const data of resultData) {
            supplierCodeList.push({
              code: data.SHIIRESAKI_CD,
              name: data.SHIIRESAKI_NAME,
              cssCode: data.CSS_SHIIRESAKI_CD,
              inDateSection: data.CHAKUBI_KBN,
            });
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query:"listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query:"listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        alert(ALERT_MESSAGE_COMMON_ERROR);
        console.log(JSON.stringify(error));
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return;
      }

      // CSS仕入先コードのNULLチェック
      for (const listItem of supplierCodeList) {
        if (listItem.cssCode == null) {
          alert(
            `仕入先マスタにCSS仕入先コードが設定されていません。\n仕入先コード：${listItem.code}\n仕入先名：${listItem.name}`
          );
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // CSS仕入先コード
        whereInOption_SHIIRE.push(listItem.cssCode);
      }

      // 仕入先基本情報テーブルから仕入先名を取得
      if (whereInOption_SHIIRE.length !== 0) {
        sqlList.push(
          `SELECT SI_TORCD, SI_TORNM1, SI_SEICD FROM SHIIRE WHERE SI_TORCD IN (${whereInOption_SHIIRE.join(
            `, `
          )})`
        );
      }
      if (whereInOption_SHOHIN.length !== 0) {
        sqlList.push(
          `SELECT SH_SHOCD FROM SHOHIN WHERE SH_SHOCD IN (${whereInOption_SHOHIN.join(
            `, `
          )})`
        );
      }
      if (whereInOption_OK_V_EIGYOUDATE.length !== 0) {
        sqlList.push(
          `SELECT ED_DATE, PREV_DATE FROM OK_V_EIGYOUDATE WHERE ED_DATE IN (${whereInOption_OK_V_EIGYOUDATE.join(
            `, `
          )})`
        );
      }

      let resultSHIIRE = [];
      let resultOK_V_EIGYOUDATE = [];
      let resultSHOHIN = [];

      // SQLを実行する
      let result = await this.executeHanbaikanriSql(sqlList);
      if (!result) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      for (let i = 0; i < result.length; i++) {
        switch (i) {
          case 0: // SELECT … FROM SHIIRE
            resultSHIIRE = result[i];
            // 請求先コードのnullチェック
            for (const requestData of whereInOption_SHIIRE) {
              let data = resultSHIIRE.find(
                (items) => items.SI_TORCD == requestData
              );
              if (!data) {
                alert(
                  `仕入先基本情報テーブルに請求先コードが設定されていません。\nCSS仕入先コード：${requestData}`
                );
                // ローディングを解除
                this.$store.commit("setLoading", false);
                addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
                return;
              }
            }
            break;
          case 1: // SELECT … FROM SHOHIN
            resultSHOHIN = result[i];
            // 商品コードのnullチェック
            for (const requestData of whereInOption_SHOHIN) {
              let data = resultSHOHIN.find(
                (items) => items.SH_SHOCD == requestData
              );
              if (!data) {
                alert(
                  `商品テーブルに商品が登録されていません。\n商品コード：${requestData}`
                );
                // ローディングを解除
                this.$store.commit("setLoading", false);
                // ログ出力(method-end)
                addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
                return;
              }
            }
            break;
          case 2: // SELECT … FROM OK_V_EIGYOUDATE
            resultOK_V_EIGYOUDATE = result[i];
            break;
          default:
            break;
        }
      }

      // 仕入CSVデータテーブルのデータ削除
      sqlList = [];
      sqlList.push(
        `DELETE FROM W_HACCHU_SHIIRE_CSV_DATA WHERE BUMON_CD = ${this.loginUserInfo.BUMON_CD} AND TANTOSHA_CD = ${this.loginUserInfo.TANTOSHA_CD};`
      );

      // 仕入CSVデータ挿入用のデータを生成
      let targetSuppier = null;
      let jitsuDate = null;
      let purchaseUnitPrice = null;
      let cssSupplierName = null;
      let cssSupplierCode = null;
      let purchaseSlipNo = null;
      let productName = null;
      for (const data of this.searchItemList) {
        // CSV出力対象から削除するデータは以降の処理を行わない
        if (data.removeFlg) {
          delete data.removeFlg;
          continue;
        }

        // CSS仕入先コード・CSS仕入先名をセット
        targetSuppier = supplierCodeList.find(
          (items) => items.code === data.supplierCode
        );
        cssSupplierCode = targetSuppier.cssCode;
        cssSupplierName = resultSHIIRE.find(
          (items) => items.SI_TORCD === cssSupplierCode
        ).SI_TORNM1;

        // 着日をセット
        // 仕入先の着日区分が1以外の場合
        if (targetSuppier.inDateSection !== 1) {
          jitsuDate = data.inDate;
          console.log("jitsuDate 1 = " + jitsuDate);
        } else {
          let day = data.inDate.split(`-`);
          console.log("day = " + day.join(``));
          jitsuDate = resultOK_V_EIGYOUDATE.find(
            (items) => items.ED_DATE == day.join(``)
          ).PREV_DATE;
          console.log("jitsuDate 2 = " + jitsuDate);
        }

        // 仕入単価をセット
        // トシン経由区分が1の場合、最終仕入価格、それ以外はNET
        purchaseUnitPrice = data.toshinSection ? data.lastCost : data.net;

        // カンマ区切りを外す
        purchaseUnitPrice = String(purchaseUnitPrice).replace(/,/g, "").trim();
        purchaseUnitPrice = Number(purchaseUnitPrice);
        productName = !data.productName ? null : `'${await this.deleteTabStr(data.productName)}'`;
        purchaseSlipNo = !data.purchaseSlipNo ? null : `'${await this.deleteTabStr(data.purchaseSlipNo)}'`;
        sqlList.push(
          `INSERT INTO W_HACCHU_SHIIRE_CSV_DATA (BUMON_CD, TANTOSHA_CD, HACCHU_DATA_ID, MEISAI_NO, CHUMON_NO, SHOHIN_CD, HINBAN_NO, SHIIRESAKI_CD, CSS_SHIIRESAKI_NAME, CHAKUBI_DATE, JITSU_DATE, SURYO_SU, SHIIRE_TANKA_GK, CSS_SHIIRESAKI_CD, SHIIRESAKI_DENPYO_NO, DENPYO_KBN, CREATE_USER_NAME, CREATE_DATE, UPDATE_USER_NAME, UPDATE_DATE) VALUES (` +
            `${this.loginUserInfo.BUMON_CD},` + // BUMON_CD
            `${this.loginUserInfo.TANTOSHA_CD},` + // TANTOSHA_CD
            `${data.orderDataId},` + // HACCHU_DATA_ID
            `${data.orderBranchNo},` + // MEISAI_NO
            `${data.orderNo},` + // CHUMON_NO
            `${data.productCode},` + // SHOHIN_CD
            `${productName},` + // HINBAN_NO
            `${data.supplierCode},` + // SHIIRESAKI_CD
            `'${cssSupplierName}',` + // CSS_SHIIRESAKI_NAME
            `'${jitsuDate}',` + // CHAKUBI_DATE
            `'${jitsuDate}',` + // JITSU_DATE
            `${data.amount},` + // SURYO_SU
            `${purchaseUnitPrice},` + // SHIIRE_TANKA_GK
            `${cssSupplierCode},` + // CSS_SHIIRESAKI_CD
            `${purchaseSlipNo},` + // SHIIRESAKI_DENPYO_NO
            `${data.splitSection},` + // DENPYO_KBN
            `'${this.loginUserInfo.LOGIN_USER_CD}',` + //CREATE_USER_NAME
            `CURDATE(),` + //CREATE_DATE
            `'${this.loginUserInfo.LOGIN_USER_CD}',` + //UPDATE_USER_NAME
            `CURDATE());` //UPDATE_DATE
        );
      }
      console.log(
        "onClickPurchaseCsv：before executeTransactSql = " +
          JSON.stringify(sqlList)
      );

      // トランザクション関数の実行
      if (sqlList.length > 1) {
        if (!(await this.executeTransactSql(sqlList))) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }

        // CSV出力画面に遷移
        this.$router.push("/csv_print");
      } else {
        for (const data of this.searchItemList) {
          delete data.removeFlg
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        alert(ALERT_NO_CSV_DATA);
        return;
      }

      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    // 納期一覧表出力ボタン押下
    async onClickDeliveryDateList() {
      // メソッド名を定義
      const method_name="onClickDeliveryDateList"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 帳票用得意先リストをリセット
      this.tokuisakiForDeliveryDateList = [];

      // 未保存チェックを実施し、未保存の場合はエラーを表示
      if (!this.isSavedData()) {
        if (confirm(CONFIRM_MESSAGE_UNSAVED_DATA)) {
          // バリデーションチェックでエラーの際は登録しない
          if (!this.validateCheck()) {
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }

          // ローディング画面の開始
          this.$store.commit("setLoading", true);
          // 修正：入力データで更新
          if (!(await this.updateTable())) {
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // エラーダイアログを表示
            alert(ALERT_MESSAGE_COMMON_ERROR);
            // 画面遷移しない
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // 完了ダイアログを表示
          alert(ALERT_COMPLETE_CRUD_DATA);
        }
      }
      // ローディングを開始
      this.$store.commit("setLoading", true);

      // 画面上の検索条件でWHERE句を作成
      let whereOptions = this.setWhereOptionsForDeliveryDateList();
      console.log({whereOptions});
      // データ取得
      try {
        let result = await API.graphql(
          graphqlOperation(listV_HACCHU_DATA_WITH_MEISAI, {where_options: whereOptions})
        );
        // 結果判定
        if (result.data.listV_HACCHU_DATA_WITH_MEISAI) {
          const items = result.data.listV_HACCHU_DATA_WITH_MEISAI;
          console.log({items});
          if(items.length === RESULT_ROWS_COUNT_LIMIT) {
            // 検索結果が多い場合再度絞り込みを要請
            alert(ALERT_TOO_MANY_ROWS_COUNT);
            // ローディングを解除
            this.$store.commit("setLoading", false);
            return;
          }
          if (items && items.length > 0) {
            // データを取得できた場合、摘要欄入力ダイアログ表示
            console.log('dialogDeliveryDateList on');
            console.log('delively date items', items);
            // 得意先を抽出
            let tokuisakiList = []
            for(const item of items) {
              tokuisakiList.push(
                (({ CSS_TOKUISAKI_CD, CSS_TOKUISAKI_NAME })=>({ CSS_TOKUISAKI_CD, CSS_TOKUISAKI_NAME }))(item)
              )
            }
            this.tokuisakiForDeliveryDateList = Array.from(
              new Map(tokuisakiList.map((tokuisaki) => [tokuisaki.CSS_TOKUISAKI_CD, tokuisaki])).values()
            );
            console.log('tokuisakiForDeliveryDateList', this.tokuisakiForDeliveryDateList);
            this.dialogDeliveryDateList = true;
          } else {
            // データがない場合
            // console.log('delivery data not exists');
            this.dataNotExistDialog = true;
          }
          // ローディングを解除
          this.$store.commit("setLoading", false);
        } else {
          // データを取得できなかった場合
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query:"listV_HACCHU_DATA_WITH_MEISAI", where_option:whereOptions}, this.$route.name, method_name, TYPE["0003"],);
          // エラーダイアログを表示
          alert(ALERT_MESSAGE_COMMON_ERROR);
        }
      } catch (error) {
        // Exception発生の場合
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listV_HACCHU_DATA_WITH_MEISAI", where_option:whereOptions}, this.$route.name, method_name, TYPE["0003"], error);
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
      }
    },
    // 納期一覧表出力
    async outputDeliveryDateList() {
      // メソッド名を定義
      const method_name="outputDeliveryDateList"
      // オペレーションタイプを定義
      const operation_type="0003"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 未保存チェックを実施し、未保存の場合はエラーを表示
      if (!this.isSavedData()) {
        if (confirm(CONFIRM_MESSAGE_UNSAVED_DATA)) {
          // バリデーションチェックでエラーの際は登録しない
          if (!this.validateCheck()) {
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }

          // ローディング画面の開始
          this.$store.commit("setLoading", true);
          // 修正：入力データで更新
          if (!(await this.updateTable())) {
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // エラーダイアログを表示
            alert(ALERT_MESSAGE_COMMON_ERROR);
            // 画面遷移しない
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // 完了ダイアログを表示
          alert(ALERT_COMPLETE_CRUD_DATA);
        }
      }

      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // バリデーションチェックでエラーの際は検索しない
      if (!this.validateCheckForSearch(true)) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 画面上の検索条件でWHERE句を作成
      let whereOptions = this.setWhereOptionsForDeliveryDateList();

      console.log({whereOptions});

      const params = {
        whereOptions: whereOptions,
        description: this.descriptionForDeliveryDateList,
        tokuisakiInfo: this.tokuisakiForDeliveryDateList,
      }

      const encodedParams = await base64Encode(JSON.stringify(params));

      if (encodedParams) {
        // 納期一覧表PDF出力処理
        let route = this.$router.resolve({ name: 'DeliveryDateNotification', params: { params: encodedParams }});
        window.open(route.href, '_blank');
      } else {
        // TODO エンコードエラー
      }
      // モーダルを閉じる
      this.dialogDeliveryDateList = false;
      // ローディング画面の終了
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 納期一覧表用のWHERE句生成処理（発注検索）
     */
    setWhereOptionsForDeliveryDateList() {
      // メソッド名を定義
      const method_name="setWhereOptionsForDeliveryDateList"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let whereOptions = "";

      // 検索条件 作成日付
      if (this.scOrderDateRange !== null) {
        if (this.scOrderDates.length > 1) {
          whereOptions += ` AND CHUMON_DATE BETWEEN '${this.scOrderDates[0]}' AND '${this.scOrderDates[1]}'`;
        } else {
          whereOptions += ` AND CHUMON_DATE = '${this.scOrderDates[0]}'`;
        }
      }

      // 検索条件 注文番号
      if (this.scOrderNoStart !== null || this.scOrderNoEnd !== null) {
        if (this.scOrderNoStart !== null && this.scOrderNoEnd !== null) {
          whereOptions += ` AND CHUMON_NO BETWEEN ${this.scOrderNoStart} AND ${this.scOrderNoEnd}`;
        } else if (this.scOrderNoStart !== null) {
          whereOptions += ` AND CHUMON_NO = ${this.scOrderNoStart}`;
        } else {
          whereOptions += ` AND CHUMON_NO = ${this.scOrderNoEnd}`;
        }
      }

      // 検索条件 仕入先コード
      whereOptions +=
        this.scSupplierCode == null
          ? ""
          : ` AND SHIIRESAKI_CD = ${this.scSupplierCode}`;

      // 検索条件 得意先名
      whereOptions +=
        this.scCssClientName == null
          ? ""
          : ` AND CSS_TOKUISAKI_NAME LIKE '%${this.scCssClientName}%'`;

      // 検索条件 得意先コード
      if (
        this.scCssClientCodeStart !== null ||
        this.scCssClientCodeEnd !== null
      ) {
        if (
          this.scCssClientCodeStart !== null &&
          this.scCssClientCodeEnd !== null
        ) {
          whereOptions += ` AND CSS_TOKUISAKI_CD BETWEEN ${this.scCssClientCodeStart} AND ${this.scCssClientCodeEnd}`;
        } else if (this.scCssClientCodeStart !== null) {
          whereOptions += ` AND CSS_TOKUISAKI_CD = ${this.scCssClientCodeStart}`;
        } else {
          whereOptions += ` AND CSS_TOKUISAKI_CD = ${this.scCssClientCodeEnd}`;
        }
      }

      // 検索条件 項目チェック（入荷、仮伝、本伝）
      whereOptions += !this.scEnableCheckBox
        ? ""
        : ` AND NYUKA_FLG = ${this.scInFlgCheckBox} AND KARIDEN_FLG = ${this.scTempSlipFlgCheckBox} AND HONDEN_FLG = ${this.slipFlgCheckBox}`;

      // 検索条件(共通) 所属部門の明細、発注データID、明細データでソート
      whereOptions += ` AND BUMON_CD = ${this.loginUserInfo.BUMON_CD} ORDER BY HACCHU_DATA_ID DESC, MEISAI_NO ASC LIMIT ${RESULT_ROWS_COUNT_LIMIT}`;

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return whereOptions;
    },
    // トシン伝票明細出力ボタン
    async outputToshinSalesSlip() {
      // メソッド名を定義
      const method_name="outputToshinSalesSlip"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      
      // 未保存チェックを実施し、未保存の場合はエラーを表示
      if (!this.isSavedData()) {
        if (confirm(CONFIRM_MESSAGE_UNSAVED_DATA)) {
          // バリデーションチェックでエラーの際は登録しない
          if (!this.validateCheck()) {
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }

          // ローディング画面の開始
          this.$store.commit("setLoading", true);
          // 修正：入力データで更新
          if (!(await this.updateTable())) {
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // エラーダイアログを表示
            alert(ALERT_MESSAGE_COMMON_ERROR);
            // 画面遷移しない
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // 完了ダイアログを表示
          alert(ALERT_COMPLETE_CRUD_DATA);
        }
      }
      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // 出力対象のレコードが存在するかチェック
      // 画面上の検索条件でWHERE句を作成
      let whereOptionsForSearchOrder = await this.setWhereOptionsForSearchOrder();

      // ORDER BY句で切り出し
      let whereOptionsSplit = whereOptionsForSearchOrder.split(`ORDER BY`);
      // console.log({whereOptionsSplit});

      let whereOptions = whereOptionsSplit[0];
      whereOptions += " AND TOSHIN_KEIYU_KBN = 1";
      whereOptions += " AND TOSHIN_DENPYO_DATE IS NOT NULL";
      whereOptions += " AND TOSHIN_DENPYO_INSATSU_FLG = 0";
      whereOptions += " AND TOSHIN_DENPYO_INSATSU_DATE IS NULL";
      whereOptions += ` ORDER BY SHIIRESAKI_CD, SHIIRESAKI_DENPYO_NO, CHAKUBI_DATE, CHUMON_NO, MEISAI_NO LIMIT ${RESULT_ROWS_COUNT_LIMIT}`;

      // console.log({whereOptions});

      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listV_HACCHU_DATA_WITH_MEISAI, {where_options: whereOptions})
        );
        // 結果判定
        if (result.data.listV_HACCHU_DATA_WITH_MEISAI) {
          const items = result.data.listV_HACCHU_DATA_WITH_MEISAI;
          if (items.length > 0) {
            // データを取得できた場合

            // 最大取得件数に抵触しているかチェック
            if(items.length === RESULT_ROWS_COUNT_LIMIT) {
              alert(ALERT_ROWS_COUNT_LIMIT);
            }

            // トシン伝票明細Excel出力処理
            // ヘッダ情報
            // タイムスタンプ
            const date = new Date();
            const now = date.getTime();
            let excelData = {
              header: {
                timeStamp: now,
                departmentName: this.departmentInfo.BUMON_NAME,
                tantoshaName: this.loginUserInfo.TANTOSHA_NAME,
                tel: this.departmentInfo.DENWA_NO,
                fax: this.departmentInfo.FAX_NO,
              },
              orders: items,
            }
            // Excel出力関数用パラメータ
            const condition = {
              ExcelKind: `toshin`,
              ExcelData: JSON.stringify(excelData),
            };

            console.log('excel data', JSON.stringify(excelData));

            try {
              // AppSync→AuroraServerless(MySQL)でデータ取得
              let result = await API.graphql(
                graphqlOperation(executeExcelFileCreation, condition)
              );
              // レスポンスデータを取得できた際の処理
              console.log({result});

              /**
               * JSON.parseする際に
               * result.data.executeExcelFileCreation.bodyだと
               * dataがundefinedに何故かなるためこのタイミングで代入
               */
              const resultData = result.data;

              if (resultData) {
                console.log({result});
                // 関数実行でエラーが発生した場合の処理
                if (result.data.executeExcelFileCreation.statusCode > 200) {
                  console.log(
                    "executeExcelFileCreation：error : " + JSON.stringify(result)
                  );
                  // ログ出力(Lamda関数でのSQL実行でステータスエラー)
                  addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeExcelFileCreation", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
                  alert(ALERT_MESSAGE_COMMON_ERROR);
                } else {
                  // 関数が正常に終了した際の処理
                  // console.log("executeExcelFileCreation：SUCCESS ");

                  const parsedBody = JSON.parse(resultData.executeExcelFileCreation.body);
                  console.log({parsedBody});
                  
                  // S3からファイルを取得
                  const downloadingFileName = parsedBody.fileName.split('/')[2];
                  console.log({downloadingFileName});
                  const storeGetResult = await Storage.get(`excelOutputs/${downloadingFileName}`, {download: true});
                  
                  // S3から取得したファイルをダウンロード
                  const url = URL.createObjectURL(storeGetResult.Body);
                  const a = document.createElement('a');
                  a.href = url;
                  a.download = downloadingFileName || 'download';
                  const clickHandler = () => {
                    setTimeout(() => {
                      URL.revokeObjectURL(url);
                      a.removeEventListener('click', clickHandler);
                    }, 150);
                  };
                  a.addEventListener('click', clickHandler, false);
                  a.click();

                  // ExcelファイルをS3から削除
                  await Storage.remove(`excelOutputs/${downloadingFileName}`);

                  // フラグの更新
                  // トシン伝票印刷フラグ・トシン伝票印刷日付を更新する
                  let updateSQL = '';
                  updateSQL += 'UPDATE T_HACCHU_MEISAI_DATA SET';
                  updateSQL += ' TOSHIN_DENPYO_INSATSU_FLG = 1,';
                  updateSQL += ' TOSHIN_DENPYO_INSATSU_DATE = CURDATE()';

                  // 発注データIDを抜き出し
                  const originalHacchuDataIdList = items.map(function(element) {
                    return element.HACCHU_DATA_ID;
                  });
                  // 重複を削除してIN句の文字列を作成
                  const hacchuDataIdList = Array.from(new Set(originalHacchuDataIdList))
                  const hacchuDataIds = hacchuDataIdList.join(',');

                  updateSQL += ` WHERE HACCHU_DATA_ID IN (${hacchuDataIds})`;
                  updateSQL += ` AND TOSHIN_DENPYO_DATE IS NOT NULL AND TOSHIN_DENPYO_INSATSU_FLG = 0 AND TOSHIN_DENPYO_INSATSU_DATE IS NULL`;
                  console.log({updateSQL});
                  if (!(await this.executeTransactSql([updateSQL]))) {
                    alert(ALERT_MESSAGE_COMMON_ERROR);
                    // ローディングを解除
                    this.$store.commit("setLoading", false);
                    return;
                  }

                  // ローディング画面の終了
                  this.$store.commit("setLoading", false);
                  // ログ出力(SQL-end_OK)
                  addLog('INFO', {message: MSG.INFO["1004"], query: "executeExcelFileCreation", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
                  // ログ出力(method-end)
                  addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
                  // ファイルを返却
                  return a;
                }
              } else {
                // レスポンスデータを取得できなかった際の処理
                console.log("executeExcelFileCreation : responseBody = null：" + JSON.stringify(result));
                // ログ出力(Lamda関数でのSQL実行でエラー)
                addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeExcelFileCreation", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
                alert(ALERT_MESSAGE_COMMON_ERROR);
              }
            } catch (error) {
              console.log({error});
              console.log("executeExcelFileCreation : 異常終了 = " + JSON.stringify(error));
              // ログ出力(exception)
              addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeExcelFileCreation", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
              alert(ALERT_MESSAGE_COMMON_ERROR);
            }
          } else {
            // データがない場合
            // console.log('toshin data not exists');
            this.dataNotExistDialog = true;
          }
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listV_HACCHU_DATA_WITH_MEISAI", where_option:whereOptions}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          //TODO データ取得無しのモーダル表示
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query:"listV_HACCHU_DATA_WITH_MEISAI", where_option:whereOptions}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listV_HACCHU_DATA_WITH_MEISAI", where_option:whereOptions}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
    },
    /**
     * 売上検索ボタン押下処理
     */
    async onClickSearchSales() {
      // メソッド名を定義
      const method_name="onClickSearchSales"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 未保存チェックを実施し、未保存の場合はエラーを表示
      if (!this.isSavedData()) {
        if (confirm(CONFIRM_MESSAGE_UNSAVED_DATA)) {
          // バリデーションチェックでエラーの際は登録しない
          if (!this.validateCheck()) {
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }

          // ローディング画面の開始
          this.$store.commit("setLoading", true);
          // 修正：入力データで更新
          if (!(await this.updateTable())) {
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // エラーダイアログを表示
            alert(ALERT_MESSAGE_COMMON_ERROR);
            // 画面遷移しない
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // 完了ダイアログを表示
          alert(ALERT_COMPLETE_CRUD_DATA);
        }
      }

      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // チェックボックスを解除
      this.selected = [];
      // 売上検索のアコーディオンのみ表示する
      this.panel = [1];

      // バリデーションチェックでエラーの際は検索しない
      if (!this.validateCheckForSearch(true)) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 画面上の検索条件でWHERE句を作成
      let whereOptions = await this.setWhereOptionsForSearchSales();
      if (!(await this.getOrderList(whereOptions))) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 検索条件用のWHERE句生成処理（売上検索）
     */
    async setWhereOptionsForSearchSales() {
      // メソッド名を定義
      const method_name="setWhereOptionsForSearchSales"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let whereOptions = "";

      // 検索条件 売上日付
      // 売上日付を昇順ソートする
      this.scSalesDates.sort((a, b) => new Date(a) - new Date(b));
      if (this.scSalesDateRange !== null) {
        if (this.scSalesDates.length > 1) {
          whereOptions += ` AND URIAGE_DENPYO_DATE BETWEEN '${this.scSalesDates[0]}' AND '${this.scSalesDates[1]}'`;
        } else {
          whereOptions += ` AND URIAGE_DENPYO_DATE = '${this.scSalesDates[0]}'`;
        }
      }

      // 検索条件 売上CSVフラグ
      switch (Number(this.scSalesSalesCsvFlgRadio)) {
        case 1: // チェックなし
          whereOptions += ` AND URIAGE_CSV_FLG = 0`;
          break;
        case 2: // チェックあり
          whereOptions += ` AND URIAGE_CSV_FLG = 1`;
          break;
        default:
          break;
      }

      // 検索条件(共通) 所属部門の明細、発注データID、明細データでソート
      whereOptions += ` AND BUMON_CD = ${this.loginUserInfo.BUMON_CD} ORDER BY HACCHU_DATA_ID DESC, MEISAI_NO ASC`;
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return whereOptions;
    },
    /**
     * 売上CSV出力ボタン押下処理
     * ・売上CSVファイルを出力する
     * ・売上CSV出力フラグを立て、売上CSV出力日付を当日で登録する
     */
    async onClickSalesCsv() {
      // メソッド名を定義
      const method_name="onClickSalesCsv"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 確認ダイアログを表示する
      let result = confirm(CONFIRM_MESSAGE_CSV_INSERT_DATA);
      if( !result ) {
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // データ未保存の場合はCSV出力画面に遷移しない
      if (!this.isSavedData()) {
        alert(ALERT_UNSAVED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 検索明細のレコード数確認
      if (this.searchItemList.length < 1) {
        alert(ALERT_SEARCH_ZERO_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      } else if (this.searchItemList.length > 5000) {
        alert("レコードを絞ってください。");
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // 計上日付の取得
      let sqlSearchListResult = [];
      let sqlSearchList = ["SELECT SY_SIKEIDT FROM KANRI WHERE SY_BASCD = " + this.loginUserInfo.BUMON_CD];
      sqlSearchListResult = await this.executeHanbaikanriSql(sqlSearchList)
      if (!sqlSearchListResult[0][0]) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 取得した営業日をフォーマットに変更
      let beforeDate = "";
      beforeDate = String(sqlSearchListResult[0][0].SY_SIKEIDT);
      await this.changeFormatDate(beforeDate);

      // 商品情報の取得
      sqlSearchList = []
      for (const data of this.searchItemList) {
        sqlSearchList.push(
          "SELECT SH_SHOCD, SH_JAN, SH_SBUBCD, SH_SHOKNM, SH_SHORNM FROM SHOHIN" +
          " WHERE SH_SHOCD = " + data.productCode
        );
      }
      this.sqlSearchListResult = await this.executeHanbaikanriSql(sqlSearchList)
      if (!this.sqlSearchListResult[0][0]) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      this.productInfoList = this.sqlSearchListResult;

      // エラーチェック
      if (!(await this.checkError())) {
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 得意先名、売上担当者コードの取得
      sqlSearchList = [];
      this.sqlSearchListResult = [];
      for (const data of this.searchItemList) {
        sqlSearchList.push(
          "SELECT T1.TK_TORCD, T1.TK_SEICD, T1.TK_TANCD, T1.TK_TORNM1, T2.TT_TANNM FROM TOKUI T1"
            + " LEFT OUTER JOIN TANTO T2"
            + " ON T1.TK_TANCD = T2.TT_TANCD"
          + " WHERE T1.TK_TORCD = " + data.cssClientCode);
      }
      this.sqlSearchListResult = await this.executeHanbaikanriSql(sqlSearchList)
      if (!this.sqlSearchListResult[0][0]) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // CSVリスト生成処理
      await this.createCsvDataList()

      let csvDataStr = this.csvDataList
      if (!(await this.executeCsvFileCreation(csvDataStr))) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 売上CSV出力フラグと売上CSV出力日付の登録
      sqlSearchList = [];
      for (const data of this.searchItemList) {
        if (data.splitSection !== 1) {
          sqlSearchList.push(
            " UPDATE T_HACCHU_MEISAI_DATA SET" +
              " URIAGE_CSV_FLG = 1," +
              " URIAGE_CSV_OUTPUT_DATE = CURDATE()," +
              " SHIIRE_MACHI_CHECK_FLG = FALSE," +
              " URIAGE_AKA_FLG = 1," +
              " URIAGE_AKA_DATE = CURDATE()," +
              " UPDATE_USER_NAME = '" + this.loginUserInfo.TANTOSHA_NAME + "', " +
              " UPDATE_DATE = CURDATE()" +
              " WHERE HACCHU_DATA_ID = " + data.orderDataId +
              " AND MEISAI_NO = " + data.orderBranchNo + ";",
          );
        } else {
          sqlSearchList.push(
            " UPDATE T_HACCHU_MEISAI_DATA SET" +
              " URIAGE_CSV_FLG = 1," +
              " URIAGE_CSV_OUTPUT_DATE = CURDATE()," +
              " SHIIRE_MACHI_CHECK_FLG = FALSE," +
              " UPDATE_USER_NAME = '" + this.loginUserInfo.TANTOSHA_NAME + "', " +
              " UPDATE_DATE = CURDATE()" +
              " WHERE HACCHU_DATA_ID = " + data.orderDataId +
              " AND MEISAI_NO = " + data.orderBranchNo + ";",
          );
        }
      }
      if (!(await this.executeTransactSql(sqlSearchList))) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      alert("売上CSV出力が完了しました。");

      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 日付の変更処理
     * ・日付をフォーマット通りに変更する
     */
    async changeFormatDate(beforeDate) {
      // メソッド名を定義
      const method_name="changeFormatDate"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let y = beforeDate.substr( 0, 4 );
      let m = beforeDate.substr( 4, 2 );
      let d = beforeDate.substr( 6, 2 );
      this.newDate = y + "-" + m + "-" + d;

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * エラーチェック処理
     * ・ CSV出力ボタン押下のエラーについて判別
     */
    async checkError(){
      // メソッド名を定義
      const method_name="checkError"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let i = 0;
      // 請求先コードエラーチェック
      for (const data of this.searchItemList) {
        // エラーメッセージ
        this.todayDate = new Date();
        let alertmsg = "\n\n注文番号   　   ：" + data.orderNo + "\n明細No　　　 ：" + data.orderBranchNo

        // 「商品コード」が0 またはNull の場合
        if (data.productCode == 0 || data.productCode == null) {
          alert("商品コードが入っていない明細があります" + alertmsg);
          return false;
        }
        // 「数量」が0 またはNull の場合
        if (data.amount == 0 || data.amount == null) {
          alert("数量がマイナスおよび空白の明細があります。" + alertmsg);
          return false;
        }
        // 「お客様卸価格」が0 またはNull の場合
        if (data.customerWholesaleCost == 0 || data.customerWholesaleCost == null) {
          let result = confirm(CONFIRM_MESSAGE_ZERO_CUSTOMER_PRICE + alertmsg);
          if( !result ) {
            alert('お客様卸価格が設定されていません。' + alertmsg);
            return false;
          }
        }
        // 「最終仕入価格」が0 またはNull の場合
        if (data.lastCost == 0 || data.lastCost == null) {
          let result = confirm('最終仕入価格が0の明細があります。このまま計上しますか？');
          if( !result ) {
            alert('最終仕入価格が設定されていません。' + alertmsg);
            return false;
          }
        }
        // 「得意先コード」が0 またはNull の場合
        if (data.cssClientCode == 0 || data.cssClientCode == null) {
          alert("CSS得意先コードが設定されていません。" + alertmsg);
          return false;
        }
        // 「売上CSVフラグ」がTrue の場合
        if (data.salesCsvFlg == true) {
          alert("売上CSV出力済みの明細があります。" + alertmsg);
          return false;
        }
        // 「商品コード」が"CSS_SHOHIN"に登録されていない場合
        if (this.productInfoList[i][0].SH_SHOCD == null) {
          alert("商品テーブルに商品が登録されていません。\n\n商品コード 　   ：" + data.productCode);
          return false;
        }
        // 「売上伝票日付」が計上日付より前の日付の場合
        if (data.salesSlipDate < this.newDate) {
          alert("計上日付より前の売上伝票日付になっている明細があります。" + alertmsg + "\n売上伝票日付  ：" + data.salesSlipDate);
          return false;
        }
        // 「売上伝票日付」がNull の場合
        if (data.salesSlipDate == null) {
          alert("売上伝票日付が入っていない明細があります。" + alertmsg);
          return false;
        }
        // 「品名」がNull の場合
        if (data.productName == null) {
          alert("品名が入っていない明細があります。" + alertmsg);
          return false;
        }
        // 「品名」に改行が入っている場合
        if (data.productName.indexOf('\n') > -1) {
          alert("品名に改行が入っている明細があります。\n削除してください。" + alertmsg + "\n品名　　   　   ：" + data.productName);
          return false;
        }
        i = i + 1;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * CSVリスト生成処理
     */
    async createCsvDataList() {
      // メソッド名を定義
      const method_name="createCsvDataList"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // １つ前の得意先コード
      let beforeCssClientCode = null;
      // １つ前の現場コード
      let beforeSpotName = null;
      // １つ前の次回請求
      let beforeNextBillingFlg = null;
      // 行番号
      let lineNo = null;
      // カウンタ
      let cnt = 0;
      // 行番号更新フラグ
      let lineNoUpdFlg = false;
      // 伝票番号
      let SlipNo = null;
      // 伝票区分結果
      let voucher = 0
      // 粗利結果
      let grossProfit = 0
      // 年月日時分秒
      let nowDate = null;
      // 今日日付
      let date = new Date;

      this.csvDataList = null
      let searchItemList = Object.assign([{}], this.searchItemList)

      for (const data of searchItemList) {
        // 1レコード分の検索結果格納用
        let purchasingData = this.sqlSearchListResult.find(
          (items) => items[0].TK_TORCD == data.cssClientCode
        );
        let productData = this.productInfoList.find(
          (items) => items[0].SH_SHOCD == data.productCode
        );
        // 売上伝票日付8桁
        let salesSlipDate
        console.log("売上伝票日付8桁 " + data.salesSlipDate)
        if (data.salesSlipDate !== null) {
          salesSlipDate = data.salesSlipDate.split(`-`);
          salesSlipDate =  `${salesSlipDate[0]}${salesSlipDate[1]}${salesSlipDate[2]}`
        }
        // 商品コード下5桁
        let productCode = "";
        if (data.productCode !== null) {
          productCode = ( '00000' + data.productCode ).slice( -5 )
        }
        // 品名左40文字
        let productName
        if (data.productName !== null) {
          productName = data.productName.substr( 0 , 40 )
        }

        // 行番号・カウンタの生成
        if (data.cssClientCode !== beforeCssClientCode) {
          lineNoUpdFlg = true;
        } else if (data.spotName !== beforeSpotName) {
          if (this.scSalesMixedSpotNameRadio == 0) {
            lineNoUpdFlg = true;
          }
        } else if (data.nextBillingFlg !== beforeNextBillingFlg) {
          lineNoUpdFlg = true;
        } else if (lineNo == 6) {
          lineNoUpdFlg = true;
        }

        if (lineNoUpdFlg == true) {
          lineNo = 1;
          cnt = cnt + 1;
          nowDate =
            date.getFullYear() +
            ( '00' + (date.getMonth() + 1) ).slice( -2 ) +
            ( '00' + (date.getDate()) ).slice( -2 ) +
            ( '00' + (date.getHours()) ).slice( -2 ) +
            ( '00' + (date.getMinutes()) ).slice( -2 ) +
            ( '00' + (date.getSeconds()) ).slice( -2 );
          SlipNo = this.loginUserInfo.BUMON_CD + "1" + nowDate + ( '000' + cnt ).slice( -3 )
        } else {
          lineNo = lineNo + 1;
        }

        beforeCssClientCode = data.cssClientCode;
        beforeSpotName = data.spotName;
        beforeNextBillingFlg = data.nextBillingFlg;
        lineNoUpdFlg = false;

        // 伝票区分
        switch (data.splitSection) {
          case 1:
            voucher = 11;
            break;
          case 2:
            voucher = 12;
            break;
          case 3:
            voucher = 13;
            break;
          default:
            break;
        }
        // JANコード
        let janCode = "";
        if (productData[0].SH_JAN !== null) {
          janCode = productData[0].SH_JAN
        }
        // 略称名
        let abbreviationName = "";
        if (productData[0].SH_SHORNM !== null) {
          abbreviationName = productData[0].SH_SHORNM
        }
        // 現場名
        let spotName = "";
        if (data.spotName !== null) {
          spotName = data.spotName
        }
        // 次回請求
        let nextBillingFlg = 9
        if (data.nextBillingFlg == true) {
          nextBillingFlg = 1
        }
        // 粗利
        let kn = Math.round(data.amount * data.customerWholesaleCost);
        let arari = Math.round(((data.customerWholesaleCost - data.lastCost) * data.amount * 1000)) / 1000;
        if (data.customerWholesaleCost !== 0) {
          grossProfit = Math.round((arari / kn * 100) * 100) / 100;
        }

        this.csvDataList = this.csvDataList +
          this.loginUserInfo.BUMON_CD + "\t" + // TM_BASCD
          "1\t" + // TM_DATAKB
          await this.deleteTabStr(data.cssClientCode) + "\t" + // TM_TORCD
          salesSlipDate + "\t" + // TM_DENDT
          voucher + "\t" + // TM_DENKB
          SlipNo + "\t" + // TM_DENNO
          lineNo + "\t" + // TM_GYONO
          await this.deleteTabStr(purchasingData[0].TK_SEICD) + "\t" + // TM_SEICD
          await this.deleteTabStr(purchasingData[0].TK_TORNM1) + "\t" + // TM_TORNM
          "0\t" + // TM_COKCD
          "\t" + // TM_COKNM
          await this.deleteTabStr(purchasingData[0].TK_TANCD) + "\t" + // TM_TANCD
          await this.deleteTabStr(purchasingData[0].TT_TANNM) + "\t" + // TM_TANNM
          "0\t" + // TM_CHKCD
          this.loginUserInfo.BUMON_CD + "\t" + // TM_EBUCD
          await this.deleteTabStr(data.productCode) + "\t" + // TM_SHOCD
          await this.deleteTabStr(productName) + "\t" + // TM_SHONM
          await this.deleteTabStr(janCode) + "\t" + // TM_JANCD
          "0\t" + // TM_SBUACD
          await this.deleteTabStr(productData[0].SH_SBUBCD) + "\t" + // TM_SBUBCD
          "\t" + // TM_TANANO1
          "\t" + // TM_TANANO2
          "0\t" + // TM_IRISU
          Math.abs(data.amount) + "\t" + // TM_SU
          "1\t" + // TM_TAICD
          "\t" + // TM_TAINM
          await this.deleteTabStr(data.lastCost) + "\t" + // TM_SIRTN
          await this.deleteTabStr(data.customerWholesaleCost) + "\t" + // TM_URITN
          "0\t" + // TM_JYOTN
          Math.abs(kn) + "\t" + // TM_KN
          arari + "\t" + // TM_ARARI
          grossProfit + "\t" + // TM_ARARITU
          "0\t" + // TM_TAXKN
          "0\t" + // TM_STANCD
          "\t" + // TM_STANNM
          "0\t" + // TM_BIKOCD
          'H-' + await this.deleteTabStr(data.orderNo) + "\t" + // TM_BIKO
          "0\t" + // TM_TEGDT
          "\t" + // TM_TEGNO
          "9\t" + // TM_BNKCD
          "\t" + // TM_BNKNM
          "0\t" + // TM_SITCD
          "\t" + // TM_SITNM
          "\t" + // TM_KOZANO
          "\t" + // TM_FURIUKE
          "0\t" + // TM_SOSAICD
          "0\t" + // TM_BINCD
          "\t" + // TM_BINNM
          "0\t" + // TM_INTANCD
          "\t" + // TM_INTANNM
          "\t" + // PURCHASESLIP_NO
          "0\t" + // TM_JYUHANO
          "0\t" + // TM_JYUHAGYO
          "9\t" + // TM_KARIKB
          "0\t" + // TM_KARINO
          "0\t" + // TM_KARIGYO
          nextBillingFlg + "\t" + // TM_CYOHAKB
          "0\t" + // TM_CYOHADT
          "1\t" + // TM_STAXKB
          "3\t" + // TM_TAXKB
          "1\t" + // TM_TMALKB
          "0\t" + // TM_TMLKKB
          "1\t" + // TM_MALKB
          "0\t" + // TM_MLKKB
          "9\t" + // TM_HEKTNKB
          "9\t" + // TM_MOTKB
          "9\t" + // TM_URIKAIKB
          "9\t" + // TM_SYUKB
          "9\t" + // TM_RUIKB
          "0\t" + // TM_DELKB
          "0\t" + // TM_GKN
          "0\t" + // TM_GARAKN
          "0\t" + // TM_GTAXKN
          "0\t" + // TM_TEKINMCD
          "\t" + // TM_TEKINM
          "0\t" + // TM_UNICD
          "0\t" + // TM_SHACD
          "0\t" + // TM_BUNCD
          "0\t" + // TM_HDENKB
          nowDate + ( '000' + cnt ).slice( -3 ) + "\t" + // TM_NBASNM
          "\t" + // TM_MIDENM
          "\t" + // TM_URINM
          "\t" + // TM_NAINO
          "\t" + // TM_HTANNM
          "\t" + // TM_HYAKA
          "\t" + // TM_HYAKB
          "\t" + // TM_HYAKC
          "\t" + // TM_HYAKD
          "1\t" + // TM_KBN1
          "1\t" + // TM_KBN2
          "1\t" + // TM_KBN3
          "1\t" + // TM_KBN4
          "1\t" + // TM_KBN5
          "0\t" + // TM_SYORIDT
          "0\t" + // TM_OUTDT
          "0\t" + // TM_DTAIDT
          "0\t" + // TM_SEITAIDT
          "0\t" + // TM_URITAIDT
          "0\t" + // TM_ZAITAIDT
          "0\t" + // TM_DENDTO
          "0\t" + // TM_DENNOO
          "0\t" + // TM_GYONOO
          "0\t" + // TM_MEKCD
          productCode + "\t" + // TM_HOWCD
          this.loginUserInfo.BUMON_CD + "\t" + // TM_BASCD1
          "1\t" + // TM_DATAKB1
          await this.deleteTabStr(purchasingData[0].TK_TANCD) + "\t" + // TM_TANCD1
          await this.deleteTabStr(data.cssClientCode) + "\t" + // TM_TORCD1
          salesSlipDate + "\t" + // TM_DENDT1
          voucher + "\t" + // TM_DENKB1
          "0\t" + // TM_DENNO1
          lineNo + "\t" + // TM_GYONO1
          this.loginUserInfo.BUMON_CD + "\t" + // TM_BASCD2
          "1\t" + // TM_DATAKB2
          "0\t" + // TM_SEITAIDT2
          await this.deleteTabStr(purchasingData[0].TK_SEICD) + "\t" + // TM_SEICD2
          await this.deleteTabStr(data.cssClientCode) + "\t" + // TM_TORCD2
          salesSlipDate + "\t" + // TM_DENDT2
          voucher + "\t" + // TM_DENKB2
          "0\t" + // TM_DENNO2
          lineNo + "\t" + // TM_GYONO2
          this.loginUserInfo.BUMON_CD + "\t" + // TM_BASCD3
          "1\t" + // TM_DATAKB3
          "0\t" + // TM_URITAIDT3
          await this.deleteTabStr(purchasingData[0].TK_SEICD) + "\t" + // TM_SEICD3
          await this.deleteTabStr(data.cssClientCode) + "\t" + // TM_TORCD3
          salesSlipDate + "\t" + // TM_DENDT3
          voucher + "\t" + // TM_DENKB3
          "0\t" + // TM_DENNO3
          lineNo + "\t" + // TM_GYONO3
          this.loginUserInfo.BUMON_CD + "\t" + // TM_BASCD4
          "0\t" + // TM_ZAITAIDT4
          await this.deleteTabStr(data.productCode) + "\t" + // TM_SHOCD4
          salesSlipDate + "\t" + // TM_DENDT4
          "2\t" + // TM_DATAKB4
          await this.deleteTabStr(data.cssClientCode) + "\t" + // TM_TORCD4
          voucher + "\t" + // TM_DENKB4
          "0\t" + // TM_DENNO4
          lineNo + "\t" + // TM_GYONO4
          "0\t" + // TM_SAKDT
          "0\t" + // TM_SYUDT
          await this.deleteTabStr(productData[0].SH_SHOKNM) + "\t" + // TM_SHOKNM
          "\t" + // TM_REBSBT
          "0\t" + // TM_RDENDT
          "0\t" + // TM_NOUHINKBN
          "0\t" + // TM_PICKTIME
          "0\t" + // TM_KENPINFLG
          "0\t" + // TM_SOKOCD
          "0\t" + // TM_CYOKFLG
          "0\t" + // TM_KONPOTANI
          janCode + "\t" + // TM_JAN
          "0\t" + // TM_SHOPTN
          "\t" + // TM_NOUKITEXT
          "0\t" + // TM_NEBSITENCD
          "0\t" + // TM_NEBSEIKYU
          "0\t" + // TM_NEBDT
          "\t" + // TM_NBBIKO
          "0\t" + // TM_NBNEBADENKB
          "0\t" + // TM_HANO
          "0\t" + // TM_HAGYO
          "0\t" + // TM_HDENDT
          "0\t" + // TM_EDIDT
          "0\t" + // TM_EDINO
          "0\t" + // TM_EDIGYO
          "\t" + // TM_CHUNOH
          "\t" + // TM_KCHUNO
          spotName + "\t" + // TM_CHUNOM
          "0\t" + // TM_SIMECHK
          "0\t" + // TM_SYKDT
          "0\t" + // TM_IDOBASCD
          "0\t" + // TM_IDODATAKB
          "0\t" + // TM_IDOTORCD
          "0\t" + // TM_IDODENDT
          "0\t" + // TM_IDODENKB
          "0\t" + // TM_IDONO
          "0\t" + // TM_IDOGYO
          abbreviationName + "\t" + // TM_SHORNM
          "0\t" + // TM_DPCODE
          "\t" + // TM_DPNAME
          await this.deleteTabStr(data.cssClientCode) + "\t" + // TM_TORCD5
          await this.deleteTabStr(purchasingData[0].TK_TORNM1) + "\t" + // TM_TORNM5
          "0\t" + // TM_COKCD5
          "\t" // TM_COKNM5
          + "\n"
        ;
      }
      console.log("csvDataList   " + this.csvDataList)
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * tab文字の削除
     */
    async deleteTabStr(csvDataStr) {
      return String(csvDataStr).replace(/\t+/g, " ");
    },
    /**
     * トランザクション関数の実行処理
     */
    async executeCsvFileCreation(csvDataStr) {
      // メソッド名を定義
      const method_name="executeCsvFileCreation"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      
      let condition = {
        CsvKind: `uri`,
        CsvData: csvDataStr,
        DepartmentCd: this.loginUserInfo.BUMON_CD
      };
      console.log(
        "executeCsvFileCreation：condition = " +
          JSON.stringify(condition)
          );
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeCsvFileCreation, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeCsvFileCreation.statusCode > 200) {
            console.log(
              "executeCsvFileCreation：error : " + JSON.stringify(result)
            );
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeCsvFileCreation", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // SQLが正常に終了した際の処理
            console.log("executeCsvFileCreation：SUCCESS ");
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeCsvFileCreation", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          console.log("executeCsvFileCreation : responseBody = null：" + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeCsvFileCreation", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        console.log("executeCsvFileCreation : 異常終了 = " + JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeCsvFileCreation", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 登録ボタン押下処理
     */
    async onClickRegister() {
      // メソッド名を定義
      const method_name="onClickRegister"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      console.log("onClickRegister [in]");
      // バリデーションチェックでエラーの際は登録しない
      if (!(await this.validateCheck())) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      if (this.searchItemList.length === 0) {
        alert("検索結果に1件もレコードがありません。");
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 保存済みデータの場合、ダイアログを表示
      if (this.isSavedData()) {
        alert(ALERT_ALREADY_SAVED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      if (!confirm(CONFIRM_MESSAGE_SAVE_DATA)) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディング画面の表示
      this.$store.commit("setLoading", true);

      // 修正：入力データで更新
      if (!(await this.updateTable())) {
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 未保存チェック用配列を更新
      this.saveOriginalParams();

      // ローディングを解除
      this.$store.commit("setLoading", false);
      // 完了ダイアログを表示
      alert(ALERT_COMPLETE_CRUD_DATA);

      // バリデーションエラーをリセット
      this.resetValidate();

      console.log("onClickRegister [out]");
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 明細データ用編集カードのOK処理
     */
    save(item) {
      // メソッド名を定義
      const method_name="save"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);

      // 利益率計算
      this.calcProfitRatio(item);

      // 着日未定フラグON/OFF
      item.inDateUndecidedFlg = item.inDate ? false : true;

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  データテーブルの単位編集ダイアログ open
     */
    openUnit(item) {
      // メソッド名を定義
      const method_name="openUnit"
      // オペレーションタイプを定義
      const operation_type="0004"
      this.viewUnit = item.unit;
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    },
    /**
     *  データテーブルの単位編集ダイアログ OKボタン
     */
    saveUnit(item) {
      // メソッド名を定義
      const method_name="saveUnit"
      // オペレーションタイプを定義
      const operation_type="0004"
      this.$refs.unitComboBox.blur();
      this.$nextTick(() => {
        item.unit = this.viewUnit;
        // 更新フラグを立てる
        this.changeIsUpdatedItem(item);
      });
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    },
    /**
     * 明細項目変更時のisUpdatedItem更新処理
     */
    changeIsUpdatedItem(item) {
      // メソッド名を定義
      const method_name="changeIsUpdatedItem"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      let editedIndex = this.searchItemList.indexOf(item);
      let result = this.originalParamsDetails[editedIndex];
      // isUpdatedItemを削除したリストを生成
      let newResult =  Object.assign({}, result);
      let newItem = Object.assign({}, item);
      delete newResult.isUpdatedItem;
      delete newItem.isUpdatedItem;
      // 初期状態から変更があれば、更新フラグを立てる
      item.isUpdatedItem = JSON.stringify(newResult) !== JSON.stringify(newItem);
      console.log(item.isUpdatedItem)
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    cancel() {
      // メソッド名を定義
      const method_name="cancel"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 編集ボタン押下時処理
     */
    editItem(item) {
      // メソッド名を定義
      const method_name="editItem"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 入力画面に編集対象の発注データIDを渡す
      this.$router.push({
        name: "OrderInput",
        query :{ reqOrderDataId: item.orderDataId, },
      });

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * マスタ系テーブルからデータを取得
     * ・得意先基本情報テーブル
     */
    async getMasterData() {
      //  販売管理システムの商品基本テーブルから商品コードを取得。
      // メソッド名を定義
      const method_name="getMasterData"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 仕入先カナ用コンボボックスのリスト生成
      let condition = {
        where_options:
          "AND BUMON_CD = " +
          this.loginUserInfo.BUMON_CD +
          " ORDER BY SHIIRESAKI_CD",
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listM_SHIIRESAKI, condition)
        );
        // 結果判定
        if (result.data.listM_SHIIRESAKI) {
          // データを取得できた場合
          // 取得結果をリストに追加
          let resultData = result.data.listM_SHIIRESAKI;
          for (const data of resultData) {
            this.scSupplierList.push({
              comboItem: data.SHIIRESAKI_CD + "：" + data.SHIIRESAKI_KANA_NAME,
              code: data.SHIIRESAKI_CD,
              name: data.SHIIRESAKI_NAME,
              toshinSection: data.TOSHIN_KEIYU_KBN,
              panasonicSection: data.PANASONIC_KBN,
              commissionSection: data.TESURYO_KBN,
              rebateSection: data.SHIIRESAKI_REBATE_KBN,
            });
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query:"listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // ログ出力(SQL-end_0)
          addLog('INFO', {message: MSG.INFO["1005"], query:"listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // コンボボックスのリスト生成
      this.scCssSalesManagerList = [];
      this.scCssClientNameList = [];
      condition = {
        where_options:
          "AND BUMON_CD = " +
          this.loginUserInfo.BUMON_CD + 
          " GROUP BY CSS_URIAGE_TANTOSHA_CD, CSS_TOKUISAKI_NAME"
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listV_HACCHU_DATA_WITH_MEISAI, condition)
        );
        // 結果判定
        if (result.data.listV_HACCHU_DATA_WITH_MEISAI) {
          // データを取得できた場合
          // 取得結果をリストに追加
          let resultData = result.data.listV_HACCHU_DATA_WITH_MEISAI;
          // TODO 最大件数アラート
          for (const data of resultData) {
            if (data.CSS_URIAGE_TANTOSHA_CD !== null) {
              this.scCssSalesManagerList.push(data.CSS_URIAGE_TANTOSHA_CD);
            }
            if (data.CSS_TOKUISAKI_CD !== null) {
              this.scCssClientNameList.push({
                comboItem: data.CSS_TOKUISAKI_CD + "：" + data.CSS_TOKUISAKI_NAME,
                name: data.CSS_TOKUISAKI_NAME,
                code: data.CSS_TOKUISAKI_CD,
              });
            }
          }
          // それぞれコード順にソート
          this.scCssSalesManagerList.sort((a, b) => a - b);
          this.scCssClientNameList.sort((a, b) => a.code - b.code);
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query:"listV_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // ログ出力(SQL-end_0)
          addLog('INFO', {message: MSG.INFO["1005"], query:"listV_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log(JSON.stringify(error));
        // ログ出力(exception)
          addLog('ERROR', {message: MSG.ERROR["3006"], query: "listV_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
      //★Log入れる(method -end)
    },
    /**
     * 得意先名入力時の初期値設定処理
     */
    async openCssClientName(item) {
      // メソッド名を定義
      const method_name="openCssClientName"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 品番入力用のコンボボックス表示時に表示する初期値を設定
      this.cssClientObj = item.cssClientName;

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 得意先名選択時の自動入力
     */
    async changeCssClientNameList(item, cssClientObj) {
      // メソッド名を定義
      const method_name="changeCssClientNameList"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 得意先名未選択の場合はreturn
      if (!cssClientObj || typeof cssClientObj !== 'object') {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // 選択した得意先名のデータを取得
      const searchClient = this.cssClientNameList.find(
        (items) => items.code === cssClientObj.code
      );
      // リストに存在しない場合、何もしない
      if (!searchClient) {
        // ローディング画面の解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 得意先単価テーブルからお客様卸価格を取得
      let sqlList = [
        `SELECT TAT_TORCD, TAT_TORTN, TAT_SHOCD FROM TTANKA` +
          ` WHERE TAT_BASCD = ${this.loginUserInfo.BUMON_CD}` +
          ` AND TAT_TORCD = ${searchClient.code}` +
          ` AND TAT_SHOCD = ${item.productCode}`
      ];
      let result = await this.executeHanbaikanriSql(sqlList);
      if (result == null || result == void 0) {
        // オートコンプリートのローディングを解除
        this.isLoading = false;
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 取得結果を格納
      item.cssClientCode = searchClient.code;
      item.cssClientName = searchClient.name;
      item.cssSalesManagerCode = searchClient.managerCode;
      if (result[0][0]) {
        item.customerWholesaleCost = result[0][0].TAT_TORTN;
      }
      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);

      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 原ボタン押下時の自動入力
     */
    calcCost(item) {
      // メソッド名を定義
      const method_name="calcCost"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      if (!item.multiplationRate) {
        item.calcMethod = null;
        // ローディング画面の開始
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      item.customerWholesaleCost = Math.ceil(
        item.lastCost / item.multiplationRate
      );
      item.calcMethod = "原価÷";

      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 定ボタン押下時の自動入力
     */
    calcListPrice(item) {
      // メソッド名を定義
      const method_name="calcListPrice"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      if (!item.multiplationRate) {
        item.calcMethod = null;
        // ローディング画面の開始
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      item.customerWholesaleCost = Math.ceil(
        item.listPrice * item.multiplationRate
      );
      item.calcMethod = "定価×";

      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 卸ボタン押下時の自動入力
     */
    calcWholesalePrice(item) {
      // メソッド名を定義
      const method_name="calcWholesalePrice"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      if (!item.multiplationRate) {
        item.calcMethod = null;
        // ローディング画面の開始
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      item.customerWholesaleCost = Math.ceil(
        item.wholesaleCost * item.multiplationRate
      );
      item.calcMethod = "卸×";

      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },

    /**
     * 当日ボタン押下時の自動入力
     */
    async setDateToday(item, id) {
      // メソッド名を定義
      const method_name="setDateToday"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 当日日付を取得
      let date = new Date();
      let sysdate =
        date.getFullYear() +
        String(date.getMonth() + 1).padStart(2, "0") +
        String(date.getDate()).padStart(2, "0");
      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // 営業日の取得
      // 検索条件
      let resultclassificationData;
      this.todayDate = new Date();
      let sqlSerchList = [
        "select * from OK_V_EIGYOUDATE where ED_DATE=" + sysdate,
      ];

      resultclassificationData = await this.executeHanbaikanriSql(sqlSerchList);
      if (!resultclassificationData[0][0]) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 値を設定する
      let returnDate =
        String(resultclassificationData[0][0].ED_DATE).substr(0, 4) +
        "-" +
        String(resultclassificationData[0][0].ED_DATE).substr(4, 2) +
        "-" +
        String(resultclassificationData[0][0].ED_DATE).substr(6, 2);
      if (id === 0) {
        item.inDate = returnDate;
        item.inDateUndecidedFlg = false;
      } else {
        item.tempSlipPrintDate = returnDate;
      }

      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 翌日ボタン押下時の自動入力
     */
    async setDateNextday(item, id) {
      // メソッド名を定義
      const method_name="setDateNextday"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // 当日日付を取得
      let date = new Date();
      let sysdate =
        date.getFullYear() +
        String(date.getMonth() + 1).padStart(2, "0") +
        String(date.getDate()).padStart(2, "0");
      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // 営業日の取得
      // 検索条件
      let resultclassificationData;
      this.todayDate = new Date();
      let sqlSerchList = [
        "select * from OK_V_EIGYOUDATE where ED_DATE=" + sysdate,
      ];

      resultclassificationData = await this.executeHanbaikanriSql(sqlSerchList);
      if (!resultclassificationData[0][0]) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      let returnDate =
        String(resultclassificationData[0][0].NEXT_DATE).substr(0, 4) +
        "-" +
        String(resultclassificationData[0][0].NEXT_DATE).substr(4, 2) +
        "-" +
        String(resultclassificationData[0][0].NEXT_DATE).substr(6, 2);
      // 値を設定する
      if (id === 0) {
        item.inDate = returnDate;
        item.inDateUndecidedFlg = false;
      } else {
        item.tempSlipPrintDate = returnDate;
      }

      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 同注番Copyボタン押下時の自動入力
     */
     async copyInDateToSameOrder(item) {
      // メソッド名を定義
      const method_name="copyInDateToSameOrder"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      const inDate = item.inDate;
      const orderNo = item.orderNo;

      for (const searchItem of this.searchItemList) {
        if (searchItem.orderNo !== orderNo) continue;
        searchItem.inDate = inDate;
        searchItem.inDateUndecidedFlg = searchItem.inDate ? false : true
        searchItem.isUpdatedItem = true;
      }

      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  日ボタン押下時の自動入力
     */
    salesSlipDateToday(item) {
      // メソッド名を定義
      const method_name="salesSlipDateToday"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // 当日日付を取得
      let date = new Date();
      let sysdate =
        date.getFullYear() +
        "-" +
        String(date.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(date.getDate()).padStart(2, "0");
      item.salesSlipDate = sysdate;

      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  リベート区分チェック時の処理
     */
    async changeRebate(item) {
      // メソッド名を定義
      const method_name="changeRebate"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // 小数チェック用変数
      let calcLastCost;
      // リベート区分がTRUEの場合
      if (item.rebateFlg) {
        // NETが0の場合
        if (!item.net) {
          alert("NETが0です。");
          item.rebateFlg = false;
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
          // NETが0以外の場合
        } else {
          calcLastCost = item.net * 0.967;
          console.log("KAWAKATSU : calcLastCost(TRUE) before = " + calcLastCost);
          // NET * 0.967の結果が整数値の場合
          if (Number.isInteger(calcLastCost)) {
            item.lastCost = calcLastCost;
            console.log("KAWAKATSU : calcLastCost(TRUE) after 1 = " + item.lastCost);
            // NET * 0.967の結果が少数の場合
          } else {
            item.lastCost = Math.ceil(calcLastCost);
            console.log("KAWAKATSU : calcLastCost(TRUE) after 2 = " + item.lastCost);
          }
        }
        // リベート区分がFALSEの場合
      } else {
        calcLastCost = item.net * 1.0278;
        console.log("KAWAKATSU : calcLastCost(FALSE) before = " + calcLastCost);
        // 少数3位で四捨五入
        item.lastCost = Math.round(calcLastCost * 100) / 100;
        console.log("KAWAKATSU : calcLastCost(FALSE) after = " + item.lastCost);
      }

      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },

    /**
     *  一括ボタン押下時の処理
     */
    summarySlipStatus(item) {
      // メソッド名を定義
      const method_name="summarySlipStatus"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // 入荷にチェックありの場合
      if (item.inFlg) {
        item.inFlg = false;
        item.tempSlipFlg = false;
        item.slipFlg = false;
        // 入荷にチェックなしの場合
      } else {
        item.inFlg = true;
        item.tempSlipFlg = true;
        item.slipFlg = true;
      }
      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },

    /**
     *  トシン伝票番号更新時の処理
     */
    toshinSplitNo(item) {
      // メソッド名を定義
      const method_name="toshinSplitNo"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // トシン伝票番号が0またはNULLの場合
      if (!item.toshinSplitNo || item.toshinSplitNo == 0) {
        item.inFlg = false;
        item.tempSlipFlg = false;
        // それ以外の場合
      } else {
        item.inFlg = true;
        item.tempSlipFlg = true;
      }
      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  トシン伝票日付更新時の処理
     */
    toshinSplitDate(item) {
      // メソッド名を定義
      const method_name="toshinSplitDate"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // トシン伝票日付が0またはNULLの場合
      if (!item.toshinSplitDate || item.toshinSplitDate == 0) {
        item.inFlg = false;
        item.tempSlipFlg = false;
        // それ以外の場合
      } else {
        item.inFlg = true;
        item.tempSlipFlg = true;
      }
      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  仕入先伝票番号更新時の処理
     */
    purchaseSlipNo(item) {
      // メソッド名を定義
      const method_name="purchaseSlipNo"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // 仕入先伝票番号が0またはNULLの場合
      if (!item.purchaseSlipNo || item.purchaseSlipNo == 0) {
        item.inFlg = false;
        item.tempSlipFlg = false;
        item.slipFlg = false;
        // それ以外の場合
      } else {
        item.inFlg = true;
        item.tempSlipFlg = true;
        item.slipFlg = true;
      }
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 明細行を選択した内容で絞り込む
     */
    updateSummaryCombo() {
      // メソッド名を定義
      const method_name="updateSummaryCombo"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      this.headers = [];
      // 共通出力項目
      this.headers.push({ text: "", value: "data-table-select", width: "50px" });
      this.headers.push({ text: "操作", value: "actions", sortable: false, width: "42px" });
      this.headers.push({ text: "発注日付", value: "orderDate", width: "85px" });
      this.headers.push({ text: "注文番号", value: "orderNo", width: "40px" });
      this.headers.push({ text: "明細No", value: "orderBranchNo", width: "40px" });
      this.headers.push({ text: "品名", value: "productName", width: "200px" });
      this.headers.push({ text: "商品コード", value: "productCode", width: "80px" });
      this.headers.push({ text: "CSS仕入先コード", value: "cssSupplierCode", width: "80px" });
      this.headers.push({ text: "仕入先名", value: "supplierName", width: "100px" });
      this.headers.push({ text: "数量", value: "amount", width: "50px" });
      this.headers.push({ text: "単位", value: "unit", width: "50px" });
      this.headers.push({ text: "キャンセル", value: "cancelFlg", width: "50px"});
      switch (this.filterValue) {
        case 0: // 全部
          this.headers.push({ text: "仕入先伝票番号", value: "purchaseSlipNo", width: "100px" });
          this.headers.push({ text: "NET", value: "net", width: "30px" });
          this.headers.push({ text: "リベート区分", value: "rebateFlg", width: "60px" });
          this.headers.push({ text: "お客様卸価格", value: "customerWholesaleCost", width: "50px" });
          this.headers.push({ text: "仕入CSV", value: "purchaseCsvFlg", width: "40px" });
          this.headers.push({ text: "売上CSV", value: "salesCsvFlg", width: "40px" });
          this.headers.push({ text: "計算方式", value: "calcMethod", width: "70px" });
          this.headers.push({ text: "掛率", value: "multiplationRate", width: "50px" });
          this.headers.push({ text: "原", value: "calcCost", width: "30px" });
          this.headers.push({ text: "定", value: "calcListPrice", width: "30px" });
          this.headers.push({ text: "卸", value: "calcWholesalePrice", width: "30px" });
          this.headers.push({ text: "入荷時仕入価格", value: "arrivalPurchasePrice", width: "70px" });
          this.headers.push({ text: "最終仕入価格", value: "lastCost", width: "60px" });
          this.headers.push({ text: "利益率", value: "profitRatio", width: "40px" });
          this.headers.push({ text: "原価変更依頼", value: "costChangeFlg", width: "60px" });
          this.headers.push({ text: "得意先コード", value: "cssClientCode", width: "110px" });
          this.headers.push({ text: "得意先名", value: "cssClientName", width: "200px" });
          this.headers.push({ text: "着日", value: "inDate", width: "85px" });
          this.headers.push({ text: "着日未定", value: "inDateUndecidedFlg", width: "40px" });
          this.headers.push({ text: "当", value: "inDateToday", width: "40px" });
          this.headers.push({ text: "翌", value: "inDateNextday", width: "40px" });
          this.headers.push({ text: "同注番コピー", value: "copySameOrderInDate", width: "50px" });
          this.headers.push({ text: "AM・PM", value: "arrivalDescription", width: "80px" });
          this.headers.push({ text: "仮伝日付", value: "tempSlipPrintDate", width: "85px" });
          this.headers.push({ text: "当", value: "tempSlipPrintDateToday", width: "40px" });
          this.headers.push({ text: "翌", value: "tempSlipPrintDateNextday", width: "40px" });
          this.headers.push({ text: "仮伝印刷フラグ", value: "tempSlipPrintFlg", width: "60px" });
          this.headers.push({ text: "備考", value: "detailsDescription", width: "200px" });
          this.headers.push({ text: "売伝No", value: "tempSlipNo", width: "100px" });
          this.headers.push({ text: "現場名", value: "spotName", width: "200px" });
          this.headers.push({ text: "次回請求", value: "nextBillingFlg", width: "40px" });
          this.headers.push({ text: "売上伝票日付", value: "salesSlipDate", width: "85px" });
          this.headers.push({ text: "日", value: "salesSlipDateToday", width: "30px" });
          this.headers.push({ text: "メーカー卸価格", value: "wholesaleCost", width: "60px" });
          this.headers.push({ text: "定価", value: "listPrice", width: "40px" });
          this.headers.push({ text: "一括", value: "summarySlipStatus", width: "40px" });
          this.headers.push({ text: "入荷", value: "inFlg", width: "40px" });
          this.headers.push({ text: "仮伝", value: "tempSlipFlg", width: "40px" });
          this.headers.push({ text: "本伝", value: "slipFlg", width: "40px" });
          this.headers.push({ text: "トシン経由区分", value: "toshinSection", width: "70px" });
          this.headers.push({ text: "トシン伝票日付", value: "toshinSplitDate", width: "80px" });
          this.headers.push({ text: "トシン伝票番号", value: "toshinSplitNo", width: "80px" });
          this.headers.push({ text: "トシン伝票印刷済", value: "toshinSplitPrintFlg", width: "80px" });
          this.headers.push({ text: "トシン伝票印刷日付", value: "toshinSplitPrintDate", width: "100px" });
          this.headers.push({ text: "メール送信済", value: "sentEmailFlg", width: "50px" });
          this.headers.push({ text: "返品", value: "returnFlg", width: "40px" });
          this.headers.push({ text: "値引", value: "discountFlg", width: "40px" });
          this.headers.push({ text: "引上", value: "pullUpFlg", width: "40px" });
          this.headers.push({ text: "依頼", value: "returnRequestFlg", width: "40px" });
          this.headers.push({ text: "返却", value: "returnProductFlg", width: "40px" });
          this.headers.push({ text: "返伝No", value: "returnSlipNo", width: "40px" });
          this.headers.push({ text: "仕入赤伝", value: "purchaseAkaCsvOutputFlg", width: "40px" });
          this.headers.push({ text: "仕入赤伝日付", value: "purchaseAkaCsvOutputDate", width: "85px" });
          this.headers.push({ text: "売上赤伝", value: "salesCsvAkaCsvOutputFlg", width: "40px" });
          this.headers.push({ text: "売上赤伝日付", value: "salesAkaCsvOutputDate", width: "85px" });
          this.headers.push({ text: "登録者名", value: "managerName", width: "80px" });
          this.headers.push({ text: "売上CSV作成日付", value: "salesCsvOutputDate", width: "85px" });
          this.headers.push({ text: "仕入CSV作成日付", value: "purchaseCsvOutputDate", width: "85px" });
          this.headers.push({ text: "売上担当者コード", value: "cssSalesManagerCode", width: "80px" });
          break;
        case 1: // 納期
          this.headers.push({ text: "NET", value: "net", width: "30px" });
          this.headers.push({ text: "リベート区分", value: "rebateFlg", width: "60px" });
          this.headers.push({ text: "メーカー卸価格", value: "wholesaleCost", width: "60px" });
          this.headers.push({ text: "定価", value: "listPrice", width: "40px" });
          this.headers.push({ text: "着日", value: "inDate", width: "85px" });
          this.headers.push({ text: "着日未定", value: "inDateUndecidedFlg", width: "40px" });
          this.headers.push({ text: "当", value: "inDateToday", width: "40px" });
          this.headers.push({ text: "翌", value: "inDateNextday", width: "40px" });
          this.headers.push({ text: "同注番コピー", value: "copySameOrderInDate", width: "50px" });
          this.headers.push({ text: "AM・PM", value: "arrivalDescription", width: "80px" });
          this.headers.push({ text: "仮伝日付", value: "tempSlipPrintDate", width: "85px" });
          this.headers.push({ text: "当", value: "tempSlipPrintDateToday", width: "40px" });
          this.headers.push({ text: "翌", value: "tempSlipPrintDateNextday", width: "40px" });
          this.headers.push({ text: "仮伝印刷フラグ", value: "tempSlipPrintFlg", width: "60px" });
          this.headers.push({ text: "売伝No", value: "tempSlipNo", width: "100px" });
          this.headers.push({ text: "得意先コード", value: "cssClientCode", width: "110px" });
          this.headers.push({ text: "得意先名", value: "cssClientName", width: "200px" });
          this.headers.push({ text: "備考", value: "detailsDescription", width: "200px" });
          this.headers.push({ text: "現場名", value: "spotName", width: "200px" });
          this.headers.push({ text: "一括", value: "summarySlipStatus", width: "40px" });
          this.headers.push({ text: "入荷", value: "inFlg", width: "40px" });
          this.headers.push({ text: "仮伝", value: "tempSlipFlg", width: "40px" });
          this.headers.push({ text: "本伝", value: "slipFlg", width: "40px" });
          break;
        case 2: // 売上準備
          this.headers.push({ text: "仕入CSV", value: "purchaseCsvFlg", width: "40px" });
          this.headers.push({ text: "売上CSV", value: "salesCsvFlg", width: "40px" });
          this.headers.push({ text: "NET", value: "net", width: "30px" });
          this.headers.push({ text: "リベート区分", value: "rebateFlg", width: "60px" });
          this.headers.push({ text: "最終仕入価格", value: "lastCost", width: "60px" });
          this.headers.push({ text: "メーカー卸価格", value: "wholesaleCost", width: "60px" });
          this.headers.push({ text: "定価", value: "listPrice", width: "40px" });
          this.headers.push({ text: "お客様卸価格", value: "customerWholesaleCost", width: "50px" });
          this.headers.push({ text: "得意先コード", value: "cssClientCode", width: "110px" });
          this.headers.push({ text: "得意先名", value: "cssClientName", width: "200px" });
          this.headers.push({ text: "備考", value: "detailsDescription", width: "200px" });
          this.headers.push({ text: "現場名", value: "spotName", width: "200px" });
          this.headers.push({ text: "登録者名", value: "managerName", width: "80px" });
          this.headers.push({ text: "売上担当者コード", value: "cssSalesManagerCode", width: "80px" });
          break;
        case 3: // 売上
          this.headers.push({ text: "仕入CSV", value: "purchaseCsvFlg", width: "40px" });
          this.headers.push({ text: "売上CSV", value: "salesCsvFlg", width: "40px" });
          this.headers.push({ text: "最終仕入価格", value: "lastCost", width: "60px" });
          this.headers.push({ text: "お客様卸価格", value: "customerWholesaleCost", width: "50px" });
          this.headers.push({ text: "利益率", value: "profitRatio", width: "40px" });
          this.headers.push({ text: "得意先コード", value: "cssClientCode", width: "110px" });
          this.headers.push({ text: "得意先名", value: "cssClientName", width: "200px" });
          this.headers.push({ text: "次回請求", value: "nextBillingFlg", width: "40px" });
          this.headers.push({ text: "売上伝票日付", value: "salesSlipDate", width: "85px" });
          this.headers.push({ text: "日", value: "salesSlipDateToday", width: "30px" });
          this.headers.push({ text: "メーカー卸価格", value: "wholesaleCost", width: "60px" });
          this.headers.push({ text: "定価", value: "listPrice", width: "40px" });
          this.headers.push({ text: "一括", value: "summarySlipStatus", width: "40px" });
          this.headers.push({ text: "入荷", value: "inFlg", width: "40px" });
          this.headers.push({ text: "仮伝", value: "tempSlipFlg", width: "40px" });
          this.headers.push({ text: "本伝", value: "slipFlg", width: "40px" });
          this.headers.push({ text: "売上CSV作成日付", value: "salesCsvOutputDate", width: "85px" });
          this.headers.push({ text: "仕入CSV作成日付", value: "purchaseCsvOutputDate", width: "85px" });
          this.headers.push({ text: "売上担当者コード", value: "cssSalesManagerCode", width: "80px" });
          break;
        case 4: // 入荷
          this.headers.push({ text: "仕入CSV", value: "purchaseCsvFlg", width: "40px" });
          this.headers.push({ text: "売上CSV", value: "salesCsvFlg", width: "40px" });
          this.headers.push({ text: "仕入先伝票番号", value: "purchaseSlipNo", width: "100px" });
          this.headers.push({ text: "お客様卸価格", value: "customerWholesaleCost", width: "50px" });
          this.headers.push({ text: "NET", value: "net", width: "30px" });
          this.headers.push({ text: "リベート区分", value: "rebateFlg", width: "60px" });
          this.headers.push({ text: "最終仕入価格", value: "lastCost", width: "60px" });
          this.headers.push({ text: "メーカー卸価格", value: "wholesaleCost", width: "60px" });
          this.headers.push({ text: "定価", value: "listPrice", width: "40px" });
          this.headers.push({ text: "一括", value: "summarySlipStatus", width: "40px" });
          this.headers.push({ text: "入荷", value: "inFlg", width: "40px" });
          this.headers.push({ text: "仮伝", value: "tempSlipFlg", width: "40px" });
          this.headers.push({ text: "本伝", value: "slipFlg", width: "40px" });
          this.headers.push({ text: "売上CSV作成日付", value: "salesCsvOutputDate", width: "85px" });
          this.headers.push({ text: "仕入CSV作成日付", value: "purchaseCsvOutputDate", width: "85px" });
          break;
        case 5: // トシン
          this.headers.push({ text: "NET", value: "net", width: "30px" });
          this.headers.push({ text: "メーカー卸価格", value: "wholesaleCost", width: "60px" });
          this.headers.push({ text: "定価", value: "listPrice", width: "40px" });
          this.headers.push({ text: "リベート区分", value: "rebateFlg", width: "60px" });
          this.headers.push({ text: "トシン経由区分", value: "toshinSection", width: "70px" });
          this.headers.push({ text: "トシン伝票日付", value: "toshinSplitDate", width: "80px" });
          this.headers.push({ text: "トシン伝票番号", value: "toshinSplitNo", width: "80px" });
          this.headers.push({ text: "トシン伝票印刷済", value: "toshinSplitPrintFlg", width: "80px" });
          this.headers.push({ text: "トシン伝票印刷日付", value: "toshinSplitPrintDate", width: "100px" });
          this.headers.push({ text: "一括", value: "summarySlipStatus", width: "40px" });
          this.headers.push({ text: "入荷", value: "inFlg", width: "40px" });
          this.headers.push({ text: "仮伝", value: "tempSlipFlg", width: "40px" });
          this.headers.push({ text: "本伝", value: "slipFlg", width: "40px" });
          break;
        case 6: // 返品
          this.headers.push({ text: "良品/不良品", value: "returnStatus", width: "210px"});
          this.headers.push({ text: "引上", value: "pullUpFlg", width: "40px" });
          this.headers.push({ text: "依頼", value: "returnRequestFlg", width: "40px" });
          this.headers.push({ text: "返却", value: "returnProductFlg", width: "40px" });
          this.headers.push({ text: "仕入赤伝", value: "purchaseAkaCsvOutputFlg", width: "40px" });
          this.headers.push({ text: "仕入赤伝日付", value: "purchaseAkaCsvOutputDate", width: "85px" });
          this.headers.push({ text: "売上赤伝", value: "salesCsvAkaCsvOutputFlg", width: "40px" });
          this.headers.push({ text: "売上赤伝日付", value: "salesAkaCsvOutputDate", width: "85px" });
          break;
        case 7: // 通常
          // 通常のみ共通のヘッダーではないためクリア
          this.headers = [];
          this.headers.push({ text: "", value: "data-table-select", width: "50px" });
          this.headers.push({ text: "操作", value: "actions", sortable: false, width: "42px" });
          this.headers.push({ text: "発注日付", value: "orderDate", width: "85px" });
          this.headers.push({ text: "注文番号", value: "orderNo", width: "40px" });
          this.headers.push({ text: "明細No", value: "orderBranchNo", width: "40px" });
          this.headers.push({ text: "品名", value: "productName", width: "200px" });
          this.headers.push({ text: "仕入先名", value: "supplierName", width: "100px" });
          this.headers.push({ text: "数量", value: "amount", width: "50px" });
          this.headers.push({ text: "NET", value: "net", width: "30px" });
          this.headers.push({ text: "最終仕入価格", value: "lastCost", width: "60px" });
          this.headers.push({ text: "お客様卸価格", value: "customerWholesaleCost", width: "50px" });
          this.headers.push({ text: "得意先名", value: "cssClientName", width: "200px" });
          this.headers.push({ text: "着日", value: "inDate", width: "85px" });
          this.headers.push({ text: "着日未定", value: "inDateUndecidedFlg", width: "40px" });
          break;
        default:
          break;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  返品値引がある場合スタイルを変更処理
     */
    rowClasses(item) {
      // メソッド名を定義
      const method_name="rowClasses"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      if (item.returnFlg == true) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return "style-1";
      } else if (item.discountFlg == true) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return "style-2";
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  現場名コピー
     */
    corySpot() {
      // メソッド名を定義
      const method_name="corySpot"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      if (!this.selected[0]) {
        alert(ALERT_UNSELECTED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      } else if (this.selected[1]) {
        alert("レコードが複数選択されています");
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      for (const item of this.searchItemList) {
        if (
          this.selected[0].cssClientCode == item.cssClientCode &&
          this.selected[0].orderNo == item.orderNo
        ) {
          item.spotName = this.selected[0].spotName;
          this.changeIsUpdatedItem(item);
        }
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  原価更新依頼ボタン押下処理
     */
    async onClickRequestChangingCost() {
      // メソッド名を定義
      const method_name="onClickRequestChangingCost"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 未保存のデータがある場合はエラー
      if (!this.isSavedData()) {
        alert(ALERT_UNSAVED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 一つも選択されていない場合、処理を行わない
      if (!this.selected[0]) {
        alert(ALERT_UNSELECTED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // Eメールが未承認の場合はエラー
      if (!(await this.checkAuthUser())) {
        alert(ALERT_MESSAGE_EMAIL_AUTH_ERROR);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 確認ダイアログを表示
      if (
        confirm(
          "選択した商品の原価変更依頼を東北商品センターに依頼します。\nよろしいですか？"
        )
      ) {
        // エラーのチェック
        for (const item of this.selected) {
          if (item.lastCost == null) {
            alert(
              "最終仕入価格が空のレコードがあります。" +
              "\n\n注文番号   　   ：" + item.orderNo + "\n明細No　　　 ：" + item.orderBranchNo
            );
            return;
          }
        }

        // ローディングの開始
        this.$store.commit("setLoading", true);

        // 商品情報の登録処理
        if (!(await this.insertCenterRequest())) {
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }

        // 原価変更依頼メールを送信
        if (!(await this.sendCenterRequestToAdministrator())) {
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }

        // 登録済みアイテムのフラグ更新
        if (!(await this.updateCenterRequestFlg())) {
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // 選択中アイテムを空にする
        this.selected = [];

        // 未保存チェック用配列を更新
        this.saveOriginalParams();
        alert(ALERT_COMPLETE_CRUD_DATA);
      }

      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    },
    /**
     *  原価更新を依頼する商品情報の登録処理
     */
    async insertCenterRequest() {
      // メソッド名を定義
      const method_name="insertCenterRequest"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let sqlList = [];
      for (const item of this.selected) {
        sqlList.push(
          `INSERT INTO T_CENTER_SHOHIN_REQUEST SET` +
            ` BUMON_CD = ${this.loginUserInfo.BUMON_CD},` +
            ` SHOHIN_CD = ${item.productCode},` +
            ` HINBAN_NO = '${item.productNo}',` +
            ` IRAI_DATE = CURDATE(),` +
            ` SHOHIN_NAME = '${item.productName}',` +
            ` TEKA_GK = ${item.listPrice},` +
            ` OKYAKUSAMA_OROSHIKAKAKU_GK = ${item.customerWholesaleCost},` +
            ` SAISHU_SHIIRE_KAKAKU_GK = ${item.lastCost},` +
            ` NET_GK = ${item.net},` +
            ` SHIIRESAKI_NAME = '${item.supplierName}',` +
            ` SHORI_STATUS_KBN = 0,` +
            ` SHONIN_IRAISHA_EMAIL = '${this.loginUserInfo.TANTOSHA_ADDRESS}',` +
            ` CREATE_DATE = CURDATE(),` +
            ` CREATE_USER_NAME = '${this.loginUserInfo.LOGIN_USER_CD}',` +
            ` UPDATE_DATE = CURDATE(),` +
            ` UPDATE_USER_NAME = '${this.loginUserInfo.LOGIN_USER_CD}';`
        );
      }
      let condition = { SQLs: sqlList };
      console.log(
        "insertCenterRequest：condition = " + JSON.stringify(condition)
      );

      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeTransactSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            console.log(
              "insertCenterRequest：error : " + JSON.stringify(result)
            );
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              for (const rows of responseBody.data) {
                console.dir(rows, { depth: null });
              }
              // ログ出力(SQL-end_OK)
              addLog('INFO', {message: MSG.INFO["1004"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            }
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          console.log(
            "insertCenterRequest : error : " + JSON.stringify(result)
          );
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        console.log(
          "insertCenterRequest : 異常終了 = " + JSON.stringify(error)
        );
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     *  原価更新依頼メールの送信処理
     */
    async sendCenterRequestToAdministrator() {
      // メソッド名を定義
      const method_name="sendCenterRequestToAdministrator"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ログ出力(sendEmail-start)
      addLog('INFO', {message: MSG.INFO["1010"],}, this.$route.name, method_name, TYPE["0011"],);
      // SESからメール送信
      try {
        const credentials = await Auth.currentCredentials();
        const ses = new AWS.SES({
          credentials: Amplify.Auth.essentialCredentials(credentials),
          region: AWS_DEFAULT_REGION,
        });
        // ログイン中のユーザー情報を取得
        const requester = this.loginUserInfo.TANTOSHA_NAME;
        const fromAddress = this.loginUserInfo.TANTOSHA_ADDRESS;
        const toAddress = this.departmentInfo.SHONINSHA_EMAIL;
        const departmentName = this.departmentInfo.BUMON_NAME;
        const departmentPhone = this.departmentInfo.DENWA_NO;
        const departmentFax = this.departmentInfo.FAX_NO;

        console.log(
          "fromAddress = " +
            fromAddress +
            ", toAddress = " +
            toAddress
        );

        let message =
          `${departmentName}の${requester}から、原価更新の依頼がありました。\n\n` +
          // `依頼日       ：${}\n` +
          // `依頼No      ：${item.requestNo}\n` +
          // `商品コード：${item.productCode}\n\n\n` +
          `サン電子株式会社  ${departmentName}\n` +
          `${requester}\n` +
          `TEL：${departmentPhone}  FAX：${departmentFax}\n` +
          `E-Mail：${fromAddress}`;

        let params = {
          Destination: {
            ToAddresses: [toAddress],
          },
          Message: {
            Body: {
              Text: { Data: message },
            },
            Subject: { Data: `原価更新を依頼されました` },
          },
          Source: fromAddress,
          ReturnPath: MAILINGLIST_ADDRESS,
        };

        const result = await ses.sendEmail(params).promise();
        console.log(result);
        // ログ出力(sendEmail-end_OK)
        addLog('INFO', {message: MSG.INFO["1011"], result: "result"}, this.$route.name, method_name, TYPE["0011"],);
      } catch (error) {
        // エラー時はログ出力のみ
        console.log(error);
        addLog('INFO', {message: MSG.INFO["3013"], error: "error"}, this.$route.name, method_name, TYPE["0011"],);
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     *  原価更新依頼フラグの更新処理
     */
    async updateCenterRequestFlg() {
      // メソッド名を定義
      const method_name="updateCenterRequestFlg"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let sqlList = [];
      for (const selectItem of this.selected) {
        // 発注明細データ用SQLを追加、原価依頼フラグを依頼済みにする
        sqlList.push(
          `UPDATE T_HACCHU_MEISAI_DATA SET` +
            ` GENKA_IRAI_FLG = 1` +
            ` WHERE HACCHU_DATA_ID = ${selectItem.orderDataId}` +
            ` AND MEISAI_NO = ${selectItem.orderBranchNo};`
        );
      }

      // トランザクション関数の実行
      if (!(await this.executeTransactSql(sqlList))) {
        console.log("updateCenterRequestFlg false");
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }

      for (const selectItem of this.selected) {
        // 発注明細データ用SQLを追加、原価依頼フラグを依頼済みにする
        let item = this.searchItemList.find(
          (items) =>
            items.orderDataId === selectItem.orderDataId &&
            items.orderBranchNo === selectItem.orderBranchNo
        );
        item.costChangeFlg = true;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * NETの値が変更された時の処理
     */
    async calcNet(item) {
      // メソッド名を定義
      const method_name="calcNet"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // 仕入先コードに紐づくリベート区分を取得
      // コンボボックス用リストの取得
      // 検索条件
      let condition = {
        BUMON_CD : this.loginUserInfo.BUMON_CD,
        SHIIRESAKI_CD: item.supplierCode
      };
      let resultData;
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(getM_SHIIRESAKI, condition)
        );
        // 結果判定
        if (result.data.getM_SHIIRESAKI) {
          // データを取得できた場合
          // 取得結果をリストに追加
          resultData = result.data.getM_SHIIRESAKI;
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query:"getM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          if (result.errors) {
            // データを取得できなかった場合
            // @TODO データ取得無しの場合の処理を以下に記載。
            // ログ出力(SQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3010"], query: "getM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // データ0件時
            // ログ出力(SQL-end_0)
            addLog('INFO', {message: MSG.INFO["1005"], query: "getM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          }
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log("異常系ログ:" + JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      console.log(JSON.stringify(resultData))
      // リベートフラグをfalseにする
      item.rebateFlg = false;
      // 最終仕入価格計算条件
      switch (resultData.SHIIRESAKI_REBATE_KBN) {
        case 1: // リベート区分が1の場合
          item.lastCost = parseFloat(item.net) * 0.94 * 1.0278;
          item.rebateFlg = true;
          break;
        case 2: // リベート区分が2の場合
          item.lastCost = parseFloat(item.net) * 0.97 * 1.0278;
          // 手数料区分が1以外の場合
          if (resultData.TESURYO_KBN !== 1) item.rebateFlg = true;
          break;
        default: // リベート区分が上記以外の場合
          // 手数料区分が1の場合はNET * 1.0278を、それでなければNETの値を最終仕入価格に格納
          item.lastCost = resultData.TESURYO_KBN !== 1 ? item.net : parseFloat(item.net) * 1.0278;
          break;
      }
      // 小数点以下2桁までの表示とする
      if (String(item.lastCost).indexOf(".") != -1) {
        item.lastCost = item.lastCost.toFixed(2);
      }
      // 数値に変換できない場合、最終仕入価格をnullとする
      item.lastCost = isNaN(item.lastCost) ? null : item.lastCost;

      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  仮伝印刷済みフラグ更新時の処理
     */
    changeTempSlipPrintFlg(item) {
      // メソッド名を定義
      const method_name="changeTempSlipPrintFlg"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // チェックを外した場合
      if (!item.tempSlipPrintFlg) {
        item.tempSlipNo = null;
      }
      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  トシン印刷済みフラグ更新時の処理
     */
    changeToshinSplitPrintFlg(item) {
      // メソッド名を定義
      const method_name="changeToshinSplitPrintFlg"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // チェックを外した場合
      if (!item.toshinSplitPrintFlg) {
        item.toshinSplitPrintDate = null;
      }
      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  メール編集押下時、未選択の場合
     */
    noSelected() {
      // メソッド名を定義
      const method_name="noSelected"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      alert(ALERT_UNSELECTED_DATA);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  メール編集押下時、得意先複数選択の場合
     */
    selectedItems() {
      // メソッド名を定義
      const method_name="selectedItems"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      alert("得意先が複数選択されています。");

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    },
    /**
     *  MailFormに渡す値を設定する
     */
    async openMailForm() {
      // メソッド名を定義
      const method_name="openMailForm"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 未保存のデータがある場合はエラー
      if (!this.isSavedData()) {
        alert(ALERT_UNSAVED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // Eメールが未承認の場合はエラー
      if (!(await this.checkAuthUser())) {
        alert(ALERT_MESSAGE_EMAIL_AUTH_ERROR);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 変数を初期化する
      this.reqMailList = [];
      // メール送信済みメッセージ表示フラグ
      let mailCheckFlg = true;
      // エラーチェックを行う
      for (const data of this.selected) {
        if (data.sentEmailFlg && mailCheckFlg) {
          if (
            !confirm(
              "メール送信済みのレコードが選択されています。\n再送信を行いますか？"
            )
          ) {
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }
          // OK選択後、同じ質問はしない
          mailCheckFlg = false;
        }
        if (data.returnFlg || data.discountFlg) {
          alert("返品、または値引の明細が選択されています。");
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        if (!data.productName) {
          alert("品名が入力されていません。");
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        if (!data.amount) {
          alert("数量が入力されていません。");
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        if (String(data.amount).indexOf("-") != -1) {
          alert("数量がマイナスです。");
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        if (!data.inDate) {
          alert("着日が入力されていません。");
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        if (data.returnFlg || data.discountFlg) {
          alert("返品、または値引の明細が選択されています。");
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
      }
      this.selected.sort((a, b) => a.orderNo - b.orderNo);
      for (const data of this.selected) {
        this.reqMailList.push({
          orderDataId: data.orderDataId,
          orderBranchNo: data.orderBranchNo,
          productName: data.productName,
          amount: data.amount,
          inDate: data.inDate,
          spotName: data.spotName,
          cssClientCode: data.cssClientCode,
          cssClientName: data.cssClientName,
        });
      }
      this.isDisabled = false;
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  MailFormを閉じる
     */
    closeMailForm(item) {
      // メソッド名を定義
      const method_name="closeMailForm"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // メールを送信していない場合、ダイアログを表示
      if (!item) {
        if (!confirm(CONFIRM_MESSAGE_LEAVE_EMAIL_FORM)) {
          this.isDisabled = false;
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
      }
      // 変数を初期化し、データを更新する
      this.dialog = false;
      this.reqMailList = [];
      this.onClickSearchOrderData();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  MailFormを開く前に、Eメールが認証済か調べる
     */
    async checkAuthUser() {
      // メソッド名を定義
      const method_name="checkAuthUser"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // SESの認証情報を取得
      if (!(await this.getSesData())) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      return true;
    },
    /**
     *  SESの認証情報を取得
     */
    async getSesData() {
      // メソッド名を定義
      const method_name="getSesData"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      const credentials = await Auth.currentCredentials();
      let ses = new AWS.SES({
        credentials: Amplify.Auth.essentialCredentials(credentials),
        region: AWS_DEFAULT_REGION,
        apiVersion: SES_API_VERSION
      });
      let params = {
        Identities: [
          this.loginUserInfo.TANTOSHA_ADDRESS
        ]
      };
      let authData = "";
      try {
        const result = await ses.getIdentityVerificationAttributes(params).promise();
        authData = JSON.stringify(result.VerificationAttributes)
        // 認証済みかどうかの結果を変数に格納
        this.authEmail = authData.indexOf('Success') !== -1 ? true : false;
      } catch (error) {
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return authData.indexOf('Success') !== -1;
    },
    /**
     *  単価登録押下処理
     */
    async onClickInsertUnitPrice() {
      // メソッド名を定義
      const method_name="onClickInsertUnitPrice"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 未保存のデータがある場合はエラー
      if (!this.isSavedData()) {
        alert(ALERT_UNSAVED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 一つも選択されていない場合、処理を行わない
      if (!this.selected[0]) {
        alert(ALERT_UNSELECTED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 複数選択されている場合、処理を行わない
      if (this.selected[1] !== undefined) {
        alert("レコードが複数選択されています。");
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 確認ダイアログを表示
      if (confirm(CONFIRM_MESSAGE_INSERT_UNIT_PRICE)) {
        // お客様卸価格が0,null,空文字の場合
        if (!this.selected[0].customerWholesaleCost) {
          alert("お客様卸価格がセットされていません。");
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // 得意先コードが0,null,空文字の場合
        if (!this.selected[0].cssClientCode) {
          alert("得意先コードが選択されていません。");
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // 商品コードが0,null,空文字の場合
        if (!this.selected[0].productCode) {
          alert("選択されたレコードがありません。");
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }

        // ローディング画面を表示
        this.$store.commit("setLoading", true);
        // 得意先コード存在しない場合
        // 得意先リストからデータ検索を行う
        let clientSqlList = [
          `SELECT TOKUI.TK_TORCD FROM TOKUI WHERE TOKUI.TK_TORCD = ` + this.selected[0].cssClientCode,
        ];
        let resultClientData = await this.executeHanbaikanriSql(clientSqlList);
        if (resultClientData[0].length == 0 || resultClientData[0][0] == null) {
          alert("存在しない得意先コードです。");
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // 商品コードが存在しない場合
        // 商品リストからデータ検索を行う
        let productSqlList = [
          `SELECT SHOHIN.SH_SHOCD FROM SHOHIN WHERE SHOHIN.SH_SHOCD = ` + this.selected[0].productCode,
        ];
        let resultProductData = await this.executeHanbaikanriSql(productSqlList);
        if (resultProductData[0].length == 0 || resultProductData[0][0] == null) {
          alert("存在しない商品コードです。");
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // 当日日付を取得
        let date = new Date();
        let sysdate =
          date.getFullYear() +
          String(date.getMonth() + 1).padStart(2, "0") +
          String(date.getDate()).padStart(2, "0");

        // 単価登録を行うSQL
        let sqlList = [];
        sqlList.push(
          `MERGE INTO TTANKA USING DUAL ON (` +
            `TAT_BASCD = ${this.selected[0].departmentCode}` + // BUMON_CD
            ` AND TAT_TORCD = ${this.selected[0].cssClientCode}` + // CSS_TOKUISAKI_CD
            ` AND TAT_SHOCD = ${this.selected[0].productCode}` + // SHOHIN_CD
            `) WHEN MATCHED THEN UPDATE SET TAT_TORTN = ${this.selected[0].customerWholesaleCost}, ` + // OKYAKUSAMA_OROSHIKAKAKU_GK
            `TAT_TAICD = 1,` +
            `TAT_SYUDT = ${sysdate},` +
            `TAT_TORCD1 = ${this.selected[0].cssClientCode}, ` + // CSS_TOKUISAKI_CD
            `TAT_TORCD2 = ${this.selected[0].cssClientCode}, ` + // CSS_TOKUISAKI_CD
            `TAT_TORCD3 = ${this.selected[0].cssClientCode}, ` + // CSS_TOKUISAKI_CD
            `TAT_TORCD4 = ${this.selected[0].cssClientCode}` + // CSS_TOKUISAKI_CD
            ` WHEN NOT MATCHED THEN INSERT (TAT_BASCD, TAT_TORCD, TAT_SHOCD, TAT_TAICD, TAT_TORTN, TAT_SAKDT, TAT_SYUDT, TAT_SHOPTN,` +
            `TAT_TRHEKDT, TAT_TORTNA, TAT_USTORTN, TAT_USHEKDT, TAT_USTORTNA, TAT_BAITN, TAT_BAHEKDT, TAT_BAITNA, TAT_TEITN,` +
            `TAT_TEHEKDT, TAT_TEITNA, TAT_TORTN1, TAT_TR1HEKDT, TAT_TORTN1A, TAT_IRISU, TAT_STADT, TAT_ENDDT, TAT_TOKTN, TAT_KAKEPH,` +
            `TAT_TORCD1, TAT_TORCD2, TAT_TORCD3, TAT_TORCD4, TAT_ASHOGR, TAT_ESHONO, TAT_ESHOGU, TAT_ASHOCD, TAT_HYAKA, TAT_HYAKB,` +
            `TAT_HYAKC, TAT_HYAKD, TAT_KBN1, TAT_KBN2, TAT_KBN3, TAT_KBN4, TAT_KBN5, TAT_MTANCD) VALUES (` +
            `${this.selected[0].departmentCode}, ` + // TAT_BASCD
            `${this.selected[0].cssClientCode}, ` + // TAT_TORCD
            `${this.selected[0].productCode}, ` + // TAT_SHOCD
            `1, ` + // TAT_TAICD
            `${this.selected[0].customerWholesaleCost}, ` + // TAT_TORTN
            `${sysdate}, ` + // TAT_SAKDT
            `${sysdate}, ` + // TAT_SYUDT
            `0, ` + // TAT_SHOPTN
            `0, ` + // TAT_TRHEKDT
            `0, ` + // TAT_TORTNA
            `0, ` + // TAT_USTORTN
            `0, ` + // TAT_USHEKDT
            `0, ` + // TAT_USTORTNA
            `0, ` + // TAT_BAITN
            `0, ` + // TAT_BAHEKDT
            `0, ` + // TAT_BAITNA
            `0, ` + // TAT_TEITN
            `0, ` + // TAT_TEHEKDT
            `0, ` + // TAT_TEITNA
            `0, ` + // TAT_TORTN1
            `0, ` + // TAT_TR1HEKDT
            `0, ` + // TAT_TORTN1A
            `0, ` + // TAT_IRISU
            `0, ` + // TAT_STADT
            `0, ` + // TAT_ENDDT
            `0, ` + // TAT_TOKTN
            `0, ` + // TAT_KAKEPH
            `${this.selected[0].cssClientCode}, ` + // TAT_TORCD1
            `${this.selected[0].cssClientCode}, ` + // TAT_TORCD2
            `${this.selected[0].cssClientCode}, ` + // TAT_TORCD3
            `${this.selected[0].cssClientCode}, ` + // TAT_TORCD4
            `0, ` + // TAT_ASHOGR
            `0, ` + // TAT_ESHONO
            `0, ` + // TAT_ESHOGU
            `0, ` + // TAT_ASHOCD
            `0, ` + // TAT_HYAKA
            `0, ` + // TAT_HYAKB
            `0, ` + // TAT_HYAKC
            `0, ` + // TAT_HYAKD
            `0, ` + // TAT_KBN1
            `0, ` + // TAT_KBN2
            `0, ` + // TAT_KBN3
            `0, ` + // TAT_KBN4
            `0, ` + // TAT_KBN5
            `0` + // TAT_MTANC
            `)`
        );
        // SQLを実行する
        if (!(await this.executeHanbaikanriSql(sqlList))) {
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }

        // 選択中アイテムを空にする
        this.selected = [];

        // 未保存チェック用配列を更新
        this.saveOriginalParams();
        // ローディングを解除
        this.$store.commit("setLoading", false);

        alert("単価登録が完了しました。");
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
    },
    /**
     * バリデーションチェックをリセット
     */
    resetValidate() {
      // メソッド名を定義
      const method_name="resetValidate"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ヘッダーのバリデーションをリセット
      this.$refs.formSearchConditionsOrder.resetValidation();
      this.$refs.formSearchConditionsSales.resetValidation();
      // 明細行のバリデーションをリセット
      if (this.$refs.formDetails) {
        this.$refs.formDetails.resetValidation();
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 利益率計算
     */
    calcProfitRatio(item) {
      console.log('calcProfitRatio', item);
      if(!item.customerWholesaleCost) {
        item.profitRatio = 0;
      } else {
        if (!item.lastCost) {
          item.profitRatio = 100;
        } else {
          item.profitRatio = Math.round((1-(item.lastCost / item.customerWholesaleCost)) * 100 * 100) / 100;
        }
      }
      console.log('item.profitRatio', item.profitRatio);
    }
  },
};
</script>
<style scoped>
/* 1列目スクロール時固定 */
.v-data-table >>> th:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 3 !important;
  background-color: white;
}
.v-data-table >>> td:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}
/* 2列目スクロール時固定 */
.v-data-table >>> th:nth-child(2) {
  position: sticky;
  left: 50px;
  z-index: 3 !important;
  background-color: white;
}
.v-data-table >>> td:nth-child(2) {
  position: sticky;
  left: 50px;
  z-index: 1;
  background-color: white;
}
/* 3列目スクロール時固定 */
.v-data-table >>> th:nth-child(3) {
  position: sticky;
  left: 92px;
  z-index: 3 !important;
  background-color: white;
}
.v-data-table >>> td:nth-child(3) {
  position: sticky;
  left: 92px;
  z-index: 1;
  background-color: white;
}
/* 4列目スクロール時固定 */
.v-data-table >>> th:nth-child(4) {
  position: sticky;
  left: 177px;
  z-index: 3 !important;
  background-color: white;
}
.v-data-table >>> td:nth-child(4) {
  position: sticky;
  left: 177px;
  z-index: 1;
  background-color: white;
}
/* 5列目スクロール時固定 */
.v-data-table >>> th:nth-child(5) {
  position: sticky;
  left: 217px;
  z-index: 3 !important;
  background-color: white;
}
.v-data-table >>> td:nth-child(5) {
  position: sticky;
  left: 217px;
  z-index: 1;
  background-color: white;
}
/* 6列目スクロール時固定 */
.v-data-table >>> th:nth-child(6) {
  position: sticky;
  left: 257px;
  z-index: 3 !important;
  background-color: white;
}
.v-data-table >>> td:nth-child(6) {
  position: sticky;
  left: 257px;
  z-index: 1;
  background-color: white;
}
.carendar-position {
  position: relative;
}
.nameOmit {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.productNameOmit {
  width: 200px;
  overflow: visible;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  line-height: 15px;
}
</style>