<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>メールフォーム</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <!-- 宛先 -->
              <!-- サービス制限要確認。文字数や宛先数など -->
              <v-col cols="12" sm="12">
                <v-textarea
                  v-model="address"
                  label="宛先"
                  :rules="[required, limit_length2560]"
                  counter="2560"
                  clearable
                  :hint="`カンマ区切りで複数宛先に送信可能です`"
                  persistent-hint
                  auto-grow
                  rows="1"
                  autofocus
                  tabindex="1"
                >
                </v-textarea>
              </v-col>
              <!-- Cc -->
              <v-col cols="12" sm="12">
                <v-textarea
                  v-model="Cc"
                  label="Cc"
                  :rules="[limit_length2560]"
                  counter="2560"
                  clearable
                  :hint="`カンマ区切りで複数宛先に送信可能です`"
                  persistent-hint
                  auto-grow
                  rows="1"
                  tabindex="2"
                >
                </v-textarea>
              </v-col>
              <!-- 件名 -->
              <v-col cols="12" sm="12">
                <v-textarea
                  v-model="subject"
                  label="件名"
                  :rules="[required, limit_length60]"
                  counter="60"
                  clearable
                  auto-grow
                  rows="1"
                  tabindex="3"
                >
                </v-textarea>
              </v-col>
              <!-- 添付 -->
              <v-col cols="12" sm="12">
                <v-file-input
                  @change="getFileContent"
                  label="添付"
                  chips
                  counter
                  show-size
                  truncate-length="15"
                  @click:clear="deleteAllFile"
                  tabindex="4"
                >
                  <template v-slot:selection>
                    <v-chip
                      v-for="(item, index) in file"
                      :key="index + ' ' + item.name">
                      {{ item.name }}</v-chip>
                  </template>
                </v-file-input>
              </v-col>
              <!-- 本文 -->
              <v-col cols="12" sm="12">
                <v-textarea
                  v-model="emailText"
                  label="本文"
                  counter="4000"
                  rows="10"
                  :rules="[required, limit_length4000]"
                  clearable
                  auto-grow
                  tabindex="5"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-divider class="mt-5"></v-divider>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
          <!-- メール送信ボタン -->
          <v-btn color="secondary" v-on:click="mail" rounded tabindex="6">
            <v-icon left> mdi-email-check </v-icon>メール送信
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { getM_SHOKISETTEI, getM_TOKUISAKI } from "@/graphql/queries";
import { executeTransactSql, sendRawEmail } from "@/graphql/mutations";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { CONFIRM_MESSAGE_SEND_EMAIL, ALERT_MESSAGE_COMMON_ERROR } from "@/assets/js/dialog_messages";
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
export default {
  name: "MailForm",
  props: {
    // 発注検索画面の値
    reqMailList: Array,
  },

  data: () => ({
    // ログインユーザー情報
    loginUserInfo: null,
    // ログインユーザー所属部門
    loginUserDepartmetCode: null,
    // 宛先
    address: null,
    // Cc
    Cc: null,
    // 件名
    subject: null,
    // 本文
    emailText: null,
    // ファイルリスト
    file: [],
    // 得意先リスト
    cssClientList: [],
    // 商品リスト
    productList: [],
    // 入力規則
    // 入力必須の制約
    required: (value) => !!value || "必ず入力してください",
    // 文字数の制約 最大60文字
    limit_length60: (value) =>
      value == null || value.length <= 60 || "60文字以内で入力してください",
    // 文字数の制約 最大2560文字
    limit_length2560: (value) =>
      value == null || value.length <= 2560 || "2560文字以内で入力してください",
    // 文字数の制約 最大4000文字
    limit_length4000: (value) =>
      value == null || value.length <= 4000 || "4000文字以内で入力してください",
  }),

  /**
   * created ライフサイクルフック
   */
  created: async function () {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0006"
    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // ローディング画面の表示
    this.$store.commit("setLoading", true);
    // ログイン中のユーザー情報を取得
    if (!(await this.getLoginUserInfo())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }
    // 初期化処理の実行
    if (!(await this.initialize())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }
    // ローディングを解除
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * mounted ライフサイクルフック
   */
  mounted: async function () {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0007"
    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // バリデーションエラーをリセット
    this.$refs.form.resetValidation();
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },

  methods: {
    /**
     * ログインユーザー情報の取得処理
     * ・ログインユーザーコードの取得
     * ・ログインユーザーの所属部門の取得
     */
    async getLoginUserInfo() {
      // メソッド名を定義
      const method_name="getLoginUserInfo"
      // オペレーションタイプを定義
      const operation_type="0001"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログイン中のユーザー情報を取得
      this.loginUserInfo = this.$store.getters.user;

      // データ取得
      let condition = { BUMON_CD: this.loginUserInfo.BUMON_CD };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(getM_SHOKISETTEI, condition)
        );
        // 結果判定
        if (result.data.getM_SHOKISETTEI) {
          // データを取得できた場合
          // ログイン中ユーザーの所属部門情報を取得
          this.loginUserDepartmetCode = result.data.getM_SHOKISETTEI;
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query:"getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          if (result.errors) {
            // ログ出力(SQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3010"], query:"getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // ログ出力(SQL-end_0)
            addLog('INFO', {message: MSG.INFO["1005"], query:"getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          }
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 初期化処理
     * ・コンボボックスのデータ取得
     * ・テキストボックスのデータ取得
     */
    async initialize() {
      // メソッド名を定義
      const method_name="initialize"
      // オペレーションタイプを定義
      const operation_type="0001"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 得意先マスタから情報を取得
      if (!(await this.getCssClient())) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }

      // 宛先を作成
      this.createAddress();
      // 納期お知らせ用の商品データを作成
      this.createProduct();
      // メール本文を作成
      this.createEmailText();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 得意先マスタから情報を取得
     */
    async getCssClient() {
      // メソッド名を定義
      const method_name="getCssClient"
      // オペレーションタイプを定義
      const operation_type="0001"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 仕入先カナ用コンボボックスのリスト生成
      let condition = {
        BUMON_CD: this.loginUserInfo.BUMON_CD,
        CSS_TOKUISAKI_CD: this.reqMailList[0].cssClientCode,
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(getM_TOKUISAKI, condition)
        );
        // 結果判定
        if (result.data.getM_TOKUISAKI) {
          // データを取得できた場合
          // 取得結果をリストに追加
          this.cssClientList = result.data.getM_TOKUISAKI;
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query:"getM_TOKUISAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          if (result.errors) {
            // ログ出力(SQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3010"], query:"getM_TOKUISAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          } else {
            // ログ出力(SQL-end_0)
            addLog('INFO', {message: MSG.INFO["1005"], query:"getM_TOKUISAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          }
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getM_TOKUISAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * メールの宛先を作成
     */
    createAddress() {
      // メソッド名を定義
      const method_name="createAddress"
      // オペレーションタイプを定義
      const operation_type="0001"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // データが存在する場合、メールアドレスをカンマ区切りで追加していく
      if (this.cssClientList.TOKUI_EMAIL_1) {
        this.address = this.cssClientList.TOKUI_EMAIL_1;
      }
      if (this.cssClientList.TOKUI_EMAIL_2) {
        this.address += "," + this.cssClientList.TOKUI_EMAIL_2;
      }
      if (this.cssClientList.TOKUI_EMAIL_3) {
        this.address += "," + this.cssClientList.TOKUI_EMAIL_3;
      }
      if (this.cssClientList.TOKUI_EMAIL_4) {
        this.address += "," + this.cssClientList.TOKUI_EMAIL_4;
      }
      if (this.cssClientList.TOKUI_EMAIL_5) {
        this.address += "," + this.cssClientList.TOKUI_EMAIL_5;
      }
      if (this.cssClientList.TOKUI_EMAIL_6) {
        this.address += "," + this.cssClientList.TOKUI_EMAIL_6;
      }
      if (this.cssClientList.TOKUI_EMAIL_7) {
        this.address += "," + this.cssClientList.TOKUI_EMAIL_7;
      }
      if (this.cssClientList.TOKUI_EMAIL_8) {
        this.address += "," + this.cssClientList.TOKUI_EMAIL_8;
      }
      if (this.cssClientList.TOKUI_EMAIL_9) {
        this.address += "," + this.cssClientList.TOKUI_EMAIL_9;
      }
      if (this.cssClientList.TOKUI_EMAIL_10) {
        this.address += "," + this.cssClientList.TOKUI_EMAIL_10;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 納期お知らせ用の商品データを作成
     */
    createProduct() {
      // メソッド名を定義
      const method_name="createProduct"
      // オペレーションタイプを定義
      const operation_type="0001"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 文字数をカウント（半角を1文字、全角を2文字）
      for (const data of this.reqMailList) {
        this.productList +=
        "=".repeat(50) + "\r\n" +
          "品番：" + data.productName + "\r\n" +
          "数量：" + data.amount + "\r\n" +
          "納品日：" + data.inDate;
        // 現場名が存在するなら追加
        if (data.spotName) {
          this.productList += "\r\n" + "現場名：" + data.spotName;
        }
        this.productList += "\r\n";
      }
      this.productList += "=".repeat(50);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * メール本文を作成
     */
    createEmailText() {
      // メソッド名を定義
      const method_name="createEmailText"
      // オペレーションタイプを定義
      const operation_type="0001"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 件名
      if (this.cssClientList.CSS_TOKUISAKI_NAME) {
        this.subject = this.cssClientList.CSS_TOKUISAKI_NAME;
      } else {
        this.subject = this.reqMailList[0].cssClientName;
      }
      this.subject += "　御中　納期のお知らせ　サン電子株式会社";
      // 本文
      this.emailText =
        "　お世話になっております。\r\nサン電子の" +
        this.loginUserInfo.TANTOSHA_NAME +
        "です。\r\n\r\n納期をお知らせ致します。\r\n\r\n" +
        this.productList +
        "\r\nよろしくお願い致します。\r\n\r\n\r\nサン電子株式会社　" +
        this.loginUserDepartmetCode.BUMON_NAME +
        "\r\n" +
        this.loginUserInfo.TANTOSHA_NAME +
        "\r\nTEL：" +
        this.loginUserDepartmetCode.DENWA_NO +
        "    FAX：" +
        this.loginUserDepartmetCode.FAX_NO +
        "\r\nE-Mail：" +
        this.loginUserInfo.TANTOSHA_ADDRESS;
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * ファイル添付時処理
     */
    async getFileContent(e) {
      // メソッド名を定義
      const method_name="getFileContent"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      if (e) {
        // 同じファイル名のものは上書きする
        let target = this.file.some((v) => v.name === e.name);
        if (target) {
          let mergeFile = this.file.filter((v) => v.name !== e.name);
          mergeFile.push(e);
          this.file = mergeFile;
          return;
        }
        // 同じファイル名でなければ新規追加
        this.file.push(e);
      } else {
        this.file = [];
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 添付ファイル削除時処理
     */
    // deleteFileContent(item) {
    //   // メソッド名を定義
    //   const method_name="deleteFileContent"
    //   // オペレーションタイプを定義
    //   const operation_type="0004"
    //   // ログ出力(method-start)
    //   addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    //   let deleteFile = this.file.filter((v) => v.name !== item.name);
    //   this.file = [];
    //   this.file = deleteFile;
    //   // ログ出力(method-end)
    //   addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    // },
    /**
     * 添付ファイル×ボタン押下時処理
     */
    deleteAllFile() {
      // メソッド名を定義
      const method_name="deleteAllFile"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      this.file = [];
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * メール送信ボタン押下処理
     */
    async mail() {
      // メソッド名を定義
      const method_name="mail"
      // オペレーションタイプを定義
      const operation_type="0002"
      // S3オブジェクトの名称を定義
      let filePath = "";
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // バリデーションチェックでエラーの際は登録しない
      if (!this.validateCheck()) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      if (!confirm(CONFIRM_MESSAGE_SEND_EMAIL)) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // S3にファイルをアップロードする
      if (this.file.length !== 0) {
        filePath = `rawMailAttachments/${this.loginUserInfo.BUMON_CD + '-' +this.loginUserInfo.TANTOSHA_CD}`
        for (const file of this.file) {
          try {
            //1バケット内で用途を分けるため「rawMailAttachments」をファイル名の前に指定してフォルダを切るような使い方にしている
            await Storage.put(`${filePath + file.name}`, file);
          } catch (error) {
            console.log("Error uploading file: ", error);
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }
        }
      }
      // Ccに何も入力されていない場合、空文字で送信する
      if (this.Cc == null) {
        this.Cc = "";
      }
      let condition = {
        FROM: this.loginUserInfo.TANTOSHA_ADDRESS,
        TO: this.address,
        CC: this.Cc,
        SUBJECT: this.subject,
        TEXT: this.emailText,
        PATH: filePath,
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        let result = await API.graphql(
          graphqlOperation(sendRawEmail, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(result.data.sendRawEmail.body);
          // エラーが発生した場合の処理
          if (result.data.sendRawEmail.statusCode > 200) {
            alert(ALERT_MESSAGE_COMMON_ERROR);
            console.log("sendRawEmail : " + JSON.stringify(result));
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "sendRawEmail", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return;
          } else {
            // 正常に終了した際の処理
            console.log(responseBody.data);
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "sendRawEmail", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "sendRawEmail", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return;
        }
        console.log(result);
      } catch (error) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        console.log(error);
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "sendRawEmail", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return;
      }
      // 発注明細データを更新する
      if (!(await this.updateMailFlg())) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
         this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // ローディングを解除
      this.$store.commit("setLoading", false);
      alert("メール送信が完了しました。");
      // 発注検索画面に戻る
      this.$emit("result", { sentMailFlg: true });
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    },
    /**
     * バリデーションチェック
     */
    validateCheck() {
      // メソッド名を定義
      const method_name="validateCheck"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // バリデーションチェックでエラーの際は登録しない
      const form = this.$refs.form;
      if (!form.validate()) {
        // 最初のエラー箇所へスクロール
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 発注明細データのメール送信済みフラグをTRUEにする
     */
    async updateMailFlg() {
      // メソッド名を定義
      const method_name="updateMailFlg"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 発注明細データを更新する
      let sqlSearchList = [];
      for (const data of this.reqMailList) {
        sqlSearchList.push(
          " UPDATE T_HACCHU_MEISAI_DATA SET" +
            " EMAIL_SENT_FLG = 1," +
            " UPDATE_DATE = CURDATE()," +
            " UPDATE_USER_NAME = '" +
            this.loginUserInfo.TANTOSHA_NAME +
            "' " +
            " WHERE HACCHU_DATA_ID = " +
            data.orderDataId +
            " AND MEISAI_NO = " +
            data.orderBranchNo +
            ";"
        );
      }
      let condition = { SQLs: sqlSearchList };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // 実行結果取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeTransactSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // SQLが正常に終了した際の処理
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          }
          console.info(JSON.parse(result.data.executeTransactSql.body));
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return true;
        } else {
          // レスポンスデータを取得できなかった際の処理
          console.log("updateTables : " + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        console.log("updateTables : 異常終了 = " + JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
    },
  },
};
</script>